import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import Iconify from "../../../icon/Iconify";

import { StyledNavItem } from "./styles";

export const NavItemDesktop = forwardRef((obj, ref) => {
  const { item, open, makeParentClickable, active, subItem, isExternalLink, ...other } = obj;
  const { title, path, children } = item;

  const buttonPadding = subItem ? "0.75rem 0" : "1.25rem 0";

  const renderContent = (
    <StyledNavItem
      ref={ref}
      disableRipple
      subItem={subItem}
      active={active}
      open={open}
      {...other}
      sx={{ padding: buttonPadding }}
    >
      {title}

      {!!children && <Iconify width={16} icon="carbon:chevron-down" sx={{ ml: 1 }} />}
    </StyledNavItem>
  );

  // ExternalLink
  if (isExternalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" color="inherit" underline="none">
        {renderContent}
      </Link>
    );
  }

  // navbar item has children and attribute
  // makeParentClickable is true
  // so render it, and make it clickable
  if (children && makeParentClickable) {
    return (
      <Link component={RouterLink} to={path} color="inherit" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link component={RouterLink} to={path} color="inherit" underline="none">
      {renderContent}
    </Link>
  );
});

NavItemDesktop.displayName = "NavItemDesktop";
