import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Button, Link } from "@mui/material";

interface RoutedButtonProps {
  path: string;
  buttonColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  buttonStyle?: React.CSSProperties;
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
}

const RoutedButton = ({ path, buttonColor, buttonStyle, children, size, variant }: RoutedButtonProps) => {
  return (
    <Link component={RouterLink} to={path}>
      <Button color={buttonColor} style={buttonStyle ?? {}} size={size ?? "medium"} variant={variant ?? "contained"}>
        {children}
      </Button>
    </Link>
  );
};

export default RoutedButton;
