import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import Iconify from "src/components/icon/Iconify";

const QBQuestionFlag = ({ isFlagged, toggleFlag }) => {
  const red = "rgba(250, 0, 0, 0.9)";

  return (
    <Tooltip title={isFlagged ? "Unflag question" : "Flag question"}>
      <IconButton
        sx={{
          color: isFlagged ? red : "grey",
        }}
        onClick={toggleFlag}
      >
        <Iconify icon={"majesticons:flag"} style={{ width: "1.75rem", height: "auto" }} />
      </IconButton>
    </Tooltip>
  );
};

export default QBQuestionFlag;
