export const calculationsBank = "Calculations";
export const clinicalMixedBank = "Clinical Mixed";
export const clinicalOtcBank = "Clinical OTC";
export const clinicalMepBank = "Clinical MEP";
export const clinicalTherapeuticsBank = "Clinical Therapeutics";

const getBankName = (bank) => {
  const bank_lower_case = bank.replace(" ", "").toLowerCase();
  if (bank_lower_case === "calculations") return calculationsBank;
  if (bank_lower_case === "clinicalmixed") return clinicalMixedBank;
  if (bank_lower_case === "clinicalotc") return clinicalOtcBank;
  if (bank_lower_case === "clinicalmep") return clinicalMepBank;
  if (bank_lower_case === "clinicaltherapeutics") return clinicalTherapeuticsBank;
  return bank;
};

export default getBankName;
