import { FAQSection } from "../faqPageData";
import AnswerLink from "./answer/AnswerLink";

export const programmeResourcesTopics: FAQSection[] = [
  {
    title: "Programme Resources",
    content: [
      {
        id: "pr1",
        question: "What's Included In the Programme Resources?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Workbooks: For all exam revision courses including all topics for Calculations, BNF, MEP, and OTC courses
              with detailed topics, GPhC-style questions, and case studies.
            </p>
            <p>
              Revision Checklist: Track your progress, identify strengths and weaknesses, and organise your revision
            </p>
            <p style={{ marginBottom: 0 }}>
              GPhC Approved Training Plan: A structured guide to follow throughout your foundation training year,
              ensuring you meet all interim learning outcomes.
            </p>
          </div>
        ),
      },
      {
        id: "pr2",
        question: "Can I Download The Programme Resources? ",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              Yes, they are available for download. Access the workbooks and revision digitally for 365 days from the
              day you enrol on our Foundation Pharmacist Training Programme
            </p>
          </div>
        ),
      },
      {
        id: "pr3",
        question: "Where Can I Find The Workbooks?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              You can find the workbooks in each chapter of the{" "}
              <AnswerLink href="/courses/">Exam Revision Courses</AnswerLink>.
            </p>
            <p style={{ marginBottom: 0 }}>
              Our workbooks are designed to enhance active recall. For answers to questions in the workbooks, refer to
              our exam revision courses.
            </p>
          </div>
        ),
      },
    ],
  },
];
