import { FAQSection } from "../faqPageData";
import AnswerLink from "./answer/AnswerLink";

export const instalmentsAndFinancingOptionsTopics: FAQSection[] = [
  {
    title: "Instalments and Financing Options",
    content: [
      {
        id: "iafo1",
        question: "Who Are Klarna?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              They are leading payment service providers trusted by over millions of consumers worldwide to securely
              handle their payments. Klarna lets you purchase products you need without having to worry about the costs
              upfront. Just select Klana at the checkout for more information.
            </p>

            <p>Klarna Instalment / Financing Options:</p>
            <ul>
              <li>Buy now, pay in 30 days</li>
              <li>Split the cost into 3 equal payments</li>
              <li>Spread the cost up from 6 - 12 months</li>
            </ul>

            <p>
              <AnswerLink href="https://www.klarna.com/uk/customer-service/">Visit Klarna</AnswerLink> for more
              information.
            </p>

            <p>
              Pre Reg Shortcuts Ltd is authorised and regulated by the Financial Conduct Authority (FRN: 1010725). We
              act as a credit broker not a lender. We will only introduce you to Klarna Financial Services UK Ltd (FRN:
              TBC). Klarna may offer you their unregulated 'pay in three instalments' or 'pay in 30 days' finance
              products (both are interest-free, 0% APR). They may also offer you their FCA regulated extended term
              finance plan (up to 12 months), this option will also be interest-free (0% APR).
            </p>

            <p style={{ marginBottom: 0 }}>
              The finance options available will be clearly displayed at checkout under 'Financing' after selecting
              Klarna at the checkout. If you would like to know how we handle complaints, please ask for a copy of our
              complaints handling process. You can also find information about referring a complaint to the Financial
              Ombudsman Service (FOS) (LINK TO X financial-ombudsman.org.uk) This service is not available for the
              unregulated 'pay in three instalments' or 'pay in 30 days' Klarna finance products.
            </p>
          </div>
        ),
      },
      {
        id: "iafo2",
        question: "Which Instalment (Financing) Options Are Available?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>Your options include:</p>
            <ul>
              <li>PayPal Buy Now Pay Later (3 or 4 payments split over 3 or 4 months)</li>
              <li>Klarna Pay in 3 (3 payments split over 3 months)</li>
              <li>Klarna Pay in 30 (buy now pay in 30 days)</li>
              <li>Klarna Financing: no deposit, no interest, split your payments from 6-12 monthly instalments</li>
            </ul>
            <p>
              Above options are a complete list, the checkout page may present different options depending on your
              circumstances.
            </p>
            <p>
              You can choose the option at the checkout, using instalment/ finance options gives you full access to your
              programme / exam revision courses straight away.
            </p>
            <p>All our instalments and financing options are 0% interest.</p>
          </div>
        ),
      },
      {
        id: "iafo3",
        question: "How Does It Work?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Add your selected courses/programme on the <AnswerLink href="/products/">Products page</AnswerLink> and
              proceed to the checkout page
            </p>
            <p>Select Klarna at the checkout, then select learn more and follow the on screen instructions.</p>
            <p>
              Our financing options are no deposit and interest-free. We ensure you pay no additional costs than what
              you see on the website.
            </p>
            <p>
              To learn more about Instalment and Financing options visit{" "}
              <AnswerLink href="https://www.klarna.com/uk/how-klarna-works/">Klarna</AnswerLink>.
            </p>
            <p style={{ marginBottom: 0 }}>
              If you have any concerns or questions, we strongly recommend you to visit the above web pages to learn
              more or contact our team prior to enrolment.
            </p>
          </div>
        ),
      },
      {
        id: "iafo4",
        question: "What If Klarna Doesn't Accept My Application?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Please contact our team via Live Chat to discuss via chat or phone call to discuss your situation and
              explore alternative solutions.
            </p>

            <p>You have several options:</p>
            <ul>
              <li>Retry at a later stage ensuring that you use your correct personal details.</li>
              <li>Use alternate Klarna "Buy Now, Pay Later" or "Pay in 3" options.</li>
              <li>
                View our range to purchase courses/products separately on the{" "}
                <AnswerLink href="/products/">Products page</AnswerLink>
              </li>
            </ul>
            <p style={{ marginBottom: 0 }}>
              You must ensure your correct personal details are used when applying for finance, incorrect details can
              lead to rejection.
            </p>
          </div>
        ),
      },
      {
        id: "iafo5",
        question: "How Do I Know Which Instalment/Financing Option Is Best For Me?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              You can view your options at the checkout select Klarna and follow the onscreen instructions, we don't
              give illustrations as Klarna decide which instalment options are available to you.
            </p>
            <p style={{ marginBottom: 0 }}>
              If you have any concerns or questions, we strongly recommend you to contact our team before purchasing. We
              want to make sure you fully understand the implications before purchase. The quickest way to contact our
              team is to select the live chat (bottom right hand corner) you'll have the option to chat with our team
              via live chat or book a call during our opening hours.
            </p>
          </div>
        ),
      },
      {
        id: "iafo6",
        question: "I Can't Complete The Monthly Payments, What Can I Do?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>There is support available for you and your main port of call is:</p>
            <p style={{ marginTop: 0 }}>
              <AnswerLink href="https://www.klarna.com/uk/customer-service/">Klarna 24/7 support</AnswerLink>
            </p>
            <p>Not-for-profit organisations that offer free, confidential and impartial advice including:</p>
            <ul>
              <li>
                <AnswerLink href="https://www.moneyhelper.org.uk/en/money-troubles/dealing-with-debt">
                  Money Debt Advice Locator
                </AnswerLink>
              </li>
              <li>
                <AnswerLink href="https://adviser.moneyhelper.org.uk/en.html">Money Advice Network</AnswerLink>
              </li>
              <li>
                <AnswerLink href="https://www.citizensadvice.org.uk/">Citizens Advice</AnswerLink>
              </li>
              <li>
                <AnswerLink href="https://www.adviceuk.org.uk/">Advice UK</AnswerLink>
              </li>
            </ul>
          </div>
        ),
      },
      {
        id: "iafo7",
        question: "Will I Still Need To Make Payments If I Don't Attempt My Exams?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              Yes, the purchase price will need to be completed even if you do not complete the course content or exams.
              All our <AnswerLink href="/products/">products</AnswerLink> have 365 days access period from the day of
              purchase, if you choose an instalment/finance period of more than 12 months you will need to complete the
              payments
            </p>
          </div>
        ),
      },
      {
        id: "iafo8",
        question: "How Can I Contact You For More Information?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              You can contact us via Live Chat (recommended) or visit our contact us page for further ways to get in
              touch with us.
            </p>
          </div>
        ),
      },
    ],
  },
];
