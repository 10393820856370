import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = ({ title, isOpen, handleClose, children, maxWidth }) => {
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ paddingRight: "2.75rem", paddingBottom: "0px" }}>
          <span>{title}</span>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            paddingBottom: 0,
            marginBottom: "1rem",
            marginTop: "1rem",
            paddingLeft: "1rem",
          }}
        >
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomModal;
