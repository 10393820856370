import { FAQSection } from "../faqPageData";
import Answer from "./answer/Answer";
import AnswerLink from "./answer/AnswerLink";

export const generalPageTopics: FAQSection[] = [
  {
    title: "General",
    content: [
      {
        id: "general0",
        question: "Who Are Pre-Reg Shortcuts?",
        answer: (
          <>
            <Answer
              lines={[
                "Pre-Reg Shortcuts are a leading Pharmacy education platform specialising in Foundation Pharmacist Training, a Calculations webinar course in 2016 has transformed into leading Foundation Pharmacist Training Programme and interactive exam revision courses.",
                "Co-founded almost a decade ago by Pharmacists Marvin & Umar, Pre-Reg Shortcuts is a unique platform which supports Pharmacy professionals from student, to Trainee, to Pharmacist with our Exam Revision Courses, Foundation Pharmacist Training Programme & Careers Workshops.",
                "Rated EXCELLENT on Trustpilot with over over 5500 success stories. We are 4 time Chemist & Druggist award nominated for Training & Development & Online Initiative and have over 13k subscribers on our YouTube Channel @preregshortcuts",
                <>
                  To learn more about us and our journey visit our <AnswerLink href="/about/">About Us</AnswerLink>.
                </>,
              ]}
            />
          </>
        ),
      },
      {
        id: "general1",
        question: "Why Choose Pre-Reg Shortcuts?",
        answer: (
          <>
            <p style={{ marginTop: 0 }}>
              We provide a unique "ONE STOP" all inclusive Foundation Pharmacist Training Programme and high quality
              individual exam revision courses and workshops for Trainee Pharmacists, Pharmacy Students & Pharmacists.
              Our products and services include the following
            </p>
            <p>Exam Revision Courses:</p>
            <ul>
              <li>MEP (Medicines, Ethics, and Practice)</li>
              <li>BNF (British National Formulary)</li>
              <li>OTC (Over-the-Counter medicines)</li>
              <li>Pharmaceutical Calculations</li>
            </ul>
            <p>Extensive Study Materials and Tools:</p>
            <ul>
              <li>Over 50 study guides and workbooks aligned with the GPhC framework</li>
              <li>Detailed revision checklist and structured study plan</li>
              <li>Comprehensive self-assessment toolkit</li>

              <li>Full GPhC-style mock assessment with thorough feedback</li>
              <li>Access to over 1,000 GPhC-style question bank with answers, feedback, and rationale</li>
              <li>Progress tracker to monitor your development</li>
            </ul>

            <p>Interactive Learning and Support:</p>
            <ul>
              <li>Over 50 hours of live study sessions for interactive learning</li>
              <li>Regular progress review check-in calls</li>
            </ul>

            <p>Community Support:</p>
            <ul>
              <li>
                Access to a supportive Telegram revision community with over 2000 Trainee pharmacists and pharmacists
              </li>
            </ul>

            <p>Professional Training Courses:</p>
            <ul>
              <li>Leadership training with the NPA (National Pharmacy Association)</li>
              <li>Comprehensive GPhC approved foundation training year plan</li>
              <li>First aid and clinical assessment training</li>
              <li>Full face-to-face vaccination training</li>
              <li>Designated supervisor support programme</li>
            </ul>

            <p>Flexible Payment Options:</p>
            <ul>
              <li>Convenient payment option plans and contractor/pharmacy owner discounts available</li>
            </ul>

            <p>Excellent Trustpilot Reviews:</p>
            <ul style={{ marginBottom: 0 }}>
              <li>
                With over 240 reviews rated EXCELLENT see why Trainees and Pharmacy Students praise our Foundation
                Pharmacist Training Programme & Exam Revision Courses. View our{" "}
                <a
                  href="https://uk.trustpilot.com/review/preregshortcuts.com?utm_medium=trustbox&utm_source=Horizontal"
                  className="faqPageLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  Trustpilot reviews
                </a>
                .
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
];
