import { useEffect, useState } from "react";

import RenderResourceAccesses from "src/components_with_stories/remaining_access_modal/RenderResourceAccesses";
import ResourceModel from "src/models/ResourceModel";
import AdminRepository from "src/repository/adminRepository";
import ManageUserResourceAccesses from "./ManageUserResourceAccesses";
import { Typography } from "@mui/material";
import CustomAccordion from "src/custom_components/courses/CustomAccordion";

export default function ManageUserResources({
  userId,
  userName,
  onSuccessCallbackFn,
}: {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
}) {
  const [resources, setResources] = useState<ResourceModel[] | undefined>(undefined);

  const adminRepository = new AdminRepository();

  useEffect(() => {
    adminRepository.getUserResources(userId).then((resources) => {
      setResources(resources);
    });
  }, []);

  return (
    <div>
      {resources === undefined && <p>Loading...</p>}
      {resources && (
        <>
          <CustomAccordion
            removeMargin={true}
            title={<Typography variant="h6">Resource Accesses</Typography>}
            isDisabled={false}
            isLesson={false}
            content={<RenderResourceAccesses resources={resources} />}
          />

          <Typography variant="h6" sx={{ mt: 1.5, mb: 2 }}>
            Manage Resource Accesses
          </Typography>

          <ManageUserResourceAccesses
            userId={userId}
            userName={userName}
            resources={resources}
            onSuccessCallbackFn={onSuccessCallbackFn}
          />
        </>
      )}
    </div>
  );
}
