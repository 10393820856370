import React, { useContext } from "react";
import axios from "axios";

import { AppContext } from "../context/AppContext";
import QBQuiz from "../components_with_stories/QBQuiz";
import { getQuestionsEndpoint, saveQuizAttemptEndpoint } from "../urls";

import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";

const Quiz = ({ trial, bank, bankUrl }) => {
  const { isAuthenticated } = useContext(AppContext);
  const isLightMode = useIsLightMode();

  const saveQuizAttempt = async (quizAttempt) => {
    if (!isAuthenticated) return Promise.resolve(null);

    const {
      timeTakenInSeconds,
      duration: maxDurationInMinutes,
      numQuestions,
      numberOfQuestionsAnsweredCorrectly,
      selectedBank,
      selectedWeighting,
    } = quizAttempt;

    try {
      const response = await axios.post(saveQuizAttemptEndpoint, {
        bank: selectedBank,
        weighting: selectedWeighting,
        num_questions: numQuestions,
        duration_in_minutes: maxDurationInMinutes,

        num_questions_correct: numberOfQuestionsAnsweredCorrectly,
        time_taken_in_seconds: timeTakenInSeconds,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.resolve(null);
    }
  };

  const getQuestions = async ({ bank, weighting, numQuestions }) => {
    try {
      const response = await axios.get(getQuestionsEndpoint, {
        params: {
          bank: bank,
          num_questions: numQuestions,
          weighting: weighting,
        },
      });
      return Promise.resolve(response.data);
    } catch (error) {
      if (error?.response?.data?.error) {
        const errorMessage = error?.response?.data?.error;
        errorToast(errorMessage, isLightMode);
      } else {
        errorToast("Error loading questions. Please try again later.", isLightMode);
      }

      return Promise.resolve(null);
    }
  };

  return (
    <QBQuiz
      getQuestions={getQuestions}
      isLightMode={isLightMode}
      saveQuizAttempt={saveQuizAttempt}
      trial={trial}
      bank={bank}
      bankUrl={bankUrl}
    />
  );
};

export default Quiz;
