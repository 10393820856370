import axios from "axios";
import DesignatedSupervisorProgrammeModel from "src/models/DesignatedSupervisorProgrammeModel";

interface DesignatedSupervisorProgrammeDto {
  id: number;
  title: string;
  description: string;
  hours: number;
  resource: number | null;
  tutor: any;
  chapters: any;
}

interface IDesignatedSupervisorProgrammeRepository {
  getDesignatedSupervisorProgramme(): Promise<DesignatedSupervisorProgrammeModel>;
}

class DesignatedSupervisorProgrammeRepository implements IDesignatedSupervisorProgrammeRepository {
  async getDesignatedSupervisorProgramme() {
    try {
      const response = await axios.get<DesignatedSupervisorProgrammeDto[]>(`/api/designated_supervisor_programmes/`);
      if (response.status !== 200) throw new Error();
      const programmes = response.data;
      if (programmes.length !== 1) throw new Error("No designated supervisor programme found.");
      const programme = programmes[0];
      return mapDtoToModel(programme);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch designated supervisor programme.");
    }
  }
}

export default DesignatedSupervisorProgrammeRepository;

const mapDtoToModel = (dto: DesignatedSupervisorProgrammeDto): DesignatedSupervisorProgrammeModel => {
  return {
    id: dto.id,
    title: dto.title,
    description: dto.description,
    hours: dto.hours,
    resourceId: dto.resource,
    tutor: dto.tutor,
    chapters: dto.chapters,
  };
};
