import { Box, Divider, Stack, Typography, Grid, Card } from "@mui/material";

import { calculationsBank, clinicalMixedBank, clinicalMepBank, clinicalOtcBank } from "src/utils/format_bank";
import useIsLightMode from "src/hooks/useIsLightMode";
import RoutedButton from "src/newComponents/buttons/RoutedButton";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

const bankOptions = [
  {
    bankName: calculationsBank,
    bankDescription:
      "Practice GPhC-style calculations and boost your confidence in timed practice scenarios, covering GPhC framework topics. Use an approved GPhC calculator for the best experience.",
    bankLink: "/question-bank/quiz/calculations/",
  },
  {
    bankName: clinicalMixedBank,
    bankDescription:
      "Explore a mix of BNF, MEP, and OTC topics. All Clinical Question banks include a mixture of Single Best Answer (SBA) and Extended Matching Questions (EMQ) formats for a unique learning interaction.",
    bankLink: "/question-bank/quiz/clinical-mixed/",
  },
  {
    bankName: "Clinical Therapeutics (BNF)",
    bankDescription:
      "Improve your treatment knowledge aligned with the GPhC framework. Engage in GPhC style questions including BNF and all other topics.",
    bankLink: "/question-bank/quiz/clinical-therapeutics/",
  },
  {
    bankName: clinicalMepBank,
    bankDescription:
      "Challenge your MEP skills with practical questions and apply knowledge to real-world scenarios for improved day-to-day practice.",
    bankLink: "/question-bank/quiz/clinical-mep/",
  },
  {
    bankName: clinicalOtcBank,
    bankDescription:
      "Perfect your OTC knowledge for patient-facing roles. Our questions will improve your diagnosis skills and know when to refer, guiding patients on common health issues.",
    bankLink: "/question-bank/quiz/clinical-otc/",
  },
];

const topTwoBankOptions = bankOptions.slice(0, 2);
const bottomThreeBankOptions = bankOptions.slice(2, 5);

export const FancyPageTitle = ({ titles }) => {
  if (!titles) throw new Error("No titles provided");
  if (!Array.isArray(titles)) throw new Error("Titles must be an array");
  if (titles.length !== 4) throw new Error("Invalid number of titles");
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: { xs: "2rem", sm: "2.2rem", md: "2.4rem" },
        maxWidth: { xs: "95%", sm: "90%", md: "65%" },
      }}
      style={{ textAlign: "center" }}
    >
      {titles[0]}
      <Box component="span" sx={{ color: "primary.main" }}>
        {titles[1]}
      </Box>
      {titles[2]}
      <Box component="span" sx={{ color: "#009d47" }}>
        {titles[3]}
      </Box>
    </Typography>
  );
};

export const FancyPageSubtitle = ({ subtitle }) => {
  return (
    <Typography
      sx={{
        color: "text.secondary",
        mt: 2,
        mb: 3,
        textAlign: "center",
        maxWidth: { xs: "95%", sm: "85%", md: "70%" },
      }}
    >
      {subtitle}
    </Typography>
  );
};

const QuestionBankTopSPC = ({ canAccessFullQB, resourceModel }) => {
  const isLightMode = useIsLightMode();

  return (
    <Stack
      sx={{
        alignItems: "center",
        mt: { md: 4, lg: 2 },
      }}
    >
      <FancyPageTitle titles={[`Pass your`, ` Pharmacy Exams `, `with our `, `Question Bank `]} />
      <FancyPageSubtitle
        subtitle={`The Pre-Reg Shortcuts question bank is the perfect place to prepare for 
        your registration assessment and pharmacy exams. We have a range of
        questions covering all aspects of the GPhC framework! ${
          canAccessFullQB === false ? "Try the free demo below and join today." : ""
        }
    `}
      />
      {canAccessFullQB === false && (
        <Stack spacing={2} justifyContent="center" direction={{ xs: "column", sm: "row" }}>
          <RoutedButton
            path="/question-bank/quiz/try-calculation/"
            buttonColor="secondary"
            buttonStyle={{ width: "175px", height: "42px" }}
          >
            Try Calculations
          </RoutedButton>

          <RoutedButton
            path="/question-bank/quiz/try-clinical/"
            buttonColor="secondary"
            buttonStyle={{ width: "175px", height: "42px" }}
          >
            Try Clinical
          </RoutedButton>
        </Stack>
      )}
      {canAccessFullQB === false && (
        <>
          <Typography
            sx={{
              color: "text.secondary",
              mt: 4,
              mb: 2,
              textAlign: "center",
              maxWidth: { xs: "95%", sm: "85%", md: "70%" },
            }}
          >
            Want to access the full question bank? Enrol today to start preparing for your exams!
          </Typography>
          {resourceCanBePurchased(resourceModel) && (
            <Stack direction="row" spacing={2} alignItems="center">
              <ResourceCost cost={resourceModel.cost} originalCost={resourceModel.originalCost} paddingTop={false} />
              <AddResourceToBasket resourceModel={resourceModel} />
            </Stack>
          )}
        </>
      )}
      <Divider sx={{ borderStyle: "dashed", mb: 2 }} style={{ borderColor: "transparent" }} />
      {canAccessFullQB === true && (
        <>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Select A Bank To Get Started!
          </Typography>

          <Grid container spacing={4} sx={{ mt: 1 }}>
            {topTwoBankOptions.map(({ bankName, bankDescription, bankLink }, idx) => (
              <Grid item xs={12} md={6} key={idx}>
                <BankSection
                  bankName={bankName}
                  bankDescription={bankDescription}
                  bankLink={bankLink}
                  isLightMode={isLightMode}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={4} sx={{ mt: 1 }}>
            {bottomThreeBankOptions.map(({ bankName, bankDescription, bankLink }, idx) => (
              <Grid item xs={12} md={4} key={idx}>
                <BankSection
                  bankName={bankName}
                  bankDescription={bankDescription}
                  bankLink={bankLink}
                  isLightMode={isLightMode}
                />
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ borderStyle: "dashed", mt: 3, mb: 2 }} style={{ borderColor: "transparent" }} />
        </>
      )}
    </Stack>
  );
};

export default QuestionBankTopSPC;

const BankSection = ({ bankName, bankDescription, bankLink, isLightMode }) => {
  return (
    <Card
      sx={{
        display: { sm: "flex" },
        "&:hover": {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        flexDirection: "column",
        backgroundColor: isLightMode ? "#f5f5f5" : "rgb(33, 43, 54)",
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "1rem 1rem 0.5rem 1rem",
        }}
      >
        <Typography variant="h5">{bankName}</Typography>
        <Typography>{bankDescription}</Typography>

        <div style={{ margin: "1rem" }}>
          <RoutedButton buttonColor="secondary" path={bankLink}>
            Select
          </RoutedButton>
        </div>
      </div>
    </Card>
  );
};
