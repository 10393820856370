import axios from "axios";
import LiveStudySessionModel from "src/models/LiveStudySessionModel";
import DateService from "src/utils/dateService";

interface LiveStudySessionDto {
  id: number;
  title: string;
  description: string;
  date: string;
  duration: number;
  accessible_video_url: string | null;
}

interface ILiveStudySessionRepository {
  getLiveStudySessions(): Promise<LiveStudySessionModel[]>;
  getDSPLiveStudySessions(): Promise<LiveStudySessionModel[]>;
}

class LiveStudySessionRepository implements ILiveStudySessionRepository {
  async getLiveStudySessions() {
    try {
      const response = await axios.get<LiveStudySessionDto[]>("/api/live_study_sessions/");
      if (response.status !== 200) throw new Error();
      return response.data.map(mapDtoToModel);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch live study sessions.");
    }
  }

  async getDSPLiveStudySessions() {
    try {
      const response = await axios.get<LiveStudySessionDto[]>("/api/dsp_live_study_sessions/");
      if (response.status !== 200) throw new Error();
      return response.data.map(mapDtoToModel);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch live study sessions.");
    }
  }
}

export default LiveStudySessionRepository;

const mapDtoToModel = (liveStudySessionDto: LiveStudySessionDto): LiveStudySessionModel => {
  const monthAndYear = DateService.formatSeven(DateService.parseServerDate(liveStudySessionDto.date));

  return {
    id: liveStudySessionDto.id,
    title: liveStudySessionDto.title,
    description: liveStudySessionDto.description,
    date: DateService.parseServerDate(liveStudySessionDto.date),
    durationInMinutes: liveStudySessionDto.duration,
    accessibleVideoUrl: liveStudySessionDto.accessible_video_url,
    sessionGroup: monthAndYear,
  };
};
