import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import Iconify from "src/components/icon/Iconify";

export default function ContinueShoppingButton() {
  return (
    <Button
      component={RouterLink}
      to={"/products/"}
      color="inherit"
      startIcon={<Iconify icon="carbon:chevron-left" />}
      sx={{
        mt: { xs: 4, sm: 3, md: 3 },
      }}
    >
      Explore More Products
    </Button>
  );
}
