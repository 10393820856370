import React, { useEffect } from "react";
import MetaDataAccordion from "./MetaDataAccordion";
import {
  calculationsBank,
  clinicalMepBank,
  clinicalMixedBank,
  clinicalOtcBank,
  clinicalTherapeuticsBank,
  getBankName,
  getWeightingName,
  mediumWeightedQuestions,
} from "src/utils/format_bank";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TableHead from "@mui/material/TableHead";
import { paleOrange } from "../../qb_question/QBQuestion";
import { criticalToast, warningToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { TEN_SECONDS } from "src/utils/duration";

const QuestionSpread = ({ questionsMetaData }) => {
  const isLightMode = useIsLightMode();

  const filterByBankAndIsFree = (bank, isFree) => {
    if (bank === clinicalMixedBank) {
      return questionsMetaData.filter((q) => getBankName(q.bank) !== calculationsBank && q.is_free === isFree).length;
    }
    return questionsMetaData.filter((q) => getBankName(q.bank) === bank && q.is_free === isFree).length;
  };

  const commonCellSx = {
    border: "2px solid",
    borderColor: paleOrange,
    p: 1,
    textAlign: "center",
  };

  const rows = [
    {
      bank: calculationsBank,
      free: filterByBankAndIsFree(calculationsBank, true),
      premium: filterByBankAndIsFree(calculationsBank, false),
    },
    {
      bank: clinicalOtcBank,
      free: filterByBankAndIsFree(clinicalOtcBank, true),
      premium: filterByBankAndIsFree(clinicalOtcBank, false),
    },
    {
      bank: clinicalMepBank,
      free: filterByBankAndIsFree(clinicalMepBank, true),
      premium: filterByBankAndIsFree(clinicalMepBank, false),
    },
    {
      bank: clinicalTherapeuticsBank,
      free: filterByBankAndIsFree(clinicalTherapeuticsBank, true),
      premium: filterByBankAndIsFree(clinicalTherapeuticsBank, false),
    },
  ];

  const smartRows = rows.map((row) => {
    const premium = row.premium + row.free;
    return {
      ...row,
      premium,
    };
  });

  const sumRow = {
    bank: "Total",
    free: smartRows.reduce((acc, curr) => acc + curr.free, 0),
    premium: smartRows.reduce((acc, curr) => acc + curr.premium, 0),
  };

  useEffect(() => {
    if (!questionsMetaData) return;

    const numQuestionsInCalcTrial = filterByBankAndIsFree(calculationsBank, true);
    const numQuestionsInClinicalTrial = questionsMetaData.filter(
      (q) =>
        getBankName(q.bank) !== calculationsBank &&
        q.is_free &&
        getWeightingName(q.weighting) === mediumWeightedQuestions
    ).length;

    sendTrialErrors({
      numQuestionsInCalcTrial,
      numQuestionsInClinicalTrial,
    });

    const numFreeQuestions = sumRow.free;
    const numPremiumQuestions = sumRow.premium;

    sendGeneralErrors(isLightMode, {
      numFreeQuestions,
      numPremiumQuestions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsMetaData?.length]);

  return (
    <MetaDataAccordion
      title="Visible Question Spread"
      content={
        <>
          <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
            <Table sx={{ border: "2px solid", borderColor: paleOrange }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: paleOrange,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Access
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: paleOrange,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Free
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: paleOrange,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Premium
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {smartRows.concat(sumRow).map((row, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell sx={commonCellSx}>{row.bank}</TableCell>
                      <TableCell sx={commonCellSx}>{row.free}</TableCell>
                      <TableCell sx={commonCellSx}>{row.premium}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    />
  );
};

export default QuestionSpread;

const sendTrialErrors = ({ numQuestionsInCalcTrial, numQuestionsInClinicalTrial }) => {
  if (numQuestionsInCalcTrial < 5) {
    criticalToast(
      <>
        Calculations Trial is not working!
        {` There are only ${numQuestionsInCalcTrial} visible free calculation questions in the database. There should be 5.`}
      </>,
      1
    );
  }

  if (numQuestionsInClinicalTrial < 5) {
    criticalToast(
      <>
        Clinical Trial is not working!
        {` There are only ${numQuestionsInClinicalTrial} visible medium-weighted free clinical questions in the database. There should be 5.`}
      </>,
      2
    );
  }
};

const sendGeneralErrors = (isLightMode, { numFreeQuestions, numPremiumQuestions }) => {
  if (numFreeQuestions > 10) {
    warningToast(
      `There are more than 10 visible free questions. Trial users have access to ${numFreeQuestions} free questions.`,
      isLightMode,
      TEN_SECONDS
    );
  }

  if (numPremiumQuestions < 1000) {
    warningToast(
      `There are less than 1000 visible premium questions. Premium users have access to ${numPremiumQuestions} premium questions.`,
      isLightMode,
      TEN_SECONDS
    );
  }
};
