import { FAQSection } from "../faqPageData";
import Answer from "./answer/Answer";

export const questionBankTopics: FAQSection[] = [
  {
    title: "Question Bank",
    content: [
      {
        id: "qb1",
        question: "Why Should I Join Your Question Bank?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Designed to support you with over 1000 GPhC exam style questions, in line with the GPhC framework.
            </p>

            <p>
              Our question bank is organised by topics, Calculations, BNF, MEP, OTC and has separate weightings for high
              medium and low helping you gain a better understanding of the framework
            </p>

            <p style={{ marginBottom: 0 }}>Access questions and test your knowledge now.</p>
          </div>
        ),
      },
      {
        id: "qb2",
        question: "Are The Questions GPhC Style Questions?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Yes, you'll find calculation-focused questions in a free-text answer format, our clinical questions
              contain a mix of Single Best Answer (SBA) & Extended Matching Questions (EMQs).
            </p>
            <p style={{ marginBottom: 0 }}>
              Once answers are submitted in the quiz, you will see answers and a full explanation. Check out our free
              demo.
            </p>
          </div>
        ),
      },
      {
        id: "qb3",
        question: "Who Can Join Our Question Bank?",
        answer: (
          <>
            <Answer
              lines={[
                "Our Question Bank is designed for anyone who is looking to have easy access and attempt questions for Calculations, BNF, MEP, OTC topics and more. So our Question Bank is popular with students and Trainee Pharmacists.",
                "The Question Bank is suitable for anyone who is interested in practising exam style questions, also as our Question Bank is mapped to the GPhC framework especially popular with Trainee Pharmacists.",
              ]}
            />
          </>
        ),
      },
      {
        id: "qb4",
        question: "How Many Questions Can I Access?",
        answer: (
          <>
            <p style={{ marginTop: 0 }}>
              There are over 1000 Questions with immediate access after you purchase, and once you purchase the Question
              Bank you'll able to separate by the following categories:
            </p>
            <ol>
              <li>Calculations</li>
              <li>BNF (Therapeutics) (British National Formulary)</li>
              <li>MEP (Medicines, Ethics And Practice)</li>
              <li>OTC (Over The Counter Medicines)</li>
              <li>Clinical Mixed (BNF, MEP, OTC)</li>
            </ol>
            <p style={{ marginBottom: 0 }}>
              Our Questions include single best answer, extended matching and Calculations Questions.
            </p>
          </>
        ),
      },
    ],
  },
];
