import { FAQSection } from "../faqPageData";

export const paymentAndDiscountsTopics: FAQSection[] = [
  {
    title: "Payment and Discounts",
    content: [
      {
        id: "payment-methods",
        question: "Which Payment Methods Are Accepted?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>All major credit/debit cards accepted including:</p>
            <ul>
              <li>Contactless payment methods such as Apple/Google Pay</li>
              <li>Instalment options select Klarna / PayPal</li>
            </ul>
          </div>
        ),
      },
      {
        id: "available-discounts",
        question: "Are There Any Discounts Available?",
        answer: (
          <>
            All discounts are automatically applied at the checkout, no need for discount codes. There is a £200
            discount on our Foundation Pharmacist Training Programme only which expires 30th September 2024.
          </>
        ),
      },
      {
        id: "instalment-options",
        question: "Do You Have Instalment Options & When Do I Receive Access?",
        answer: (
          <>
            You will receive full access to all the content in your purchase straight away regardless how you enrol
            <br />
            <br />
            Select Klarna at checkout to learn more about our instalment options.
            <br />
            <br />
            Klarna's Pay in 3 / Pay in 30 days are unregulated credit agreements. Borrowing more than you can afford or
            paying late may negatively impact your financial status and ability to obtain credit. 18+, UK residents
            only. Subject to status.{" "}
            <a
              href="https://www.klarna.com/uk/terms-and-conditions/"
              className="faqPageLink"
              target="_blank"
              rel="noreferrer"
            >
              Ts&Cs
            </a>{" "}
            and late fees apply.
          </>
        ),
      },
    ],
  },
];
