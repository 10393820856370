import { FAQSection } from "../faqPageData";
import AnswerLink from "./answer/AnswerLink";

export const supportAndContactTopics: FAQSection[] = [
  {
    title: "Support & Contact",
    content: [
      {
        id: "sac1",
        question: "How Do I Join The Pre-Reg Shortcuts Telegram Group?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Keep an eye out in your emails after enrolment, our Lifetime Support Group access via our Telegram group
              is only available to anyone enrolled on the Foundation Pharmacist Training Programme.
            </p>
            <p style={{ marginBottom: 0 }}>
              Telegram group is lifetime membership, join our Pre-Reg Shortcuts community to study and network with
              Trainees & Pharmacists.
            </p>
          </div>
        ),
      },
      {
        id: "sac2",
        question: "Recover A Forgotten Password",
        answer: (
          <div>
            <p style={{ marginTop: "0" }}>If you've forgotten your password, don't worry, we've got you covered!</p>
            <p>Follow these simple steps to reset your password:</p>
            <ol>
              <li>
                Enter your email address after selecting the following link{" "}
                <a
                  href="https://preregshortcuts.com/forgot-password/"
                  className="faqPageLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://preregshortcuts.com/forgot-password/
                </a>{" "}
              </li>
              <li>Follow the email instructions, select the password reset link and create your new password</li>
            </ol>
            <p style={{ marginBottom: 0 }}>
              Please check your junk/spam folder if you don't see the email in your main inbox.
            </p>
          </div>
        ),
      },
      {
        id: "contact-support",
        question: "How Do We Contact You For Support?",
        answer: (
          <div>
            Find the answers to most of your questions via our <AnswerLink href="/faq/">FAQ page</AnswerLink>.
            Alternatively, you can reach to our team via our <AnswerLink href="/contact/">Contact Us page</AnswerLink>.
          </div>
        ),
      },
    ],
  },
];
