import { FAQSection } from "../faqPageData";

export const designatedSupervisorProgrammeTopics: FAQSection[] = [
  {
    title: "Designated Supervisor Programme",
    content: [
      {
        id: "dsp1",
        question: "Who Is The Programme Suitable For?",
        answer: (
          <p style={{ margin: 0 }}>
            Designated Supervisors supporting Trainee Pharmacists in the Foundation Training year. This programme is
            designed for new and experienced Designated Supervisors (formerly known as Pre-Reg Tutors). The GPhC
            requirements stipulate that you must have relevant training for your role, this course has been specifically
            designed to cover this training need.
          </p>
        ),
      },
      {
        id: "dsp2",
        question: "Is This Programme Included In Your Foundation Pharmacist Training Programme?",
        answer: (
          <>
            <p style={{ marginTop: 0 }}>
              If the Pharmacy Contractor/Organisation enrols the Trainee on our Foundation Pharmacist Training
              Programme, we'll include the Designated Supervisor Course for free!
            </p>

            <p style={{ marginBottom: 0 }}>Otherwise, you can purchase this separately too. </p>
          </>
        ),
      },
      {
        id: "dsp3",
        question: "What Does Your Designated Supervisor Programme Include?",
        answer: (
          <>
            <p style={{ marginTop: 0 }}>
              See programme structure above, we include recorded modules covering all key topics{" "}
            </p>
            <p>
              Live online zoom drop in sessions (see structure above) to connect with our team and engage with key
              topics covered
            </p>
            <p style={{ marginBottom: 0 }}>
              Support group which is exclusive to designated supervisors via Telegram Messenger
            </p>
          </>
        ),
      },
      {
        id: "dsp4",
        question: "How Can I Access The Live Drop-In Sessions And What If I'm Not Able To Attend Live?",
        answer: (
          <>
            <p style={{ margin: 0 }}>
              Select 'Zoom Register For Live Sessions' above and join us live on the day! If you're unable to attend
              live we'll upload the recorded video so you can catch up any time. Attending live is highly recommend as
              this way we can guide you through your questions and/or concerns.
            </p>
          </>
        ),
      },
      {
        id: "dsp5",
        question: "How Can I Join Your Support Group?",
        answer: (
          <>
            <p style={{ marginTop: 0 }}>
              Please download 'Telegram' on your smartphone and select the link above to register. This group is
              exclusively for designated supervisors with Pre-Reg Shortcuts only. Once you follow the link you will be
              prompted to provide your name, contact number and proof of purchase so we can make sure all members of the
              group are verified. It may take up to 3 working days (excluding weekends) to be added to this group.
            </p>
            <p style={{ marginBottom: 0 }}>You will have lifetime access to this support group</p>
          </>
        ),
      },
      {
        id: "dsp6",
        question: "Are There Any Assessments Involved?",
        answer: (
          <>
            <p style={{ margin: 0 }}>
              There are no assessments. Our live drop-in sessions are used to assess your progress by going over
              case-based scenarios and coaching you through any difficulties you may encounter.
            </p>
          </>
        ),
      },
    ],
  },
];
