import { useEffect, useState, useContext } from "react";

import { TEN_SECONDS } from "src/utils/duration";
import { errorToast, successToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { BasketContext } from "src/context/BasketContext";

const useStripeCallback = () => {
  const { checkoutSuccess } = useContext(BasketContext);

  const isLightMode = useIsLightMode();

  const [message, setMessage] = useState(null);
  const [messageSent, setMessageSent] = useState(false);

  const query = getQuery(window);
  const success = query.get("success");
  const canceled = query.get("canceled");
  const newUser = query.get("new_user");

  useEffect(() => {
    const isExistingUser = newUser?.toLowerCase() === "false";
    if (success) {
      const newUserMessage =
        "Payment successful! An email is on it's way. Please check your inbox, and also your junk or spam folder.";
      const existingUserMessage =
        "Payment successful! Your purchase is now available to access in your account. An email is also on it's way with more details.";
      const message = isExistingUser ? existingUserMessage : newUserMessage;

      setMessage(message);
      checkoutSuccess();
    } else if (canceled) {
      setMessage("Payment cancelled - please try again.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, canceled]);

  useEffect(() => {
    if (message && messageSent === false) {
      if (message.includes("success")) {
        successToast(message, isLightMode, TEN_SECONDS);
      } else {
        errorToast(message, isLightMode);
      }
      setMessageSent(true);

      // url has success or canceled query params
      // remove those query params from the url
      const url = window.location.href;
      const newUrl = url.split("?")[0];
      window.history.pushState({}, null, newUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return;
};

export default useStripeCallback;

const getQuery = (window) => {
  const href = window.location.href;
  const search = href.includes("?") ? href.split("?")[1] : "";
  const query = new URLSearchParams(search);
  return query;
};
