import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Iconify from "src/components/icon/Iconify";

interface CustomAccordionProps {
  isDisabled?: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
  isLesson?: boolean;
  removeMargin: boolean;
}

const CustomAccordion = ({
  isDisabled = false,
  title,
  content,
  isLesson = false,
  removeMargin,
}: CustomAccordionProps) => {
  const sx = {
    color: isDisabled ? "text.disabled" : "primary",
    "& .MuiAccordionSummary-content": {
      margin: removeMargin ? 0 : "20px 0px",
    },
  };
  return (
    <Accordion disabled={isDisabled} sx={isDisabled ? { cursor: "not-allowed" } : {}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={sx}>
        {lockedIcon && isLesson ? (
          <>
            {isDisabled && lockedIcon}
            {title}
          </>
        ) : (
          <>{title}</>
        )}
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;

const lockedIcon = (
  <Iconify
    width={20}
    icon={"carbon:locked"}
    sx={{
      mr: 1,
      top: -1,
      position: "relative",
      color: "text.disabled",
    }}
  />
);
