import { useMemo, useState } from "react";
import escapeRegExp from "lodash/escapeRegExp";
import { Box, Accordion, Typography, AccordionSummary, AccordionDetails } from "@mui/material";

import Iconify from "src/components/icon/Iconify";

export default function SupportContent({ contents, searchTerm }) {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const filteredContents = useMemo(() => {
    if (searchTerm === "") return contents;
    const filteredContents = contents.filter((content) => {
      const contentQuestionIncludes = content.question.toLowerCase().includes(searchTerm.toLowerCase());
      return contentQuestionIncludes;
    });
    return filteredContents;
  }, [contents, searchTerm]);

  return (
    <Box>
      {filteredContents.map((faq) => {
        const searchMatchesQuestion =
          searchTerm.length > 0 && faq.question.toLowerCase().includes(searchTerm.toLowerCase());
        return (
          <Accordion key={faq.id} expanded={expanded === faq.question} onChange={handleChangeExpanded(faq.question)}>
            <AccordionSummary
              sx={{
                "& .MuiAccordionSummary-content": {
                  my: 3,
                  ...(expanded === faq.question && {
                    mb: 2,
                  }),
                },
              }}
            >
              <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                {searchMatchesQuestion ? (
                  <>
                    <Highlighted text={faq.question} highlight={searchTerm} />
                  </>
                ) : (
                  faq.question
                )}
              </Typography>

              <Iconify icon={expanded === faq.question ? "carbon:chevron-down" : "carbon:chevron-right"} />
            </AccordionSummary>

            <AccordionDetails>
              <Typography sx={{ color: "text.secondary" }} component="span">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

const Highlighted = ({ text = "", highlight = "" }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>))}
    </span>
  );
};
