import { FAQSection } from "../faqPageData";

export const freeContentTopics: FAQSection[] = [
  {
    title: "FREE Content",
    content: [
      {
        id: "fc1",
        question: "Why Subscribe To Our Mailing List?",
        answer: (
          <div>
            <p style={{ marginTop: "0" }}>Sign up to our mailing list via our home page to receive:</p>
            <ul>
              <li>Updates & discounts</li>
              <li>50+ GPhC style questions and answers</li>
              <li>
                Access to free revision webinars, summary sheets, drug summaries and our e-book 'Success Secrets for
                Pre-Reg Students'
              </li>
            </ul>
            <p style={{ marginBottom: 0 }}>Stay connected and up to date with all things Pre-Reg Shortcuts!</p>
          </div>
        ),
      },
      {
        id: "free-webinars",
        question: "Do You Offer Free Webinars?",
        answer: (
          <>
            Webinar topics include Calculations, BNF, MEP, OTC and exam tips. These webinars feature distinguished guest
            speakers & conclude with a Q&A session.
            <br />
            <br />
            Subscribe to our mailing list for updates!
          </>
        ),
      },
      {
        id: "fc5",
        question: "Are There Additional Resources for Study and Revision?",
        answer: (
          <div>
            <p style={{ marginTop: "0" }}>
              We have built an online community and have taught over 5500+ Trainee Pharmacists.
            </p>
            <ul>
              <li>We provide study guides and workbooks</li>
              <li>Revision checklist</li>
              <li>Over 200 free educational videos on youtube</li>
              <li>Educational and revision posts on all our social media platforms</li>
            </ul>
            <p>Our exclusive Telegram group for trainees on the Foundation Pharmacist Training Programme:</p>
            <ul>
              <li>Offers support</li>
              <li>Summary sheets</li>
              <li>Mindmaps and diagrams </li>
              <li>1000+ GPhC practice questions </li>
              <li>Connect with Trainees and Pharmacists</li>
            </ul>
          </div>
        ),
      },
    ],
  },
];
