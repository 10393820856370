import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";

import PageTemplate from "src/pages/PageTemplate";

const InitialDisclosureDocumentPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/initial-disclosure-document/" }]}>
        <title>Initial Disclosure Document | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Pre Reg Shortcuts offers interest-free Klarna instalment options, these options give you more flexibility in your purchase."
        />
        <meta
          name="keywords"
          content="FCA, Consumer Duty, Klarna finance, interest-free credit, Pre Reg Shortcuts instalments, instalment payments"
        />
      </Helmet>
      <PageTemplate title="Initial Disclosure Document">
        <Typography sx={{ margin: "1rem 0" }}>
          This Information relates to the activities undertaken by Pre Reg Shortcuts Limited.
        </Typography>

        <p>
          <strong>The Financial Conduct Authority</strong>
        </p>
        <p>
          The Financial Conduct Authority (FCA) is the independent watchdog that regulates financial services. Use this
          information to decide if our services are right for you.
        </p>

        <p>
          <strong>Consumer Duty</strong>
        </p>
        <p>
          Our business is committed to ensuring our customers achieve good outcomes and that our products and services
          are suitable for their needs. The Consumer Duty is a core part of our culture and philosophy and you can
          review our commitment to it by asking for a copy of our Consumer Duty / TCF policy statement.
        </p>

        <p>
          <strong>What Products do we Offer?</strong>
        </p>
        <p>
          We are a credit broker not a lender. We can introduce you to Klarna Financial Services UK Limited (FRN:
          1010725) who may be able to assist you with your requirements. We will only introduce you to this lender.
        </p>
        <p>
          Klarna may offer you their 'pay in three instalments' or 'pay in 30 days' finance products (both are
          interest-free, 0% APR). As these are unregulated products, if you had any complaints you would not be able to
          use the Financial Ombudsman Service (FOS).
        </p>
        <p>
          Klarna may also offer you their FCA regulated extended term finance plan (up to 12 months), this option will
          also be interest-free (0% APR) and you would be able to use the service offered by the FOS if you had any
          complaints.
        </p>
        <p>The finance options available (Klarna) will be clearly displayed at checkout under 'Financing'.</p>

        <p>
          <strong>What will you have to pay to us for this service?</strong>
        </p>
        <p>
          You will not make any payment to us for processing a finance application or for introducing you to a finance
          provider. The lender, Klarna, will not charge interest on any product taken out following referral from us.
          Any other charges that you may incur as a result of the agreement will be clearly shown on the finance
          agreement.
        </p>

        <p>
          <strong>Commission Disclosure</strong>
        </p>
        <p>We will not receive a commission from the lender for arranging your finance.</p>

        <p>
          <strong>Understanding our Products and Documents</strong>
        </p>
        <p>
          If you have any health issues, difficulty in understanding information or there are any recent life events
          that could affect your ability to fully understand the information and documentation you are presented with or
          what your commitments are under the agreement, you should carefully consider the amount of time you require to
          review the documentation. You should also consider if it is advisable for you to have someone you know, help
          you make your decision. Please advise us accordingly if this is the case and we can then proceed with your
          requirements in the most appropriate way.
        </p>
        <p>
          You should make sure you have sufficient time to assess the information given to ensure the funding option
          offered is suitable for you and meets your requirements. You should seek further explanations and ask
          questions if needed to fully understand the documents you are given.
        </p>
        <p>
          <strong>Affordability</strong>
        </p>
        <p>
          You should assess the monthly payments you are required to make throughout the agreement and ensure you are
          able to meet these obligations and other obligations you already have without suffering undue hardship. If you
          are aware of any future events that will affect your ability to meet these payments, you should ensure the
          provider is informed immediately.
        </p>
        <p>
          Your credit rating could be adversely affected if you do not make payments when due which could make it harder
          or more expensive for you to access finance facilities in the future.
        </p>
        <p>
          <strong>Who Regulates Us?</strong>
        </p>
        <p>
          Pre Reg Shortcuts Limited is authorised and regulated by the Financial Conduct Authority (FRN: 1010725). Pre
          Reg Shortcuts Limited's address is 2a Rickyard Barn Stoke Road, Blisworth Hill Farm, Northampton,
          Northamptonshire, England, NN7 3DB.
        </p>
        <p>
          You can check this information on the FCA register by visiting www.fca.org.uk/register or by contacting the
          FCA on 0800 111 6768.
        </p>

        <p>
          <strong>What to do if you have a Complaint</strong>
        </p>
        <p>If you wish to register a complaint, you can contact us at:</p>
        <LeftAndRight
          left="In writing:"
          right=" Pre Reg Shortcuts Limited, 2a Rickyard Barn Stoke Road, Blisworth Hill Farm, Northampton, Northamptonshire,
            England, NN7 3DB"
        />

        <LeftAndRight left="By Phone:" right="+44 7822 010164" />

        <LeftAndRight left="By Email:" right="info@preregshortcuts.com" />

        <p>
          If you would like to know how we handle complaints, please ask for a copy of our complaints handling process.
          If you can't resolve a complaint with us, you may be able to refer it to the Financial Ombudsman Service whose
          contact details are set out below:
        </p>
        <LeftAndRight left="In writing:" right="The Financial Ombudsman Service, Exchange Tower, London E14 9SR" />
        <LeftAndRight left="By telephone:" right="0800 0234567" />
        <LeftAndRight left="By email:" right="complaint.info@financial-ombudsman.org.uk" />
        <LeftAndRight left="Website:" right="www.financial-ombudsman.org.uk" />
      </PageTemplate>
    </>
  );
};

export default InitialDisclosureDocumentPage;

const LeftAndRight = ({ left, right }: { left: string; right: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p style={{ width: "125px", display: "inline-block", margin: 0 }}>{left}</p>
      <p style={{ maxWidth: "500px", display: "inline-block", margin: 0 }}>{right}</p>
    </div>
  );
};
