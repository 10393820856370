import { useState, useContext } from "react";
import Button from "@mui/material/Button";

import { AppContext } from "src/context/AppContext";
import { DialogAnimate, varSlide } from "src/components/animate";
import { Link as RouterLink } from "react-router-dom";
import { Stack } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import { BasketContext } from "src/context/BasketContext";
import { resourceModelTypeToRouteMap } from "src/models/ResourceModelType";

// renderAccess, a function that renders a component to access the resource, if the user has access, e.g. zoom link
const AddResourceToBasket = ({ renderAccess, resourceModel, showAccessButton }) => {
  const { isAuthenticated } = useContext(AppContext);
  const { addItemToBasket, removeItemFromBasket, isItemInBasket } = useContext(BasketContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  if (resourceModel === null) return <></>;

  const isInBasket = isItemInBasket(resourceModel);

  const showAccessLink = isAuthenticated && resourceModel.access?.userHasValidAccess;
  const itemDescription = resourceModel.checkoutDescription ?? "...";

  if (showAccessLink) {
    if (renderAccess) return renderAccess();
    if (showAccessButton) {
      const link = `/${resourceModelTypeToRouteMap[resourceModel.type]}/`;

      return (
        <Button component={RouterLink} to={link} variant="contained" color="info" size="medium">
          Access
        </Button>
      );
    }
    return <></>;
  }

  const addToBasket = async () => {
    const basketItem = {
      name: resourceModel.title,
      description: itemDescription,
      image: resourceModel.image,
      resourceModel: resourceModel,
    };

    setIsLoading(true);
    setLoadingText("Adding to basket...");

    const success = await addItemToBasket(basketItem);
    if (success) {
      setIsLoading(false);
      setLoadingText("");
      setIsModalVisible(true);
    }
  };

  const removeFromBasket = () => {
    setIsLoading(true);
    setLoadingText("Removing...");

    const postAction = () => {
      setIsLoading(false);
      setLoadingText("");
    };

    removeItemFromBasket(resourceModel.stripePriceId, resourceModel.id, postAction);
  };

  const clickAddToBasket = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isInBasket) {
      removeFromBasket();
    } else {
      addToBasket();
    }
  };

  if (resourceModel.type === "questionSet") {
    // cannot allow users to add a question set individually, they must purchase the full mock paper
    return <>Your access has expired. You can no longer review this paper.</>;
  }

  return (
    <>
      <AddToBasketButton
        isInBasket={isInBasket}
        isLoading={isLoading}
        onClick={clickAddToBasket}
        loadingText={loadingText}
      />
      <DialogAnimate
        title={`${resourceModel.title} added to basket!`}
        open={isModalVisible}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsModalVisible(false);
        }}
        showCloseIcon={false}
        variants={varSlide().inUp}
      >
        <ItemDescription
          image={resourceModel.image}
          itemDescription={itemDescription}
          itemTitle={resourceModel.title}
        />
        <ContinueBrowsingOrProceedToCheckout setIsModalVisible={setIsModalVisible} />
      </DialogAnimate>
    </>
  );
};

export default AddResourceToBasket;

function ContinueBrowsingOrProceedToCheckout({ setIsModalVisible }) {
  return (
    <Stack
      spacing={2}
      direction={{
        xs: "column",
        sm: "row",
      }}
      style={{
        marginTop: "1rem",
      }}
    >
      <Button
        type="submit"
        variant="contained"
        color="inherit"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsModalVisible(false);
        }}
      >
        Continue Browsing
      </Button>
      <Button
        component={RouterLink}
        to={"/checkout/"}
        type="submit"
        variant="contained"
        color="inherit"
        onClick={() => setIsModalVisible(false)}
      >
        Proceed to Checkout
      </Button>
    </Stack>
  );
}

function ItemDescription({ image, itemDescription, itemTitle }) {
  const isMobile = useResponsive("down", "sm");
  return (
    <Stack
      spacing={2}
      direction="row"
      style={{
        alignItems: "center",
      }}
    >
      <img
        src={image}
        alt={itemTitle}
        style={{
          height: "80px",
          width: "auto",
          objectFit: "cover",
          borderRadius: "0.5rem",
          margin: isMobile ? "0 auto" : "0",
        }}
      />
      <p>{itemDescription}</p>
    </Stack>
  );
}

function AddToBasketButton({ isInBasket, isLoading, onClick, loadingText }) {
  const buttonText = isInBasket ? "Remove" : "Add to Basket";

  return (
    <Button
      type="submit"
      variant={isInBasket ? "outlined" : "contained"}
      color="inherit"
      disabled={isLoading}
      onClick={onClick}
      style={{
        minWidth: isInBasket ? "85px" : "130px",
      }}
    >
      {isLoading ? loadingText : buttonText}
    </Button>
  );
}
