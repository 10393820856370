import axios from "axios";
import ResourceModel from "src/models/ResourceModel";
import ResourceDto from "./dtos/ResourceDto";
import mapResourceDtoToModel from "./mappers/mapResource";

interface IResourceRepository {
  getResources(): Promise<ResourceModel[]>;
}

class ResourceRepository implements IResourceRepository {
  async getResources() {
    try {
      const response = await axios.get<ResourceDto[]>("/api/resource/get/");
      if (response.status !== 200) throw new Error();
      return response.data.map(mapResourceDtoToModel);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch resources.");
    }
  }
}

export default ResourceRepository;

export const resourceCanBePurchased = (resource: ResourceModel | null) => {
  // if resource is null, it can't be purchased
  if (resource === null) return false;

  // if user has no access
  if (resource.access === null) return true;

  // or if user has access but it's not valid
  if (resource.access.userHasValidAccess === false) return true;

  return false;
};
