import { Tooltip } from "@mui/material";

import Iconify from "src/components/icon/Iconify";
import RenderMarkdown from "src/components_with_stories/qb_question/RenderMarkdown";
import { getVideoLockDetails } from "src/config/requirement/requirement";
import LiveStudySessionModel from "src/models/LiveStudySessionModel";

import "./liveSession.css";
import { infoToast } from "src/components_with_stories/toast/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import DateService, { DateType } from "src/utils/dateService";

const LiveStudySession = ({
  liveStudySession,
  setSelectedSession,
}: {
  liveStudySession: LiveStudySessionModel;
  setSelectedSession: (liveStudySession: LiveStudySessionModel) => void;
}) => {
  const isLightMode = useIsLightMode();
  const today = DateService.newDate();
  const sessionStartDate = liveStudySession.date;
  const sessionEndDate = sessionStartDate.clone().add(liveStudySession.durationInMinutes, "minutes");

  const hasSessionEnded = sessionEndDate.isBefore(today);
  const isRecordingComingSoon = hasSessionEnded && liveStudySession.accessibleVideoUrl === null;
  const isFutureSession = sessionStartDate.isAfter(today);

  const title = `${liveStudySession.title}${!isFutureSession ? " Replay" : ""} (${isFutureSession ? "Streaming on " : "Streamed on "}${DateService.formatSix(sessionStartDate)} - ${DateService.formatThree(sessionEndDate)})`;
  const description = liveStudySession.description;

  const { isLocked, lockReason } = getLockDetails(liveStudySession);
  const icon = getIcon(isLocked, isFutureSession, isRecordingComingSoon);
  const tooltip = getTooltip(isLocked, lockReason, isFutureSession, isRecordingComingSoon, sessionStartDate);

  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "flexStart",
      }}
    >
      <div>
        <Tooltip title={tooltip}>
          <Iconify
            width={24}
            icon={icon}
            onClick={() => {
              if (isLocked || isFutureSession || isRecordingComingSoon) {
                infoToast(tooltip, isLightMode);
                return;
              }
              setSelectedSession(liveStudySession);
            }}
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      </div>
      <div className="noMargins">
        <p>
          <strong>{title}</strong>
        </p>
        <RenderMarkdown text={description} />
        {isRecordingComingSoon && (
          <p>Note: Live session stream has ended, recording will become available within 48 hours.</p>
        )}
      </div>
    </div>
  );
};

export default LiveStudySession;

const getLockDetails = (liveStudySession: LiveStudySessionModel) => {
  if (liveStudySession.accessibleVideoUrl === null) {
    return {
      isLocked: false,
      videoUrl: null,
      lockReason: "Video is coming soon.",
    };
  }

  return getVideoLockDetails(liveStudySession.accessibleVideoUrl);
};

const getIcon = (isLocked: boolean, isFutureSession: boolean, isRecordingComingSoon: boolean) => {
  if (isLocked) return "carbon:locked";
  if (isFutureSession) return "fluent:live-24-regular";
  if (isRecordingComingSoon) return "carbon:pending";
  return "carbon:play";
};

const getTooltip = (
  isLocked: boolean,
  lockReason: string | null, // only when there is no lock!
  isFutureSession: boolean,
  isRecordingComingSoon: boolean,
  sessionStartDate: DateType
) => {
  if (isLocked) return lockReason ?? "Locked";
  if (isFutureSession)
    return (
      "Live session streams on " +
      DateService.formatFive(sessionStartDate) +
      " at " +
      DateService.formatThree(sessionStartDate) +
      "."
    );
  if (isRecordingComingSoon) {
    return "Live session stream ended, recording available within 48 hours.";
  }
  return "Play";
};
