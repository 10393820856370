import React, { useState } from "react";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

import LiveStudySessionModel from "src/models/LiveStudySessionModel";

import LiveStudySession from "./LiveSession";
import WatchLiveSession from "./WatchLiveSession";

interface LiveSessionsScheduleProps {
  liveStudySessions: LiveStudySessionModel[];
}

const LiveSessionsSchedule = ({ liveStudySessions }: LiveSessionsScheduleProps) => {
  const [selectedSession, setSelectedSession] = useState<LiveStudySessionModel | null>(null);

  const groupedSessions = groupBy(liveStudySessions, "sessionGroup");
  const sortedKeys = sortBy(Object.keys(groupedSessions), (key) => new Date(key));

  return (
    <>
      {selectedSession && <WatchLiveSession onClose={() => setSelectedSession(null)} liveSession={selectedSession} />}

      {sortedKeys.map((group) => {
        const sessions = groupedSessions[group];
        const sortedSessions = sortBy(sessions, (session) => session.date);
        return (
          <div key={group} style={{ marginTop: "1rem" }}>
            <h3>{group}</h3>
            {sortedSessions.map((liveStudySession) => (
              <div key={liveStudySession.id} style={{ position: "relative", marginBottom: "1.25rem" }}>
                <LiveStudySession liveStudySession={liveStudySession} setSelectedSession={setSelectedSession} />
              </div>
            ))}
          </div>
        );
      })}
    </>
  );
};

export default LiveSessionsSchedule;
