import { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";

import useIsLightMode from "src/hooks/useIsLightMode";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import QuestionSetRepostiory from "src/repository/questionSetRepository";
import { QUIZ_FINISHED_FEEDBACK_PAGE } from "src/components_with_stories/QuizStates";
import { errorToast } from "src/components_with_stories/toast";
import { AccessContext } from "src/context/AccessContext";
import DateService from "src/utils/dateService";

const QuestionSet = ({ questionSet }) => {
  const { getResourceModelByResourceId } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceId(questionSet.resourceId);

  // for a question set, if they are both not null, the user has not started
  const userHasStarted = resourceModel?.access?.accessBegins !== null && resourceModel?.access?.accessEnds !== null;

  return (
    <>
      <h3>{questionSet.title}</h3>

      <AddResourceToBasket
        resourceModel={resourceModel}
        renderAccess={() => (
          <>
            {userHasStarted ? (
              <>
                {resourceModel.access.accessEnded ? (
                  <p>Your access has ended. You can no longer review this paper.</p>
                ) : (
                  <>
                    <p>
                      You have <TimeRemaining accessEnds={resourceModel.access.accessEnds} /> to review feedback.
                    </p>
                    <ReviewMockPaper inputQuestionSet={questionSet} inputResourceModel={resourceModel} />
                  </>
                )}
              </>
            ) : (
              <AccessMockPaper inputQuestionSet={questionSet} inputResourceModel={resourceModel} />
            )}
          </>
        )}
      />
      <br />
    </>
  );
};

export default QuestionSet;

const AccessMockPaper = ({ inputQuestionSet, inputResourceModel }) => {
  const navigate = useNavigate();

  const setQuestionSet = useLocalStorage("mp_questionSet")[1];

  const openMockPaper = () => {
    const accessEnds = inputResourceModel.access.accessEnds?.toISOString();
    const qs = { ...inputQuestionSet, accessEnds };
    setQuestionSet(qs);
    navigate(`/attempt-mock-paper/`);
  };

  return (
    <>
      <p>You have not started this paper yet. </p>
      <Button color="secondary" variant="contained" onClick={openMockPaper}>
        Access
      </Button>
    </>
  );
};

const ReviewMockPaper = ({ inputQuestionSet, inputResourceModel }) => {
  const questionSetRepositry = new QuestionSetRepostiory();
  const navigate = useNavigate();
  const isLightMode = useIsLightMode();

  const setQuestionSet = useLocalStorage("mp_questionSet")[1];
  const setQuestionSetAttempt = useLocalStorage("mp_questionSetAttempt")[1];

  const openMockPaper = () => {
    const accessEnds = inputResourceModel.access.accessEnds?.toISOString();
    const qs = { ...inputQuestionSet, accessEnds };

    questionSetRepositry
      .getAttempt(qs.questionSetId)
      .then((response) => {
        const attemptData = response.data;
        const { start, end, extra_time, attempt_data: questions } = attemptData;
        const metadata = {
          startTime: start,
          duration: qs.duration + extra_time,
          numberOfQuestionsAnsweredCorrectly: questions.filter((q) => q.answerId === q.question.actualAnswerId).length,
          timeTakenInSeconds: DateService.parseServerDate(end).diff(DateService.parseServerDate(start), "second"),
        };

        // as we don't know the timezone, assume it expired yesterday
        // we don't surface this anyway
        const expiredYesterday = DateService.parseServerDate(end).add(-1, "day").toISOString();
        const attempt = {
          quizMetaData: metadata,
          quizState: QUIZ_FINISHED_FEEDBACK_PAGE,
          questions: questions,
          hideTimer: false,
          quizExpiry: expiredYesterday,
        };
        setQuestionSet(qs);
        setQuestionSetAttempt(attempt);

        navigate(`/attempt-mock-paper/feedback`);
      })
      .catch((error) => {
        console.error("Error getting attempt", error);
        errorToast("Unexpected error retrieving attempt. Please contact support.", isLightMode);
      });
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={openMockPaper}>
        Review
      </Button>
    </>
  );
};

const TimeRemaining = ({ accessEnds }) => {
  const numHoursLeft = accessEnds.diff(DateService.newDate(), "hour");
  const numDaysLeft = accessEnds.diff(DateService.newDate(), "day");

  if (numHoursLeft < 1) {
    const numMinutes = accessEnds.diff(DateService.newDate(), "minute");
    return (
      <>
        {numMinutes} {pluralize("minute", numMinutes)}
      </>
    );
  } else if (numDaysLeft < 1) {
    const numHours = accessEnds.diff(DateService.newDate(), "hour");
    return (
      <>
        {numHours} {pluralize("hour", numHours)}
      </>
    );
  }
  const numHours = accessEnds.diff(DateService.newDate(), "hour") - numDaysLeft * 24;
  return (
    <>
      {numDaysLeft} {pluralize("day", numDaysLeft)}, {numHours} {pluralize("hour", numHours)}
    </>
  );
};

const pluralize = (word, num) => {
  return num === 1 ? word : word + "s";
};
