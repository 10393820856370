import React from "react";
import { Helmet } from "react-helmet-async";

import PageTemplate from "src/pages/PageTemplate";

import HowItWorksContent from "./components/HowItWorksContent";

const HowItWorksPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/how-it-works/" }]}>
        <title>How It Works | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Discover how easy it is to enrol on our foundation training year program for Trainee Pharmacists."
        />
        <meta
          name="keywords"
          content="How Pre-Reg Shortcuts Works, Steps to access foundation year programme, Online training platform, Easy enrolment, Enrol now"
        />
      </Helmet>
      <PageTemplate title="How Pre-Reg Shortcuts Works">
        <HowItWorksContent />
      </PageTemplate>
    </>
  );
};

export default HowItWorksPage;
