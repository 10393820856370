import React, { useState, useRef } from "react";
import axios from "axios";
import { Button, Stack, Typography } from "@mui/material";

import useIsLightMode from "src/hooks/useIsLightMode";
import { errorToast, successToast } from "src/components_with_stories/toast";

const UploadCsv = ({ type, endpointUrl }) => {
  const fileRef = useRef(null);
  const isLightMode = useIsLightMode();
  const [selectedFile, setSelectedFile] = useState(null);

  const onUpload = () => {
    if (selectedFile === null) {
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    axios
      .post(endpointUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = response.data;
        const numQuestions = data.length;
        successToast(`${numQuestions} questions uploaded successfully!`, isLightMode);
      })
      .catch((error) => {
        const response = error.response;

        if (response.data) {
          const data = response.data;
          if (data.file) {
            errorToast(data.file.join(", "), isLightMode);
            return;
          }
          if (data.status === "error" && typeof data.error === "string") {
            errorToast(data.error, isLightMode);
            return;
          }
        }

        errorToast("Failed to upload questions.", isLightMode);
      });
  };

  const selectFile = (e) => {
    if (e.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Stack spacing={2} direction="column">
      <Button color="inherit" variant="contained" component="label" disabled={selectedFile !== null}>
        Select CSV for Bulk Upload {type}
        <input ref={fileRef} accept="text/csv" type="file" hidden multiple={false} onChange={selectFile} />
      </Button>
      {selectedFile && <Typography variant="body2">File: {selectedFile.name}</Typography>}
      {selectedFile && (
        <Stack spacing={2} direction="row">
          <Button
            color="inherit"
            variant="outlined"
            component="label"
            onClick={() => {
              setSelectedFile(null);
              if (fileRef.current) {
                fileRef.current.value = "";
              }
            }}
          >
            Clear
          </Button>
          <Button color="primary" variant="contained" component="label" onClick={onUpload}>
            Upload
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default UploadCsv;
