import React from "react";
import { Helmet } from "react-helmet-async";

import PageTemplate from "src/pages/PageTemplate";

import PrivacyPolicyContent from "src/custom_components/PrivacyPolicyContent";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/privacy-policy/" }]}>
        <title>Privacy Policy | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Review how Pre-Reg Shortcuts collects, uses, and protects your data in our privacy policy. Your privacy is a top priority for us."
        />
        <meta
          name="keywords"
          content="How we handle privacy, Information Security, Data Protection, Privacy Commitment, Your Privacy Matters"
        />
      </Helmet>
      <PageTemplate title="Privacy Policy">
        <PrivacyPolicyContent />
      </PageTemplate>
    </>
  );
};

export default PrivacyPolicyPage;
