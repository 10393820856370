import React from "react";

import { Typography } from "@mui/material";

const RenderTextAreaContent = ({ content }) => {
  // this content is a string that contains \n
  // we are going to split by the new line character and render
  // a text component for each line, i.e. respecting the \n

  if (content === null || content === undefined) return <></>;

  const text = content.split("\n");

  return text.map((line, idx) => {
    return (
      <Typography key={idx} sx={{ color: "text.secondary", margin: "16px 0px 0px" }}>
        {line}
      </Typography>
    );
  });
};

export default RenderTextAreaContent;
