import { FAQSection } from "../faqPageData";
import AnswerLink from "./answer/AnswerLink";

export const additionalTrainingOptionsTopics: FAQSection[] = [
  {
    title: "Additional Training",
    content: [
      {
        id: "at1",
        question: "Who Is Eligible For The Additional Training Courses?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              Exclusively available to any Trainees enrolled in the Foundation Pharmacist Training Programme.
            </p>
          </div>
        ),
      },
      {
        id: "at2",
        question: "How Do I Enrol In The Additional Training Courses?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              After enrolling on the Foundation Pharmacist Training Programme you must email{" "}
              <AnswerLink href="mailto:info@preregshortcuts.com">info@preregshortcuts.com</AnswerLink> who will assist
              with next steps, enrolment and login details to access.
            </p>
            <p>Vaccination Training: Exclusive code sent within 48 hours to use on the ECG website</p>
            <p>First Aid & Clinical Assessment: Login details sent within 10 working days</p>
            <p>Leadership Training: Login details sent within 10 working days</p>
            <p style={{ marginBottom: 0 }}>All of the above include certificates on successful completion</p>
          </div>
        ),
      },
      {
        id: "at3",
        question: "How Long Do I Have Access To The Additional Training Courses?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              You will have access to the training materials and resources for one year from the date you join the
              Foundation Pharmacist Training Programme.
            </p>
          </div>
        ),
      },
      {
        id: "at4",
        question: "I Need Further Information?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              You can visit the following links these are the courses you would be joining but at our discounted prices:
            </p>
            <p style={{ margin: 0 }}>ECG</p>
            <ul>
              <li>
                <AnswerLink href="https://ecgtraining.co.uk/product/pre-reg-first-aid-online/">First Aid</AnswerLink>
              </li>
              <li>
                <AnswerLink href="https://ecgtraining.co.uk/product/clinical-assessment-online/">
                  Clinical Assessment
                </AnswerLink>
              </li>
              <li>
                <AnswerLink href="https://ecgtraining.co.uk/product/fullvaccinetraining/">
                  Vaccination Training
                </AnswerLink>
              </li>
            </ul>
            <p style={{ margin: 0 }}>NPA</p>
            <ul>
              <li>
                <AnswerLink href="https://www.npa.co.uk/training/training-a-z/npa-leadership-training/">
                  Leadership Training
                </AnswerLink>
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
];
