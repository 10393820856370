import { FAQSection } from "../faqPageData";
import AnswerLink from "./answer/AnswerLink";

export const mockPaperTopics: FAQSection[] = [
  {
    title: "Mock Paper",
    content: [
      {
        id: "mock1",
        question: "What is the Pre-Reg Shortcuts Mock Paper?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              The Pre-Reg Shortcuts mock is a complete online mock paper designed to simulate the conditions and
              questions of the GPHC registration exam for trainee pharmacists. Our complete mock paper contains:
            </p>
            <ul>
              <li>Part 1: Calculations 40 questions 2 hours</li>
              <li>Part 2: Clinical 120 questions (90 SBA, 30 EMQ) 2.5 hours</li>
            </ul>
            <p>Option to increase time if you receive additional time in the GPhC exam.</p>
            <p style={{ marginBottom: 0 }}>
              Allows you to familiarise yourself with the exam format, timing, and practice questions before the exam
              day.
            </p>
          </div>
        ),
      },
      {
        id: "mock2",
        question: "How Does Your Online Mock Paper Benefit Me?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>The mock paper helps you:</p>
            <ul style={{ marginBottom: 0 }}>
              <li>Assess your readiness for the GPHC registration exam.</li>
              <li>Identifying strengths and weaknesses in your knowledge and skills.</li>
              <li>Provides valuable practice under exam-like conditions.</li>
              <li>Improve your performance and confidence.</li>
            </ul>
          </div>
        ),
      },
      {
        id: "mock3",
        question: "Is The Mock Paper Up To Date And Based On The GPHC Registration Framework?",
        answer: (
          <>
            Yes, carefully created to align with the up to date GPHC registration framework, ensuring that the questions
            and format closely resemble the official exam.
          </>
        ),
      },
      {
        id: "mock4",
        question: "How Do I Access The Mock Paper And What Are The Rules?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              If you are enrolled on the Foundation Pharmacist Training Programme you will receive FREE access to the
              mock paper otherwise you'll be able to purchase our mock paper separately.
            </p>

            <p>Please see key mock paper rules below:</p>
            <ol>
              <li>Mock Paper can be purchased any time, you'll be sent account details to access.</li>
              <li>
                The mock paper can be attempted once only so you must ensure there is a secure connection using
                desktop/laptop and browsers Chrome/Firefox only.
              </li>
              <li>No sharing is permitted under any circumstances.</li>
              <li>Read the mock paper instructions before starting your attempt.</li>
              <li>
                Any issues or if you're unsure do not click access but email{" "}
                <AnswerLink href="mailto:info@preregshortcuts.com">info@preregshortcuts.com</AnswerLink>
              </li>
            </ol>
            <p style={{ marginBottom: 0 }}>
              Access via desktop or laptop only, using Chrome or Firefox with a stable connection.
            </p>
          </div>
        ),
      },
      {
        id: "mock6",
        question: "Is There A Time Limit?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Yes, the mock paper is timed to simulate the duration of the actual GPHC registration exam.{" "}
            </p>
            <p style={{ marginBottom: 0 }}>
              If you receive additional time, you will have the option to increase the time before attempting the mock
              paper.
            </p>
          </div>
        ),
      },
      {
        id: "mock7",
        question: "Will I Receive Feedback?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              You will receive instant feedback on your performance in your account after attempting the mock paper,
              including correct answers and explanations for all answers, helping you understand areas for improvement.
            </p>
            <p style={{ marginBottom: 0 }}>
              There is 48 hour access after the mock paper section is complete so please ensure you have the time to
              attempt the mock paper (4.5 hours) and then review your answers.
            </p>
          </div>
        ),
      },
    ],
  },
];
