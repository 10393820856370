import { Grid } from "@mui/material";

import icon1 from "src/assets/designatedSupervisorProgrammeBenefits/1.svg";
import icon2 from "src/assets/designatedSupervisorProgrammeBenefits/2.svg";
import icon3 from "src/assets/designatedSupervisorProgrammeBenefits/3.svg";
import useResponsive from "src/hooks/useResponsive";

const DSPWhatDoesItInclude = () => {
  const isMdUp = useResponsive("up", "md");
  const isMobile = !isMdUp;
  return (
    <Grid item xs={12} md={8}>
      <h2
        style={{
          marginBottom: "0.5rem",
        }}
      >
        Who Can Enrol
      </h2>
      <p
        style={{
          marginBottom: isMobile ? "2.5rem" : 0,
        }}
      >
        New, experienced and aspiring Designated Supervisors.
      </p>

      <h2
        style={{
          marginBottom: "1rem",
        }}
      >
        What's Included
      </h2>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={{
            xs: 1.5,
            md: 3,
          }}
        >
          <Grid item xs={12} md={4}>
            <img
              src={icon1}
              alt={"Programme Modules"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Programme Modules"}
            />
            <h3>Programme Modules</h3>
            <p>Cover all key topics you need to know to support your Trainee Pharmacist.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={icon2}
              alt={"Live Drop In Sessions"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Live Drop In Sessions"}
            />
            <h3>Live Drop In Sessions</h3>
            <p>Connect with fellow designated supervisors for support from our facilitator.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={icon3}
              alt={"Support Group (Telegram)"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Support Group (Telegram)"}
            />
            <h3>Support Group (Telegram)</h3>
            <p>Lifetime access to an online Designated Supervisor community.</p>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default DSPWhatDoesItInclude;
