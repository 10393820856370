import ConfigType from "src/models/ConfigType";
import ConfigurationModel from "src/models/ConfigurationModel";
import ZoomButton from "src/newComponents/ZoomButton";

interface RegisterForSessionsProps {
  configurations: ConfigurationModel[];
}

const RegisterForSessions = ({ configurations }: RegisterForSessionsProps) => {
  const configurationModel = configurations.find((c) => c.type === ConfigType.linkToRegisterForLiveStudySessions);
  if (!configurationModel) return <>Failed to display "Register for Live Sessions" button.</>;

  const zoomLink = configurationModel.value;
  return <ZoomButton href={zoomLink} buttonText={"Register for Live Sessions"} />;
};

export default RegisterForSessions;
