import React from "react";
import MobileTooltip from "src/newComponents/MobileTooltip";

import { getHiddenQuestions, getProblematicQuestions, getTotalNumberOfQuestions } from "../utils";

import { MetaDataAccordion } from "../components";
import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { TWENTY_SECONDS } from "src/utils/duration";

const Summary = ({ questionsMetaData }) => {
  const isLightMode = useIsLightMode();
  const totalNumberOfQuestions = getTotalNumberOfQuestions(questionsMetaData);

  const hiddenQuestions = getHiddenQuestions(questionsMetaData);
  const numHiddenQuestions = hiddenQuestions.length;

  const problematicQuestions = getProblematicQuestions(questionsMetaData);
  const numProblematicQuestions = problematicQuestions.length;

  if (numProblematicQuestions > 0) {
    errorToast(
      <>
        There are {numProblematicQuestions} problematic questions. Please check the summary tab and resolve these
        immediately or Trainees may experience unexpected behaviour with the question bank.
        <br />
        <br />
        If any of these questions are Free questions, trial mode will not work. Please resolve these immediately.
      </>,
      isLightMode,
      TWENTY_SECONDS
    );
  }

  return (
    <MetaDataAccordion
      title="Summary"
      content={
        <>
          <p style={{ margin: "8px 0" }}>Total number of questions in the database: {totalNumberOfQuestions}</p>
          <div>
            <p style={{ display: "inline-block", margin: "8px 0" }}>
              Number of questions that are hidden: {numHiddenQuestions}
            </p>
            {numHiddenQuestions > 0 && (
              <div style={{ display: "inline-block" }}>
                <MobileTooltip
                  title={`Hidden questions`}
                  content={
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "150px 150px 150px",
                        gridGap: "10px",
                      }}
                    >
                      {hiddenQuestions.map(({ link }, idx) => {
                        return (
                          <a
                            className="faqPageLink"
                            target="_blank"
                            href={link}
                            rel="noreferrer"
                            style={{ display: "block" }}
                            key={idx}
                          >
                            Hidden Question {idx + 1}
                          </a>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )}
          </div>
          <div>
            <p style={{ display: "inline-block", margin: "8px 0" }}>
              Number of problematic questions that students can see: {numProblematicQuestions}
            </p>
            {numProblematicQuestions > 0 && (
              <div style={{ display: "inline-block" }}>
                <MobileTooltip
                  title={`Problematic questions`}
                  content={
                    <div
                      style={{
                        display: "grid",
                        gridGap: "10px",
                      }}
                    >
                      <p>
                        Note that these questions are visible to students in quizes. If they come across these
                        questions, there may be unexpected errors. Please resolve these problematic questions
                        immediately.
                      </p>
                      {problematicQuestions.map(({ link, problem }, idx) => {
                        return (
                          <a
                            key={idx}
                            className="faqPageLink"
                            target="_blank"
                            href={link}
                            rel="noreferrer"
                            style={{ display: "block" }}
                          >
                            Problematic Question {idx + 1} ({problem})
                          </a>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </>
      }
    />
  );
};

export default Summary;
