import React from "react";

const WhyUsContent = () => {
  return (
    <div>
      <p>
        Welcome to Pre-Reg Shortcuts, the online community to support your Foundation Pharmacist Training Year. We're an
        all in one programme designed to have all the key resources you need, all in the one platform. Dedicated to your
        success, we have an impressive track record supporting over 5500 Trainee Pharmacists nationwide for almost a
        decade. Here's why Pre-Reg Shortcuts is the perfect choice for your foundation year programme:
      </p>

      <ol>
        <li>
          <strong>The Most Reviewed GPhC Exam Revision Platform</strong>
          <p>
            Become part of our thriving community of over 5500+ aspiring Pharmacists who've excelled in their GPhC
            registration exam with Pre-Reg Shortcuts. Check out what our Trainees say about us on our{" "}
            <a
              href="https://uk.trustpilot.com/review/preregshortcuts.com"
              className="faqPageLink"
              target="_blank"
              rel="noreferrer"
            >
              Trustpilot reviews
            </a>
            .
          </p>
        </li>

        <li>
          <strong>95% Pass Rate</strong>
          <p>
            Our Trainee Pharmacists have achieved an impressive pass rate 95% pass rate in the GPhC exams registration
            assessment.
          </p>
        </li>

        <li>
          <strong>Full Money Back Guarantee</strong>
          <p>
            At Pre-Reg Shortcuts, we have a 95% pass rate, we're so confident you'll pass the GPhC exam, if you don't
            pass we'll give you a full money back guarantee or a free extension to the next GPhC exam, nothing to lose!
            Visit our{" "}
            <a href="/terms-and-conditions/" className="faqPageLink" target="_blank" rel="noreferrer">
              T&C's
            </a>{" "}
            for more information.
          </p>
        </li>

        <li>
          <strong>Accessible Programme With Monthly Instalments</strong>
          <p>
            We're the only GPhC exam revision / Foundation Training Provider that gives you an option to enrol with
            monthly instalments interest free over 1 year, this means you can access our full programme from day 1 with
            monthly instalments. You'll get full access to our programme straight away. See Klarna at the checkout!
          </p>
        </li>

        <li>
          <strong>Comprehensive Learning Resources and Experience</strong>
          <ul style={{ margin: "12px 0px" }}>
            <li>
              <a href="/courses/" className="faqPageLink" target="_blank" rel="noreferrer">
                Exam Revision Courses
              </a>
              : Access interactive revision courses covering all the topics you need for the GPhC exam, complete with
              our workbooks, everything you need to pass. Our courses include Calculations, BNF, MEP & OTC.
            </li>
            <li>
              <a href="/live-online-study-sessions/" className="faqPageLink" target="_blank" rel="noreferrer">
                Live Online Study Sessions
              </a>
              : We meet with our Trainees 50 times a year, this is an unprecedented level of support! The best part is
              if you can't attend our live sessions, we give you the recording to watch later in your own time!
            </li>
            <li>
              <a href="/question-bank/" className="faqPageLink" target="_blank" rel="noreferrer">
                1000+ GPhC Style Questions
              </a>
              : Access over 1000 up to date GPhC style questions to practice and build your confidence, all questions
              come with answers and detailed rationale to support your learning
            </li>
            <li>
              <a href="/mock-paper/" className="faqPageLink" target="_blank" rel="noreferrer">
                Full GPhC Style Mock Paper
              </a>
              : Designed to give you an exam day experience before the real GPhC exam from the comfort of your own home,
              this mock paper will let you practice under exam conditions, pinpointing strengths and areas for growth.
            </li>
            <li>
              Support: Progress review calls to check in to see how you're progressing in your training and lifetime
              access to our Telegram support group
            </li>
            <li>
              There's plenty more, visit our{" "}
              <a href="/products/" className="faqPageLink" target="_blank" rel="noreferrer">
                All In One Programme
              </a>{" "}
              to learn more
            </li>
          </ul>
        </li>

        <li>
          <strong>Engaging Interactive Learning</strong>
          <p>
            Revise with a blend of our{" "}
            <a href="/courses/" className="faqPageLink" target="_blank" rel="noreferrer">
              Exam Revision Courses
            </a>{" "}
            and Join our 50+{" "}
            <a href="/live-online-study-sessions/" className="faqPageLink" target="_blank" rel="noreferrer">
              Live Online Study Sessions
            </a>{" "}
            for real-time support.
          </p>
        </li>

        <li>
          <strong>Industry Acknowledgment</strong>
          <p>
            We've been nominated 5 times with the Chemist and Druggist, the only Foundation Training Programme to do so!
            There's good reason for that! Our commitment to excellence is recognised by Trainees and Pharmacy
            nationwide.
          </p>
        </li>

        <li>
          <strong>Expert Support and Community</strong>
          <p>
            Join our exclusive Telegram group to connect with over 2000 fellow members and experienced Pharmacists who
            are your dedicated partners throughout the journey. This collaborative hub offers insights, networking, and
            expert guidance to enhance your learning journey and navigate challenges successfully.
          </p>
        </li>

        <li>
          <strong>Giving Back to the Community</strong>
          <p>
            We sponsor 100 trainees annually, providing free enrolment onto our foundation year programme. Sponsorships
            are awarded during our webinars with 4th-year Pharmacy students and our free webinars series by attending
            live in a raffle.
          </p>
        </li>

        <li>
          <strong>Stay Informed</strong>
          <p>
            Stay updated and enrich your learning journey with Pre-Reg Shortcuts. Join our mailing list for free
            questions, videos, and updates. Plus, explore a library of over 200 FREE educational videos on our{" "}
            <a
              href="https://www.youtube.com/channel/UCj1Lx4xNGk7sHQ1O7H2XDEQ"
              className="faqPageLink"
              target="_blank"
              rel="noreferrer"
            >
              YouTube channel
            </a>
            .
          </p>
        </li>

        <li>
          <strong>Lifelong Learning and Exclusive Perks</strong>
          <p>
            We provide support beyond your training year, with discounted rates on key training provided by leading
            pharmacy training providers ECG & NPA. 30% discount on vaccination training, along with discounted rates
            with the online first aid clinical assessment training and NPA leadership programme. Eligible for Trainees
            enrolled on the all in one programme only.
          </p>
        </li>

        <li>
          <strong>Active Engagement and Sponsorship</strong>
          <p>
            We actively engage with the pharmacy community and sponsor the BPSA (British Pharmaceutical Students'
            Association). This commitment includes participation in renowned conferences like the Pharmacy Show and
            Clinical Pharmacy Congress, where we engage with the next generation of Pharmacists, share our expertise,
            and contribute to the growth and development of pharmacy students.
          </p>
        </li>

        <li>
          <strong>Certificate for Continuous Professional Development</strong>

          <p>Receive a certificate that showcases your dedication to ongoing learning and professional development.</p>
        </li>
      </ol>
    </div>
  );
};

export default WhyUsContent;
