import { Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import { bgGradient } from "../../../utils/cssStyles";

import AuthSetNewPasswordForm from "./AuthSetNewPasswordForm";

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: "/assets/background/overlay_1.jpg",
  }),
  display: "flex",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 2),
}));

export default function WelcomeBackgroundView({ confirmPasswordReset, isSetNewPasswordPage }) {
  return (
    <StyledRoot>
      <Stack
        spacing={4}
        sx={{
          p: 4,
          width: 1,
          mx: "auto",
          flexShrink: 0,
          maxWidth: 400,
          borderRadius: 2,
          bgcolor: "background.default",
          textAlign: { xs: "center", md: "left" },
          boxShadow: (theme) => theme.customShadows.z24,
        }}
      >
        <div>
          <Typography variant="h3" paragraph>
            {isSetNewPasswordPage ? "Set New Password" : "Welcome"}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {isSetNewPasswordPage ? "Update" : "Set"} your account password below
          </Typography>
        </div>

        <AuthSetNewPasswordForm confirmPasswordReset={confirmPasswordReset} />
      </Stack>
    </StyledRoot>
  );
}
