import { Grid } from "@mui/material";

import Workshop from "./Workshop";

export default function Workshops({ workshops }) {
  if (!workshops) return <></>;
  return (
    <Grid
      container
      spacing={3}
      sx={{
        mt: 2,
      }}
    >
      {workshops.map((workshop, idx) => (
        <Grid item xs={12} sm={6} key={idx}>
          <Workshop workshop={workshop} />
        </Grid>
      ))}
    </Grid>
  );
}
