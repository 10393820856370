import { useState } from "react";
import uniq from "lodash/uniq";
import { InputLabel, MenuItem, Select } from "@mui/material";

import ResourceModel from "src/models/ResourceModel";
import ResourceModelType, { resourceModelTypeToFriendlyNameMap } from "src/models/ResourceModelType";
import ManageUserResourceAccess from "./ManageUserResourceAccess";

interface ManageUserResourceAccessesProps {
  userId: number;
  userName: string;
  resources: ResourceModel[];
  onSuccessCallbackFn: (newUserNotes: string) => void;
}

type Action = "give" | "edit" | "remove";

const actionOptions = [
  {
    value: "give",
    label: "Give Access",
  },
  {
    value: "edit",
    label: "Edit Access",
  },
  {
    value: "remove",
    label: "Remove Access",
  },
];

const ManageUserResourceAccesses = ({
  userId,
  userName,
  resources,
  onSuccessCallbackFn,
}: ManageUserResourceAccessesProps) => {
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceModelType | undefined>(undefined);
  const [selectedAction, setSelectedAction] = useState<Action | undefined>(undefined);

  const resourceTypes = uniq(resources.map((resource) => resource.type));
  const resourceOptions = resourceTypes.filter(
    (resourceType) =>
      resourceType !== ResourceModelType.questionSet && resourceType !== ResourceModelType.foundationTrainingProgramme
  );

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>
        <InputLabel id="select-resource-type-label">Select Resource Type</InputLabel>
        <Select
          labelId="select-resource-type-label"
          id="select-resource-type"
          value={selectedResourceType}
          label="Resource Type"
          onChange={(e) => setSelectedResourceType(e.target.value as ResourceModelType)}
          size="small"
          fullWidth
        >
          {resourceOptions.map((resourceType) => {
            return (
              <MenuItem key={resourceType} value={resourceType}>
                {resourceModelTypeToFriendlyNameMap[resourceType]}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div>
        <InputLabel id="select-action-label">Select Action</InputLabel>
        <Select
          labelId="select-action-label"
          id="select-action"
          value={selectedAction}
          label="Action"
          onChange={(e) => setSelectedAction(e.target.value as Action)}
          size="small"
          fullWidth
        >
          {actionOptions.map((action) => {
            return (
              <MenuItem key={action.value} value={action.value}>
                {action.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>

      {selectedResourceType && selectedAction && (
        <ManageUserResourceAccess
          selectedResourceType={selectedResourceType}
          selectedAction={selectedAction}
          allResources={resources}
          userId={userId}
          userName={userName}
          onSuccessCallbackFn={onSuccessCallbackFn}
        />
      )}
    </div>
  );
};

export default ManageUserResourceAccesses;
