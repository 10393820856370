import { useState } from "react";
import { Stack, IconButton, Typography } from "@mui/material";

import Iconify from "src/components/icon/Iconify";
import Image from "src/components/image/Image";
import ResourceCost from "src/newComponents/ResourceCost";

export default function CartItemsMobile({ items, removeItemFromBasket }) {
  return (
    <>
      {items.map((item) => (
        <CartItem key={item.id} item={item} removeItemFromBasket={removeItemFromBasket} />
      ))}
    </>
  );
}

function CartItem({ item, removeItemFromBasket }) {
  const [isLoading, setIsLoading] = useState(false);

  const removeFromBasket = async (item) => {
    setIsLoading(true);
    const postAction = () => setIsLoading(false);
    removeItemFromBasket(item.stripePriceId, item.resourceId, postAction);
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        mt: 1.5,
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pt: 1,
        }}
      >
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Image
            src={item.image}
            sx={{
              width: 80,
              height: 80,
              flexShrink: 0,
              borderRadius: 1.5,
              bgcolor: "background.neutral",
            }}
          />

          <Stack spacing={0.5} sx={{ pt: 2, pl: 2, pr: 2 }}>
            <Typography variant="subtitle2">{item.name}</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {item.description}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={1.5} justifyContent="end" style={{ marginBottom: "1rem" }}>
        <ResourceCost cost={item.cost} originalCost={item.originalCost} paddingTop={true} />

        <IconButton disabled={isLoading} onClick={() => removeFromBasket(item)}>
          <Iconify icon="carbon:trash-can" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
