enum ResourceModelType {
  mockPaper = "mockPaper",
  questionSet = "questionSet",
  workshop = "workshop",
  course = "course",
  questionBank = "questionBank",
  foundationTrainingProgramme = "foundationTrainingProgramme",
  designatedSupervisorProgramme = "designatedSupervisorProgramme",
}

export default ResourceModelType;

export const resourceModelTypeToRouteMap = {
  mockPaper: "mock-paper",
  questionSet: "mock-paper",
  workshop: "career-workshops",
  course: "courses",
  questionBank: "question-bank",
  foundationTrainingProgramme: "foundation-training-programme",
  designatedSupervisorProgramme: "designated-supervisor-programme",
};

export const resourceModelTypeToFriendlyNameMap = {
  mockPaper: "Mock Paper",
  questionSet: "Question Set",
  workshop: "Workshop",
  course: "Course",
  questionBank: "Question Bank",
  foundationTrainingProgramme: "Foundation Training Programme",
  designatedSupervisorProgramme: "Designated Supervisor Programme",
};
