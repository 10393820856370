import React from "react";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const PreviewLinkButton = ({ link }) => {
  if (!link) return <></>;

  return (
    <Button variant="outlined" startIcon={<VisibilityIcon />} href={link} target="_blank" color="primary" size="small">
      Preview
    </Button>
  );
};

export default PreviewLinkButton;
