import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { useDebounce } from "@uidotdev/usehooks";

import { createCheckoutSessionEndpoint, userAccountExistsEndpoint } from "src/urls";
import CSRFToken from "src/custom_components/CSRFToken";
import CustomModal from "src/newComponents/CustomModal";
import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";

export function UnauthenticatedProceedToPayment({ isMobile, resourcesStr, basketId }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isLightMode = useIsLightMode();
  const [emailAddress, setEmailAddress] = useState("");
  const debouncedEmailAddress = useDebounce(emailAddress, 300);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (!debouncedEmailAddress) return;
    if (debouncedEmailAddress.length < 6) return;
    if (!debouncedEmailAddress.includes("@")) return;
    if (!debouncedEmailAddress.includes(".")) return;
    axios
      .post(userAccountExistsEndpoint, {
        email_address: emailAddress,
      })
      .then((response) => {
        if (response.status === 208) {
          const msg = "You already have an account. Please login to continue.";
          errorToast(msg, isLightMode);
          setIsValidEmailAddress(false);
        } else if (response.status === 204) {
          setIsValidEmailAddress(true);
        }
      })
      .catch(() => {
        setIsValidEmailAddress(false);
        errorToast("Email already exists. Please login to continue.", isLightMode);
      });
  }, [debouncedEmailAddress]);

  return (
    <>
      <Button
        sx={{
          mt: 1.5,
        }}
        size="large"
        variant="contained"
        color="inherit"
        type="submit"
        onClick={() => {
          setIsModalVisible(true);
        }}
        style={{
          backgroundColor: "#009d47",
        }}
      >
        Proceed to Payment
      </Button>
      <CustomModal
        title={`Your Details`}
        isOpen={isModalVisible}
        handleClose={() => setIsModalVisible(false)}
        maxWidth="xs"
      >
        <p
          style={{
            marginTop: 0,
          }}
        >
          Please provide your details to proceed to payment.
        </p>
        <form
          action={createCheckoutSessionEndpoint}
          method="POST"
          style={{
            display: isMobile ? "initial" : "contents",
          }}
        >
          <CSRFToken />
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              required
              inputProps={{
                minLength: 6,
              }}
              id="name"
              label="Name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              required
              id="email_address"
              label="Email"
              type="email"
              name="email_address"
              value={emailAddress}
              onChange={(e) => {
                const newEmail = e.target.value;
                setEmailAddress(newEmail);
                if (newEmail.length < 6) setIsValidEmailAddress(false);
              }}
              fullWidth
            />
          </div>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <TextField
              required
              inputProps={{
                minLength: 11,
              }}
              id="phone"
              name="phone"
              label="Phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
            />
          </div>

          <input type="hidden" name="resource_ids" value={resourcesStr} />
          <input type="hidden" name="basket_id" value={basketId} />

          <div
            style={{
              float: "right",
              marginTop: "1rem",
              marginBottom: "3px",
            }}
          >
            <Button
              style={{
                marginRight: "1rem",
              }}
              variant="outlined"
              color="inherit"
              onClick={() => setIsModalVisible(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" color="inherit" type="submit" disabled={!isValidEmailAddress}>
              Proceed to Payment
            </Button>
          </div>
        </form>
      </CustomModal>
    </>
  );
}
