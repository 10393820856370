import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const StyledAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  })
);

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", ml: 2.5 }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transition: "inherit",
    marginRight: "0.5rem",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    transition: "inherit",
    marginBottom: "1rem",
    marginLeft: "0.5rem",
    marginRight: "0",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const MetaDataAccordion = ({ title, content }) => {
  return (
    <StyledAccordion>
      <StyledAccordionSummary>
        <>{title}</>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <div
          style={{
            paddingTop: "0.5rem",
            paddingLeft: "1.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          {content}
        </div>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default MetaDataAccordion;
