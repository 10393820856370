export class Requirement {
  static notLoggedInAndContentIsNotFree = "USER_IS_NOT_LOGGED_IN_AND_CONTENT_IS_NOT_FREE";
  static noResourceAccess = "NO_RESOURCE_ACCESS";
  static resourceAccessExpired = "RESOURCE_ACCESS_EXPIRED";
}

// returns { isLocked, link, lockRequirement, lockReason }
export const getLinkLockDetails = (url_or_requirement) => {
  if (url_or_requirement === null || url_or_requirement === undefined) {
    throw new Error("getLinkLockDetails unknown url_or_requirement " + url_or_requirement.toString());
  }

  if (url_or_requirement === Requirement.notLoggedInAndContentIsNotFree) {
    return {
      isLocked: true,
      link: null,
      lockRequirement: Requirement.notLoggedInAndContentIsNotFree,
      lockReason: `you must be signed in and have access to this course.`,
    };
  } else if (url_or_requirement === Requirement.noResourceAccess) {
    return {
      isLocked: true,
      link: null,
      lockRequirement: Requirement.noResourceAccess,
      lockReason: `you must have access to this course.`,
    };
  } else if (url_or_requirement === Requirement.resourceAccessExpired) {
    return {
      isLocked: true,
      link: null,
      lockRequirement: Requirement.resourceAccessExpired,
      lockReason: `you must purchase this course as your access has expired.`,
    };
  } else if (url_or_requirement.includes("http")) {
    return {
      isLocked: false,
      link: url_or_requirement,
      lockRequirement: null,
      lockReason: null,
    };
  } else {
    throw new Error("getLinkLockDetails unknown url_or_requirement " + url_or_requirement.toString());
  }
};

// returns { isLocked, videoUrl, lockRequirement, lockReason }
export const getVideoLockDetails = (video_url_or_requirement) => {
  if (video_url_or_requirement === null || video_url_or_requirement === undefined) {
    throw new Error("getVideoLockDetails unknown video_url_or_requirement " + video_url_or_requirement.toString());
  }

  if (video_url_or_requirement === Requirement.notLoggedInAndContentIsNotFree) {
    return {
      isLocked: true,
      videoUrl: null,
      lockRequirement: Requirement.notLoggedInAndContentIsNotFree,
      lockReason: "You do not have access this content.",
    };
  } else if (video_url_or_requirement === Requirement.noResourceAccess) {
    return {
      isLocked: true,
      videoUrl: null,
      lockRequirement: Requirement.noResourceAccess,
      lockReason: "You do not have access this content.",
    };
  } else if (video_url_or_requirement === Requirement.resourceAccessExpired) {
    return {
      isLocked: true,
      videoUrl: null,
      lockRequirement: Requirement.resourceAccessExpired,
      lockReason: "Your access to this content has expired.",
    };
  } else if (video_url_or_requirement.includes("http")) {
    return {
      isLocked: false,
      videoUrl: video_url_or_requirement,
      lockRequirement: null,
      lockReason: null,
    };
  } else {
    throw new Error("getVideoLockDetails unknown video_url_or_requirement " + video_url_or_requirement.toString());
  }
};

export const mapLockRequirementToToastMessage = (lockRequirement) => {
  if (lockRequirement === Requirement.notLoggedInAndContentIsNotFree) {
    const message = `Please sign in or purchase this course to access content.`;
    return message;
  } else if (lockRequirement === Requirement.noResourceAccess) {
    const message = `You must purchase this course to access content.`;
    return message;
  } else if (lockRequirement === Requirement.resourceAccessExpired) {
    const message = `Your access to this content has expired. You must purchase this again to access content.`;
    return message;
  } else {
    throw new Error("mapLockRequirementToToastMessage unknown lock requirement " + lockRequirement.toString());
  }
};
