import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { paleGreen, paleOrange } from "./qb_question/QBQuestion";
import useResponsive from "src/hooks/useResponsive";
import RenderMarkdown from "./qb_question/RenderMarkdown";
import QBQuestionFlag from "./qb_question/QBQuestionFlag";

const QBReviewAndFeedbackMenu = ({ submitQuiz, questions, goToQuestion, isFeedbackMenu, toggleQuestionFlag }) => {
  const isMdUp = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  const rows = questions.map((obj, idx) => {
    const num = idx + 1;

    const questionNum = "Question " + num;
    const questionTitle = obj.question.questionTitle;
    const goToQ = () => goToQuestion(idx);

    const answered = obj.answerId !== undefined;
    const answeredText = answered ? "Answered" : "Unanswered";
    const correct = obj.answerId === obj.question.actualAnswerId;
    const correctText = correct ? "Correct" : "Incorrect";

    const answeredOrCorrect = isFeedbackMenu ? correctText : answeredText;

    return {
      questionIndex: idx,
      questionNum,
      questionTitle,
      answeredOrCorrect: answeredOrCorrect,
      isFlagged: obj.isFlagged,
      goToQuestion: goToQ,
    };
  });

  const commonCellSx = { border: "2px solid", borderColor: paleOrange, p: 1 };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
        <Table sx={{ border: "2px solid", borderColor: paleOrange }} aria-label="simple table">
          <TableBody>
            {rows.map((row) => {
              const feedbackRowColor = row.answeredOrCorrect === "Correct" ? paleGreen : paleOrange;
              const flaggedRowColor = row.isFlagged ? paleOrange : undefined;
              const rowColor = isFeedbackMenu ? feedbackRowColor : flaggedRowColor;

              const viewButtonWidth = isMdUp ? "7rem" : "4rem";
              const viewFeedbackButtonWidth = "8.5rem";
              return (
                <TableRow key={row.questionNum} sx={{ backgroundColor: rowColor }}>
                  <TableCell sx={{ ...commonCellSx, width: isMdUp ? "7rem" : "4rem" }}>{row.questionNum}</TableCell>
                  <TableCell sx={{ ...commonCellSx }}>
                    <RenderMarkdown text={row.questionTitle} />
                  </TableCell>
                  <TableCell sx={{ ...commonCellSx, width: isMdUp ? "7rem" : "4rem" }}>
                    {row.answeredOrCorrect}
                  </TableCell>
                  {!isFeedbackMenu && (
                    <TableCell sx={{ ...commonCellSx, width: "4rem" }}>
                      <QBQuestionFlag
                        isFlagged={row.isFlagged}
                        toggleFlag={() => {
                          toggleQuestionFlag(row.questionIndex);
                        }}
                      />
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      ...commonCellSx,
                      width: isFeedbackMenu ? viewFeedbackButtonWidth : viewButtonWidth,
                      textAlign: "center",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={row.goToQuestion}
                      sx={isMobile && isFeedbackMenu ? { height: "3rem" } : undefined}
                    >
                      {isFeedbackMenu ? "View Feedback" : "View"}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!isFeedbackMenu && (
        <Button size="small" variant="contained" color="secondary" onClick={submitQuiz}>
          Submit Answers
        </Button>
      )}
    </>
  );
};

export default QBReviewAndFeedbackMenu;
