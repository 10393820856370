import { FAQSection } from "../faqPageData";

export const workshopsTopics: FAQSection[] = [
  {
    title: "Workshops",
    content: [
      {
        id: "workshops1",
        question: "How Will Your Workshops Support My Career Options?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Our workshop comprehensively covers vital aspects of careers options in Pharmacy, helping you gain a
              better understanding on your choices in Pharmacy, leaving you confident and motivated to pursue your dream
              Pharmacy career.
            </p>
            <p style={{ marginBottom: 0 }}>
              We offer a well-organised, expert-led training, saving you time and ensuring you gain a thorough
              understanding of your Pharmacy career options.
            </p>
          </div>
        ),
      },
      {
        id: "workshps2",
        question: "Why Choose Your Workshops Over Other Online Resources?",
        answer: (
          <>
            Navigating the abundance of online information can be overwhelming. In our workshop you will have the
            opportunity to network with industry leaders with decades of experience.
          </>
        ),
      },
      {
        id: "workshops3",
        question: "Who Can Participate In Your Workshops?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              This workshop is designed for all members of the Pharmacy profession whether it be an:{" "}
            </p>
            <ul>
              <li>Pharmacy Students</li>
              <li>Trainee Pharmacists</li>
              <li>UK Pharmacists</li>
              <li>International Pharmacists seeking a Pharmacy Career in the UK</li>
              <li>Any professional considering Pharmacy as a career</li>
            </ul>
          </div>
        ),
      },
      {
        id: "workshops4",
        question: "How Will The Workshop Be Conducted?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              The workshop will be conducted live online via Zoom. Participants will receive the Zoom link upon
              enrolment. Simply click the link on the day to join us live!
            </p>
            <div style={{ marginBottom: 0 }}>
              Additional details:
              <ul style={{ marginBottom: 0 }}>
                <li>Online workshops provide the utmost convenience, eliminating travel costs and saving time.</li>
                <li>Ensure secure connectivity during the live session, as there will be no replay video available.</li>
                <li>There are no slides provided, please make any notes whilst the workshop is taking place.</li>
              </ul>
            </div>
          </div>
        ),
      },
      {
        id: "workshops5",
        question: "Is There A Limit On Spaces?",
        answer: (
          <>
            Yes, spaces are limited, and enrolment operates on a first-come, first-serve basis. Once all spaces are
            filled, the "Buy Now" button on the website will grey out.
          </>
        ),
      },
      {
        id: "workshops6",
        question: "Is There A Refund Policy?",
        answer: (
          <>
            We are unable to provide refunds. Please note that missed attendance / internet connections are not valid
            reasons for a refund.
          </>
        ),
      },
      {
        id: "workshops8",
        question: "Will You Be Hosting More Workshops?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>Hospital Pharmacy: 1st June 2024</p>
            <p style={{ marginTop: 0 }}>General Practice and Independent Prescribing: 14th September 2024</p>
            If you are interested in our workshop but cannot find a suitable date, please email{" "}
            <a
              href="mailto:pharmacycareers@preregshortcuts.com"
              className="faqPageLink"
              target="_blank"
              rel="noreferrer"
            >
              pharmacycareers@preregshortcuts.com
            </a>{" "}
            to register your interest in future workshops.
            <p style={{ marginBottom: 0 }}>
              With more demand we may be able to organise another workshop. We value your interest and will keep you
              informed about upcoming sessions.
            </p>
          </div>
        ),
      },
    ],
  },
];
