import { alpha, styled } from "@mui/material/styles";
import { Link, Typography, Stack } from "@mui/material";

import { bgGradient } from "src/utils/cssStyles";
import Iconify from "src/components/icon/Iconify";
import LockIcon from "src/assets/large_lock_icon.svg";

import AuthResetPasswordForm from "./AuthResetPasswordForm";

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: "/assets/background/overlay_1.jpg",
  }),
  display: "flex",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export default function ResetPasswordView() {
  return (
    <StyledRoot>
      <Stack
        spacing={4}
        sx={{
          p: 4,
          width: 1,
          mx: "auto",
          flexShrink: 0,
          maxWidth: 425,
          borderRadius: 2,
          bgcolor: "background.default",
          textAlign: { xs: "center", md: "left" },
          boxShadow: (theme) => theme.customShadows.z24,
        }}
      >
        <img
          alt="Reset password icon"
          title="Reset password icon"
          src={LockIcon}
          width={64}
          height={64}
          style={{ margin: "auto" }}
          loading="eager"
        />

        <Typography variant="h3" paragraph>
          Forgot Your Password?
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary", mb: 4 }}>
          Please enter your email address associated and we will email you a link to reset your password.
        </Typography>

        <AuthResetPasswordForm />

        <Link
          href="/login/"
          color="inherit"
          variant="subtitle2"
          sx={{
            mt: 2.5,
            mx: "auto",
            alignItems: "center",
            display: "inline-flex",
          }}
        >
          <Iconify icon="carbon:chevron-left" width={16} sx={{ mr: 1 }} />
          Return to sign in
        </Link>
      </Stack>
    </StyledRoot>
  );
}
