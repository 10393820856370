import { Switch, ToggleButton, Typography } from "@mui/material";

import { useSettingsContext } from "../../SettingsContext";

export default function ModeOptions() {
  const { themeMode, onToggleMode } = useSettingsContext();

  const isLight = themeMode === "light";

  const isHomePage = window.location.pathname === "" || window.location.pathname === "/";

  return (
    <ToggleButton
      fullWidth
      color={isLight ? "standard" : "primary"}
      value={themeMode}
      selected={!isLight}
      onChange={() => {
        onToggleMode();
        isHomePage && window.location.reload();
      }}
      sx={{
        border: 0,
        pt: 0,
        pb: 0,
        pl: 0,
        justifyContent: "initial",
        "&.Mui-selected": {
          boxShadow: "none",
          bgcolor: "transparent",
          ...(!isLight && {
            "&:hover": {
              bgcolor: "action.hover",
            },
          }),
        },
      }}
    >
      <Switch checked={!isLight} />
      <Typography variant="subtitle2">{isLight ? "Light" : "Dark"} Mode</Typography>
    </ToggleButton>
  );
}
