import { IconButton, Typography, Avatar } from "@mui/material";
import React from "react";

const CustomAvatar = ({ id, onClick, name }) => {
  return (
    <IconButton color="inherit" aria-label="user profile" onClick={onClick}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          bgcolor: "rgba(250, 84, 28, 0.8)",
          pt: 0.15,
        }}
        aria-describedby={id}
      >
        <Typography variant="body1" color="white">
          {name[0]}
        </Typography>
      </Avatar>{" "}
    </IconButton>
  );
};

export default CustomAvatar;
