import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { List, Drawer, IconButton, Button, Stack } from "@mui/material";

import Logo from "../../../logo";
import Iconify from "../../../icon/Iconify";
import Scrollbar from "../../../scrollbar/";

import NavList from "./NavList";
import { getLoginPageUrl } from "../../header/Header";

export default function NavMobile({ leftRoutes, rightRoutes, isAuthenticated }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ ml: 1, color: "inherit" }}>
        <Iconify icon="quill:hamburger" width={26} />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 2,
            width: "80%", // width of the drawer
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <Stack spacing={1.5} direction="row" style={{ margin: "0 1.5rem 0.75rem 1rem" }}>
            {!isAuthenticated && (
              <Button
                fullWidth
                variant="outlined"
                color="inherit"
                onClick={() => {
                  const url = getLoginPageUrl(window);
                  navigate(url);
                }}
              >
                Login
              </Button>
            )}
            <Button fullWidth variant="contained" color="inherit" href={"/products/"}>
              Enrol Now
            </Button>
          </Stack>

          <List component="nav" disablePadding>
            {leftRoutes.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
            <hr
              style={{
                borderTop: "1px solid lightgrey",
                margin: "10px 0px 5px 0px",
              }}
            />
            {rightRoutes.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>

          <hr
            style={{
              borderTop: "1px solid lightgrey",
              margin: "5px 0px 0px 0px",
            }}
          />
        </Scrollbar>
      </Drawer>
    </>
  );
}
