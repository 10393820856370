import React from "react";
import { Stack, Typography } from "@mui/material";
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from "@mui/lab";

import useResponsive from "src/hooks/useResponsive";
import PageTitle from "src/custom_components/PageTitle";

const OurStorySPC = () => {
  const isMdUp = useResponsive("up", "md");
  return (
    <>
      <Stack
        spacing={3}
        sx={{
          maxWidth: "90vw",
          mx: "auto",
          textAlign: "center",
          mb: { xs: 4 },
        }}
      >
        <PageTitle title="Our Journey Over Time" />

        <Typography sx={{ color: "text.secondary" }}>
          Explore the milestones that have shaped us into the trusted platform we are today.
        </Typography>
      </Stack>

      <Timeline position={isMdUp ? "alternate" : "right"}>
        {TIMELINES.map((value, index) => (
          <TimelineItem
            key={value.title}
            sx={{
              "&:before": {
                ...(!isMdUp && { display: "none" }),
              },
            }}
          >
            <TimelineSeparator>
              <TimelineDot color={value.color} />
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ pb: { xs: 2, sm: 1 } }}>
              <Typography variant="overline" sx={{ color: `${value.color}.main` }}>
                {value.year}
              </Typography>

              <Typography variant="h6" sx={{ mt: 0.5, mb: 0.8 }}>
                {value.title}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  ...(index % 2 && {
                    ml: "auto",
                  }),
                }}
              >
                {value.description}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};

export default OurStorySPC;
const TIMELINES = [
  {
    year: "2017",
    title: "The Birth of Pre-Reg Shortcuts",
    color: "primary",
    description:
      "Marvin & Umar's mutual interest for an online webinar based course ignited a collaboration. Their passion, shared vision set the stage in transforming the journey for trainee Pharmacists.",
  },
  {
    year: "2018",
    title: "Pioneering the First Online Combo Course - Calculations and Clinical ",
    color: "warning",
    description:
      "Umar's visionary online webinar-based calculations course, adding our BNF (Clinical) course marked the birth of our combo course combining essential calculations and clinical knowledge.",
  },
  {
    year: "2019",
    title: "Designing our Unique Combo + MEP Course",
    color: "success",
    description:
      "Responding to demand, Umar introduced an interactive MEP course, addressing key facets of the MEP curriculum.",
  },
  {
    year: "2020",
    title: "Adapting to Change - Transition to Recorded Lessons",
    color: "secondary",
    description:
      "Adapting from live to recorded webinars due to the COVID pandemic for seamless access ensuring uninterrupted learning.",
  },
  {
    year: "2021",
    title: "Embracing Personal Connections at Our Preregshortcuts Family Gathering",
    color: "primary",
    description:
      "Celebrating our success stories at the inaugural Pre-Reg Shortcuts Pharmacy ball. We almost doubled our course lessons providing further enriched our Trainees' educational journey.",
  },
  {
    year: "2022",
    title: "OTC Course & Celebrating Chemist & Druggist Awards Recognition",
    color: "warning",
    description: (
      <div>
        Trainees benefited from our brand new OTC course delivered by Georgina Gillard.
        <p style={{ margin: "5px 0" }}>
          Nominations include: Online Initiative of the Year, Training and Development, and Above and Beyond.
        </p>
      </div>
    ),
  },
  {
    year: "2023",
    title: "Foundation Training Programme and Awards",
    color: "success",
    description:
      "Our all in one programme guiding Trainee Pharmacists through their foundation year and beyond. Celebrating another Chemist and Druggist nomination in the Training & Development category.",
  },
];
