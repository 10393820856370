import React from "react";

import { Typography } from "@mui/material";
import PageTemplate from "../PageTemplate";
import { Helmet } from "react-helmet-async";
import { MAINTENANCE_MODE } from "src/AuthWrapper";
import { useNavigate } from "react-router-dom";

const MaintenanceModePage = () => {
  const navigate = useNavigate();
  if (!MAINTENANCE_MODE) {
    navigate("/");
  }

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/maintenance/" }]}>
        <title>Maintenance | Pre-Reg Shortcuts</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageTemplate>
        <div style={{ textAlign: "center", marginTop: "20vh" }}>
          <Typography variant="h3" paragraph>
            Website Upgrade in Progress. We'll be back soon!
          </Typography>

          <Typography sx={{ color: "text.secondary", mb: 1 }}>
            We're making some improvements and will be back up and running shortly.
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>Estimated time of completion: 30 minutes.</Typography>
        </div>
      </PageTemplate>
    </>
  );
};

export default MaintenanceModePage;
