import { Box, Stack, Divider, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

import ProceedToPayment from "./ProceedToPayment";

export default function CartSummary({ items, isMobile }) {
  const subTotal = items.reduce((acc, item) => acc + item.originalCost, 0);
  const total = items.reduce((acc, item) => acc + item.cost, 0);
  const discount = subTotal - total;

  return (
    <Stack
      spacing={3}
      sx={{
        p: 5,
        borderRadius: 2,
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
      }}
    >
      <Typography variant="h6"> Summary </Typography>

      <Stack spacing={2}>
        <Row label="Subtotal" value={`£${subTotal.toFixed(2)}`} />
        <Row label="Discount" value={`-£${discount.toFixed(2)}`} />
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Row
        label="Total"
        value={`£${total.toFixed(2)}`}
        sx={{
          typography: "h6",
          "& span": { typography: "h6" },
        }}
      />

      <ProceedToPayment items={items} isMobile={isMobile} />

      <Typography variant="body2" style={{ marginTop: 0 }}>
        <em>Have a discount code? Click 'Proceed to Payment' to enter and apply your code. </em>
      </Typography>
    </Stack>
  );
}

function Row({ label, value, sx, ...other }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ typography: "subtitle2", ...sx }}
      {...other}
    >
      <Box component="span" sx={{ typography: "body2" }}>
        {label}
      </Box>
      {value}
    </Stack>
  );
}
