import React, { useEffect } from "react";
import MobileTooltip from "src/newComponents/MobileTooltip";

import { getHiddenQuestions, getProblematicQuestions, getTotalNumberOfQuestions } from "../utils";

import { MetaDataAccordion } from ".";
import { criticalToast } from "src/components_with_stories/toast";

const QuestionSetSummary = ({ questionsMetaData, title, expectedNumQuestions }) => {
  useEffect(() => {
    const totalNumberOfQuestions = getTotalNumberOfQuestions(questionsMetaData);

    const hiddenQuestions = getHiddenQuestions(questionsMetaData);
    const numHiddenQuestions = hiddenQuestions.length;

    const problematicQuestions = getProblematicQuestions(questionsMetaData);
    const numProblematicQuestions = problematicQuestions.length;

    if (numProblematicQuestions > 0) {
      criticalToast(
        <>
          There are {numProblematicQuestions} problematic questions in {title}. Please check the summary tab and resolve
          these immediately or Trainees will not be able to access all the questions in the mock paper.
        </>,
        3
      );
    }
    if (numHiddenQuestions > 0) {
      criticalToast(
        <>
          There are {numHiddenQuestions} hidden questions in {title}. Please check the summary tab and resolve these
          immediately or Trainees will not be able to access all the questions in the mock paper.
        </>,
        4
      );
    }
    if (totalNumberOfQuestions !== expectedNumQuestions) {
      criticalToast(
        <>
          The number of questions in {title} is {totalNumberOfQuestions}. It should be {expectedNumQuestions}. Please
          check the summary tab and resolve these immediately or Trainees will not be able to access all the questions
          in the mock paper.
        </>,
        5
      );
    }
  }, [questionsMetaData]);

  const totalNumberOfQuestions = getTotalNumberOfQuestions(questionsMetaData);

  const hiddenQuestions = getHiddenQuestions(questionsMetaData);
  const numHiddenQuestions = hiddenQuestions.length;

  const problematicQuestions = getProblematicQuestions(questionsMetaData);
  const numProblematicQuestions = problematicQuestions.length;

  return (
    <MetaDataAccordion
      title={`${title}: Summary`}
      content={
        <>
          <p style={{ margin: "8px 0" }}>Total number of questions in the database: {totalNumberOfQuestions}</p>
          <div>
            <p style={{ display: "inline-block", margin: "8px 0" }}>
              Number of questions that are hidden: {numHiddenQuestions}
            </p>
            {numHiddenQuestions > 0 && (
              <div style={{ display: "inline-block" }}>
                <MobileTooltip
                  title={`Hidden questions`}
                  content={
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "150px 150px 150px",
                        gridGap: "10px",
                      }}
                    >
                      {hiddenQuestions.map(({ link }, idx) => {
                        return (
                          <a
                            className="faqPageLink"
                            target="_blank"
                            href={link}
                            rel="noreferrer"
                            style={{ display: "block" }}
                            key={idx}
                          >
                            Hidden Question {idx + 1}
                          </a>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )}
          </div>
          <div>
            <p style={{ display: "inline-block", margin: "8px 0" }}>
              Number of problematic questions that students can see: {numProblematicQuestions}
            </p>
            {numProblematicQuestions > 0 && (
              <div style={{ display: "inline-block" }}>
                <MobileTooltip
                  title={`Problematic questions`}
                  content={
                    <div
                      style={{
                        display: "grid",
                        gridGap: "10px",
                      }}
                    >
                      <p>
                        Note that these questions are visible to students in quizes. If they come across these
                        questions, there may be unexpected errors. Please resolve these problematic questions
                        immediately.
                      </p>
                      {problematicQuestions.map(({ link, problem }, idx) => {
                        return (
                          <a
                            key={idx}
                            className="faqPageLink"
                            target="_blank"
                            href={link}
                            rel="noreferrer"
                            style={{ display: "block" }}
                          >
                            Problematic Question {idx + 1} ({problem})
                          </a>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </>
      }
    />
  );
};

export default QuestionSetSummary;
