import { generalPageTopics } from "./faq/generalPageTopics";
import { freeContentTopics } from "./faq/freeContentTopics";
import { workshopsTopics } from "./faq/workshopsTopics";
import { mockPaperTopics } from "./faq/mockPaperTopics";
import { questionBankTopics } from "./faq/questionBankTopics";
import { supportAndContactTopics } from "./faq/supportAndContactTopics";
import { paymentAndDiscountsTopics } from "./faq/paymentAndDiscountsTopics";
import { refundAndCancellationTopics } from "./faq/refundAndCancellationTopics";
import { programmeEnrolmentAndAccessTopics } from "./faq/programmeEnrolmentAndAccessTopics";

import { liveStudySessionsTopics } from "./faq/liveStudySessionsTopics";
import { programmeResourcesTopics } from "./faq/programmeResourcesTopics";
import { additionalTrainingOptionsTopics } from "./faq/additionalTrainingOptionsTopics";
import { designatedSupervisorProgrammeTopics } from "./faq/designatedSupervisorProgrammeTopics";
import { instalmentsAndFinancingOptionsTopics } from "./faq/instalmentsAndFinancingOptionsTopics";

export interface FAQSection {
  title: string;
  content: FAQContent[];
}

interface FAQContent {
  id: string;
  question: string;
  answer: JSX.Element;
}

export const faqPageTopics: FAQSection[] = [
  ...generalPageTopics,
  ...programmeEnrolmentAndAccessTopics,
  ...paymentAndDiscountsTopics,
  ...instalmentsAndFinancingOptionsTopics,
  ...freeContentTopics,
  ...liveStudySessionsTopics,
  ...programmeResourcesTopics,
  ...questionBankTopics,
  ...mockPaperTopics,
  ...designatedSupervisorProgrammeTopics,
  ...workshopsTopics,
  ...additionalTrainingOptionsTopics,
  ...supportAndContactTopics,
  ...refundAndCancellationTopics,
];
