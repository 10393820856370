import { Stack, Typography } from "@mui/material";

import Image from "src/components/image/Image";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";
import useResponsive from "src/hooks/useResponsive";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

const ProductItem = ({ resourceModel }) => {
  const isMobile = useResponsive("down", "md");

  return (
    <div
      style={{
        marginBottom: isMobile ? "2rem" : 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pb: 1,
          minWidth: isMobile ? 300 : 500,
        }}
      >
        <Stack direction="row" alignItems="center" flexGrow={1} spacing={1}>
          <Image
            src={resourceModel.image}
            sx={{
              width: 80,
              height: 80,
              flexShrink: 0,
              borderRadius: 1.5,
              bgcolor: "background.neutral",
            }}
          />

          <Stack spacing={0.5} sx={{ p: 2 }}>
            <Typography variant="subtitle2">{resourceModel.title}</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {resourceModel.checkoutDescription}
            </Typography>
          </Stack>
        </Stack>{" "}
        {!isMobile && <Cost resourceModel={resourceModel} />}
      </Stack>
      {isMobile && <Cost resourceModel={resourceModel} />}
    </div>
  );
};

export default ProductItem;

const Cost = ({ resourceModel }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="end" style={{ marginBottom: "1rem" }}>
      {resourceCanBePurchased(resourceModel) && (
        <ResourceCost cost={resourceModel.cost} originalCost={resourceModel.originalCost} paddingTop={true} />
      )}
      <AddResourceToBasket resourceModel={resourceModel} showAccessButton />
    </Stack>
  );
};
