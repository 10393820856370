export const formatAttemptTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  const hoursPlural = hours === 1 ? "hour" : "hours";
  const hoursString = hours > 0 ? `${hours} ${hoursPlural} ` : "";

  const minutesPlural = minutes === 1 ? "minute" : "minutes";
  const minutesString = minutes > 0 ? `${minutes} ${minutesPlural} ` : "";

  const secondsPlural = seconds === 1 ? "second" : "seconds";
  const secondsString = seconds > 0 ? `${seconds} ${secondsPlural}` : "";

  if (hours > 0 && minutes > 0) {
    // no need to show seconds if we have hours and minutes
    return hoursString + minutesString.trim();
  }

  return hoursString + minutesString + secondsString;
};

export const getAttemptTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  const hoursPlural = hours === 1 ? "hour" : "hours";
  const hoursString = hours > 0 ? `${hours} ${hoursPlural} ` : "";

  const minutesPlural = minutes === 1 ? "minute" : "minutes";
  const minutesString = minutes > 0 ? `${minutes} ${minutesPlural} ` : "";

  const secondsPlural = seconds === 1 ? "second" : "seconds";
  const secondsString = seconds > 0 ? `${seconds} ${secondsPlural}` : "";

  if (hours > 0 && minutes > 0) {
    // no need to show seconds if we have hours and minutes
    return {
      hours: hoursString,
      minutes: minutesString.trim(),
    };
  }

  return {
    hours: hoursString,
    minutes: minutesString,
    seconds: secondsString,
  };
};
