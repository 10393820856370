import React from "react";
import { Helmet } from "react-helmet-async";

import LoginBackgroundView from "./components/LoginBackgroundView";

const LoginPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/login/" }]}>
        <title>Login | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Securely access your Pre-Reg Shortcuts account for pharmacy training resources and materials."
        />
        <meta
          name="keywords"
          content="Create Account, Access Foundation Year Plan, Member Login, Access revision courses, Enter email & password"
        />
      </Helmet>
      <LoginBackgroundView />
    </>
  );
};

export default LoginPage;
