import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";

import GiveAccess from "../manage_access_helpers/GiveAccess";
import AccessDateSelector from "../manage_access_helpers/AccessDateSelector";
import { useState } from "react";
import DateService from "src/utils/dateService";

interface ManageQuestionBankAccessProps {
  selectedAction: "give" | "edit" | "remove";
  allResources: ResourceModel[];
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
}

const ManageQuestionBankAccess = ({
  selectedAction,
  allResources,
  userId,
  userName,
  onSuccessCallbackFn,
}: ManageQuestionBankAccessProps) => {
  const questionBankResources = allResources.filter((resource) => resource.type === ResourceModelType.questionBank);

  if (questionBankResources.length !== 1) {
    throw new Error("Invalid number of question bank resources found");
  }

  const questionBank = questionBankResources[0];

  if (selectedAction === "give") {
    if (questionBank.access !== null) {
      return <p>User has access or their access has expired, select "Edit Access" or "Remove Access" instead.</p>;
    }
    return (
      <AddAccess
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        questionBank={questionBank}
      />
    );
  }

  if (selectedAction === "edit") {
    if (questionBank.access === null) {
      return <p>User has no access to question bank, select "Give Access" instead.</p>;
    }

    return (
      <EditAccess
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        questionBank={questionBank}
      />
    );
  }

  if (selectedAction === "remove") {
    if (questionBank.access === null) {
      return <p>User has no access to question bank, select "Give Access" instead.</p>;
    }

    return (
      <div>
        <p>
          By proceeding you will remove user's access to question bank. The user will no longer be able to attempt any
          questions in the question bank.
        </p>

        <GiveAccess
          text="Permanently remove access to question bank"
          userId={userId}
          userName={userName}
          onSuccessCallbackFn={onSuccessCallbackFn}
          accessInstructions={[
            {
              resource: questionBank,
              crudAction: "delete",
              accessStart: null,
              accessEnd: null,
            },
          ]}
        />
      </div>
    );
  }

  throw new Error("Invalid action");
};

export default ManageQuestionBankAccess;

interface AddAccessProps {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
  questionBank: ResourceModel;
}

const AddAccess = ({ userId, userName, onSuccessCallbackFn, questionBank }: AddAccessProps) => {
  const [startDate, setStartDate] = useState(DateService.newDate());
  const [endDate, setEndDate] = useState(DateService.newDate());
  return (
    <div>
      <p>By proceeding you will give user access to question bank.</p>

      <AccessDateSelector label="Access Start" date={startDate} onChange={setStartDate} />
      <br />
      <AccessDateSelector label="Access End" date={endDate} onChange={setEndDate} />
      <br />

      <GiveAccess
        text="Give access to question bank"
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        accessInstructions={[
          {
            resource: questionBank,
            crudAction: "create",
            accessStart: startDate,
            accessEnd: endDate,
          },
        ]}
      />
    </div>
  );
};

interface EditAccessProps {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
  questionBank: ResourceModel;
}

const EditAccess = ({ userId, userName, onSuccessCallbackFn, questionBank }: EditAccessProps) => {
  const [startDate, setStartDate] = useState(
    questionBank.access ? DateService.parseServerDate(questionBank.access.accessBegins?.toISOString()) : null
  );
  const [endDate, setEndDate] = useState(
    questionBank.access ? DateService.parseServerDate(questionBank.access.accessEnds?.toISOString()) : null
  );

  if (questionBank.access === null || startDate === null || endDate === null) {
    return <></>;
  }
  return (
    <div>
      <p>By proceeding you will edit user access to question bank.</p>

      <AccessDateSelector label="Access Start" date={startDate} onChange={setStartDate} />
      <br />
      <AccessDateSelector label="Access End" date={endDate} onChange={setEndDate} />
      <br />

      <GiveAccess
        text="Update access to question bank"
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        accessInstructions={[
          {
            resource: questionBank,
            crudAction: "update",
            accessStart: startDate,
            accessEnd: endDate,
          },
        ]}
      />
    </div>
  );
};
