import axios from "axios";

import ProgrammeResourceModel from "src/models/ProgrammeResourceModel";
import ProgrammeResourceType from "src/models/ProgrammeResourceType";

interface ProgrammeResourceDto {
  id: number;
  type: string;
  title: string;
  description: string;
  accessible_url: string;
}

interface IProgrammeResourceRepository {
  getProgrammeResources(): Promise<ProgrammeResourceModel[]>;
}

class ProgrammeResourceRepository implements IProgrammeResourceRepository {
  async getProgrammeResources() {
    try {
      const response = await axios.get<ProgrammeResourceDto[]>("/api/programme_resources/");
      if (response.status !== 200) throw new Error();
      return response.data.map(mapDtoToModel);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to fetch programme resources.");
    }
  }
}

export default ProgrammeResourceRepository;

const mapDtoToModel = (dto: ProgrammeResourceDto): ProgrammeResourceModel => {
  return {
    id: dto.id,
    type: dto.type as ProgrammeResourceType,
    title: dto.title,
    description: dto.description,
    accessibleUrl: dto.accessible_url,
  };
};
