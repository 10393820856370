import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkMath from "remark-math";

const rehypeKatex = React.lazy(() => import("rehype-katex"));

React.lazy(() => import("katex/dist/katex.min.css"));

const RenderMarkdown = ({ text }) => {
  return (
    <ReactMarkdown
      components={{ a: LinkRenderer, img: ImageRenderer }}
      remarkPlugins={[remarkMath, remarkGfm, remarkBreaks]}
      rehypePlugins={[rehypeKatex]}
    >
      {text}
    </ReactMarkdown>
  );
};

export default RenderMarkdown;

const LinkRenderer = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer" className="faqPageLink">
      {props.children}
    </a>
  );
};

const ImageRenderer = (props) => {
  // width and height will be undefined or a string like "100px"
  const { width, height } = getWidthAndHeight(props.title);

  return <img alt={props.alt} src={props.src} style={width && height ? { width, height } : undefined} />;
};

const getWidthAndHeight = (title) => {
  if (title) {
    if (title.split("x").length === 2) {
      const width = title.split("x")[0] + "px";
      const height = title.split("x")[1] + "px";
      return { width, height };
    }
  }
  return { width: undefined, height: undefined };
};
