import { FAQSection } from "../faqPageData";
import AnswerLink from "./answer/AnswerLink";

export const liveStudySessionsTopics: FAQSection[] = [
  {
    title: "Live Online Study Sessions",
    content: [
      {
        id: "loss1",
        question: "How Can I Register For The Live Online Study Sessions?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Log in to your account, visit the{" "}
              <AnswerLink href="/live-online-study-sessions/">Live Online Study Sessions Page</AnswerLink>, select the
              'Zoom Register for Live Sessions' button and follow the on screen instructions, you'll receive email
              confirmation from Zoom.
            </p>
            <p>On the day click the Zoom email link you'll receive and join our sessions.</p>
            <p style={{ marginBottom: 0 }}>
              Note: The Live Online Study Sessions are only available if you are enrolled on the Foundation Pharmacist
              Training Programme.
            </p>
          </div>
        ),
      },
      {
        id: "loss2",
        question: "What If I Can't Attend A Live Session?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              Don't worry, all sessions are recorded and made available for catch-up. You can access the recorded videos
              anytime through your online account during your one-year access period.
            </p>
          </div>
        ),
      },
      {
        id: "loss3",
        question: "What Topics Are Covered In The Live Online Study Sessions?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              Our sessions cover exam-based case studies, interactive Q&A, guest speakers, motivational tips, and
              support on exam structure and preparation. These sessions are designed to compliment your{" "}
              <AnswerLink href="/courses/">Exam Revision Courses</AnswerLink>.
            </p>
          </div>
        ),
      },
      {
        id: "loss4",
        question: "Can I Join The Live Online Study Sessions After I've Started The Foundation Training Year?",
        answer: (
          <div>
            <p style={{ margin: 0 }}>
              Yes, you can join the live sessions at any point during the year. Any previous sessions will be available
              as recorded videos, so you can catch up on what you missed.
            </p>
          </div>
        ),
      },
    ],
  },
];
