import { Grid } from "@mui/material";

import icon1 from "src/assets/mockPaperBenefits/1.svg";
import icon2 from "src/assets/mockPaperBenefits/2.svg";
import icon3 from "src/assets/mockPaperBenefits/3.svg";

export default function MockPaperWhoCanEnrol({ isMobile }: { isMobile: boolean }) {
  return (
    <Grid item xs={12} md={8}>
      <h2
        style={{
          marginBottom: "0.5rem",
        }}
      >
        Who Can Enrol
      </h2>
      <p
        style={{
          marginBottom: isMobile ? "2.5rem" : 0,
        }}
      >
        Pharmacy Students and Trainee Pharmacists.
      </p>

      <h2
        style={{
          marginBottom: "1rem",
        }}
      >
        Benefits
      </h2>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={{
            xs: 1.5,
            md: 3,
          }}
        >
          <Grid item xs={12} md={4}>
            <img
              src={icon1}
              alt={"Practice"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Practice"}
            />
            <h3>Practice</h3>
            <p>Practice timing, format, and difficulty.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={icon2}
              alt={"Review"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Review"}
            />
            <h3>Review</h3>
            <p>Instant feedback with answers and rationales.</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={icon3}
              alt={"Exam Success"}
              style={{
                margin: "auto",
                width: isMobile ? "30%" : "50%",
              }}
              title={"Exam Success"}
            />
            <h3>Exam Success</h3>
            <p>Refine knowledge and boost confidence.</p>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
