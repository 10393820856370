import React from "react";
import { Stack } from "@mui/material";

import { Summary, QuestionsByBank } from "./components";
import { adminBulkUploadCalculationsUrl, adminBulkUploadClinicalUrl } from "src/urls";
import UploadCsv from "./UploadCsv";
import QuestionSpread from "./components/QuestionSpread";

/** example questions metadata
 * bank: "calculations"
 * visible: true
 * id: 1
 * is_calculation: true
 * num_answers: 0
 * is_free: false
 * weighting: null
 * problem: "question has no answers"
 */
const QuestionsMetaData = ({ questionsMetaData }) => {
  if (!questionsMetaData) return <div>Loading...</div>;

  const visibleQuestionsMetaData = questionsMetaData.filter((q) => q.visible === true);

  return (
    <>
      <Stack spacing={2} direction="row" sx={{ mb: 3.5 }}>
        <UploadCsv type="Calculations" endpointUrl={adminBulkUploadCalculationsUrl} />
        <UploadCsv type="Clinical" endpointUrl={adminBulkUploadClinicalUrl} />
      </Stack>

      <Summary questionsMetaData={questionsMetaData} />
      <QuestionsByBank questionsMetaData={visibleQuestionsMetaData} />
      <QuestionSpread questionsMetaData={visibleQuestionsMetaData} />
    </>
  );
};

export default QuestionsMetaData;
