import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Divider, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";

import useIsLightMode from "src/hooks/useIsLightMode";
import PageTemplate from "src/pages/PageTemplate";

import LoadingScreen from "src/components/loading-screen";
import useResponsive from "src/hooks/useResponsive";
import { loadingToast } from "src/components_with_stories/toast/toast";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";
import { mockPaperTopics } from "src/config/faq/mockPaperTopics";

import useMockPapers from "./hooks/useMockPapers";
import MockPaper from "./components/MockPaper";
import MockPaperTopSection from "./components/MockPaperTopSection";
import MockPaperHowItWorks from "./components/MockPaperHowItWorks";
import MockPaperWhoCanEnrol from "./components/MockPaperWhoCanEnrol";

const MockPaperPage = () => {
  const isMdUp = useResponsive("up", "md");
  const { mockPapers, isLoading } = useMockPapers();
  const isMobile = !isMdUp;

  const isLightMode = useIsLightMode();
  const navigate = useNavigate();

  const quizExpiryString = useLocalStorage("mp_quizExpiry")[0] as string | null;
  const quizExpiry = quizExpiryString ? new Date(quizExpiryString) : null;

  useEffect(() => {
    // if quiz has expired, remove all local storage
    // else, redirect back to quiz
    if (quizExpiry) {
      const now = new Date();
      const hasExpired = now > quizExpiry;
      if (!hasExpired) {
        navigate(`/attempt-mock-paper/0/`);
        loadingToast("Mock Paper in progress.", isLightMode);
        return;
      }
    }

    localStorage.removeItem("mp_questionSet");
    localStorage.removeItem("mp_questionSetAttempt");
    localStorage.removeItem("mp_quizMetaData");
    localStorage.removeItem("mp_quizState");
    localStorage.removeItem("mp_quizExpiry");
    localStorage.removeItem("mp_questions");
    localStorage.removeItem("mp_timerHidden");
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <Helmet link={[{ rel: "canonical", href: "/mock-paper/" }]}>
        <title>Mock Paper | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Attempt our online mock registration assessment with tailored questions aligned with the GPHC registration framework."
        />
        <meta
          name="keywords"
          content="Pharmacy registration assessment, GPHC registration mock exam, Trainee Pharmacist Exam, Online Pre-Reg mock assessment, GPHC registration framework, Pharmacy foundation training programme, Mock registration assessment questions"
        />
      </Helmet>
      <PageTemplate>
        <MockPaperTopSection />

        <Divider sx={{ mt: { md: 4 } }} style={{ borderColor: "transparent" }} />

        <Grid container spacing={{ xs: 0, md: 10 }}>
          <MockPaperHowItWorks />
          <MockPaperWhoCanEnrol isMobile={isMobile} />
        </Grid>

        <Divider sx={{ mt: { xs: 7, md: 6 } }} style={{ borderColor: "transparent" }} />

        {mockPapers?.map((mockPaper) => <MockPaper key={mockPaper.mockPaperId} mockPaper={mockPaper} />)}
      </PageTemplate>
      <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />

      <MiniFAQsSPC topics={mockPaperTopics} />
    </>
  );
};

export default MockPaperPage;
