import React, { useEffect, useState } from "react";

import { FormControl, InputLabel, OutlinedInput, Button, Tooltip, Typography, Divider, Alert } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import EditIcon from "@mui/icons-material/Edit";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { confirmToast, errorToast, dismissAllToasts } from "src/components_with_stories/toast";

import "./QBQuizMenu.css";
import { calculationsBank } from "src/utils/format_bank";
import { weightingOptions } from "./QBQuiz";
import { QBQuizInstructions } from "./QBQuizInstructions";
import MobileTooltip from "src/newComponents/MobileTooltip";
import useResponsive from "src/hooks/useResponsive";

const white = "rgba(255, 255, 255, 0.8)";
const black = "rgba(0, 0, 0, 0.8)";

const defaultBank = "";
const defaultWeighting = "";
const defaultNumQuestions = "";
const defaultDuration = "";

const QBQuizMenu = ({
  onStart,
  loadingQuestions,
  isLightMode,
  disableConfig,
  selectedBank,
  initialWeighting,
  initialNumQuestions,
  initialDuration,
}) => {
  const isMobile = useResponsive("down", "sm");

  const [selectedWeighting, setSelectedWeighting] = useState(initialWeighting ?? defaultWeighting);
  const [numQuestions, setNumQuestions] = useState(initialNumQuestions ?? defaultNumQuestions);
  const [selectedDuration, setSelectedDuration] = useState(initialDuration ?? defaultDuration);

  const [manuallyEditDuration, setManuallyEditDuration] = useState(false);

  const isLoadingQuestions = loadingQuestions === true;

  // when you change num questions or toggle discard, calculate again
  useEffect(() => {
    if (manuallyEditDuration === false) {
      const isClinical = selectedBank !== calculationsBank;
      const minsPerQuestion = isClinical ? 1 : 3;
      const recommendedDuration = minsPerQuestion * numQuestions;
      setSelectedDuration(recommendedDuration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numQuestions, manuallyEditDuration]);

  // when you change bank, turn off edit duration mode
  useEffect(() => {
    const isClinical = selectedBank !== calculationsBank;
    const minsPerQuestion = isClinical ? 1 : 3;
    const recommendedDuration = minsPerQuestion * numQuestions;
    setSelectedDuration(recommendedDuration);
    setManuallyEditDuration(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank]);

  const selectedClinical = selectedBank !== calculationsBank;
  const canShowWeighting = selectedClinical && selectedWeighting !== null;

  return (
    <div data-cy="QBQuiz_QBQuizMenu">
      <QBQuizInstructions selectedBank={selectedBank} isMockPaper={false} />
      <Divider sx={{ mt: { xs: 3, md: 3 } }} style={{ borderColor: "transparent" }} />
      {disableConfig && (
        <>
          <Alert severity={"warning"} sx={{ fontSize: "1rem" }}>
            In trial mode, you cannot modify the
            {canShowWeighting ? " clinical weighting or the " : ""} number of questions per quiz.
            <br />
            You can modify the duration by clicking on the pencil icon beside the Recommended Duration.
          </Alert>
          <Divider sx={{ mt: { xs: 3, md: 3 } }} style={{ borderColor: "transparent" }} />
        </>
      )}

      {selectedClinical && (
        <div style={{ display: "flex", alignItems: "baseline", gap: "0.5rem" }}>
          <SelectWeighting
            selectedWeighting={selectedWeighting}
            setSelectedWeighting={setSelectedWeighting}
            selectedBank={selectedBank}
            disableConfig={disableConfig}
            isLightMode={isLightMode}
          />
          <MobileTooltip
            title="Select Weighting"
            content={
              <>
                <span style={{ display: "block", margin: "8px 0" }}>
                  You can select between Low, Medium or High Weighting Questions.
                </span>
              </>
            }
          />
        </div>
      )}

      <div style={{ display: "flex", alignItems: "baseline", gap: "0.5rem" }}>
        <SelectNumberOfQuestions
          numQuestions={numQuestions}
          setNumQuestions={setNumQuestions}
          disableConfig={disableConfig}
          isLightMode={isLightMode}
        />
        <MobileTooltip
          title="Select Number of Questions"
          content={<span style={{ display: "block", margin: "8px 0" }}>You can select 5-40 questions each time.</span>}
        />
      </div>

      <div style={{ display: "flex", alignItems: "baseline", gap: "0.5rem" }}>
        <SelectDuration
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
          manuallyEditDuration={manuallyEditDuration}
          setManuallyEditDuration={setManuallyEditDuration}
          isLightMode={isLightMode}
        />{" "}
        <MobileTooltip
          title="Select Duration"
          content={
            <>
              <span style={{ display: "block", margin: "8px 0" }}>
                A recommended duration is automatically calculated for you based on the question bank and number of
                questions.
              </span>

              <span style={{ display: "block", margin: "8px 0" }}>Clinical 1 minute</span>
              <span style={{ display: "block", margin: "8px 0" }}>Calculations 3 minutes</span>
              <span style={{ display: "block", margin: "8px 0" }}>
                However, you can edit the recommended duration by clicking on the pencil icon.
              </span>
            </>
          }
        />
      </div>

      <Button
        size="small"
        variant="contained"
        color="primary"
        data-cy="QBQuiz_StartButton"
        disabled={isLoadingQuestions}
        sx={{ mt: 1 }}
        onClick={() => {
          if (selectedBank === defaultBank) {
            errorToast("Please select a bank.", isLightMode);
            return;
          }

          if (selectedBank !== calculationsBank) {
            if (selectedWeighting === defaultWeighting) {
              errorToast("Please select a weighting for Clinical questions.", isLightMode);
              return;
            }
          }

          if (numQuestions === defaultNumQuestions) {
            errorToast("Please enter how many questions you would like.", isLightMode);
            return;
          }

          if (selectedDuration === defaultDuration) {
            errorToast("Please enter how long you would like the quiz to take, in minutes.", isLightMode);

            return;
          }

          if (numQuestions < 5) {
            errorToast("Please enter at least 5 questions.", isLightMode);
            return;
          }

          if (selectedDuration < 5) {
            errorToast("Please enter at least 5 minutes.", isLightMode);
            return;
          }

          const mobileMessage =
            "We've detected you are on a mobile. For the best experience, we highly recommend you switch to a tablet or desktop.";
          const confirmMessage = `You have ${selectedDuration} minutes to complete ${numQuestions} ${
            selectedBank !== calculationsBank ? `${selectedWeighting.toLowerCase()} weighted ` : ""
          }${selectedBank} questions.`;
          const ourRecommendationMessage =
            "recommend that you are in a quiet, distraction-free space as you cannot pause, save, or resume the quiz at a later time.";

          const message = (
            <>
              {isMobile && (
                <>
                  <strong>{mobileMessage}</strong>
                  <br />
                  <br />
                </>
              )}
              {isMobile ? "In addition, we " : "We "}
              {ourRecommendationMessage}
              <br />
              <br />
              {confirmMessage}
            </>
          );

          confirmToast({
            message: message,
            isLightMode: isLightMode,
            onConfirm: () => {
              dismissAllToasts();

              onStart(selectedBank, selectedWeighting, numQuestions, selectedDuration);
            },
            confirmText: "Start Quiz",
          });
        }}
      >
        {isLoadingQuestions ? "Starting Quiz..." : "Start Quiz"}
      </Button>
    </div>
  );
};

export default QBQuizMenu;

const SelectWeighting = ({ selectedWeighting, setSelectedWeighting, selectedBank, disableConfig, isLightMode }) => {
  const isCalculations = selectedBank === calculationsBank;
  return (
    <FormControl
      style={{
        marginTop: "0.5rem",
        marginBottom: "1rem",
        width: "220px",
      }}
      id="weighting-select-form"
    >
      <InputLabel id="weighting-select-label" sx={{ color: isLightMode ? black : white }}>
        Clinical Weighting
      </InputLabel>
      <Select
        labelId="weighting-select-label"
        id="weighting-select"
        value={selectedWeighting}
        label="Clinical Weighting"
        onChange={(e) => setSelectedWeighting(e.target.value)}
        size="small"
        disabled={isCalculations || disableConfig}
        className={isLightMode ? "light-field" : "dark-field"}
      >
        {weightingOptions.map((option, idx) => {
          return (
            <MenuItem key={idx} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const SelectNumberOfQuestions = ({ numQuestions, setNumQuestions, disableConfig, isLightMode }) => {
  return (
    <FormControl
      style={{
        marginTop: "0.5rem",
        marginBottom: "1rem",
        width: "220px",
      }}
      id="num-questions-select-form"
    >
      <InputLabel htmlFor="num-questions-select-label" sx={{ color: isLightMode ? black : white }}>
        Number of Questions
      </InputLabel>
      <OutlinedInput
        id="num-questions-select-label"
        sx={{ fontSize: 16 }}
        label="Number of Questions"
        value={numQuestions}
        onChange={(e) => {
          const newValue = e.target.value;
          setNumQuestions(newValue);
        }}
        size="small"
        type="number"
        disabled={disableConfig}
        className={isLightMode ? "light-field" : "dark-field"}
      />
    </FormControl>
  );
};

const SelectDuration = ({
  selectedDuration,
  setSelectedDuration,
  manuallyEditDuration,
  setManuallyEditDuration,
  isLightMode,
}) => {
  const recommendedDuration = manuallyEditDuration === false;
  const [showTooltip, setShowTooltip] = useState(true);

  const numDigits = selectedDuration.toString().length;
  const minutesMargin = 90 - numDigits * 12;

  const id = "duration-select-label";

  return (
    <FormControl
      style={{
        marginTop: "0.75rem",
        marginBottom: "1rem",
        width: "220px",
      }}
      variant="outlined"
    >
      <InputLabel htmlFor="duration-select-label" sx={{ color: isLightMode ? black : white }}>
        {recommendedDuration && "Recommended "}Duration
      </InputLabel>

      <OutlinedInput
        id={id}
        sx={{ fontSize: 16 }}
        label={recommendedDuration ? "Recommended Duration" : "Duration"}
        value={selectedDuration}
        onChange={(e) => {
          const newValue = e.target.value;
          setSelectedDuration(newValue);
        }}
        size="small"
        type="number"
        disabled={manuallyEditDuration === false}
        endAdornment={
          <InputAdornment position="end" sx={{ color: isLightMode ? black : white }}>
            <Typography variant="body2" sx={{ mr: minutesMargin + "px" }}>
              minutes
            </Typography>
            <IconButton
              aria-label="manually edit duration"
              onClick={() => {
                setShowTooltip(false);
                setTimeout(() => {
                  setManuallyEditDuration((a) => !a);
                }, 50);
                setTimeout(() => {
                  setShowTooltip(true);
                }, 150);
              }}
              edge="end"
            >
              {recommendedDuration ? (
                <>
                  {showTooltip ? (
                    <Tooltip title="Edit recommended duration">
                      <EditIcon style={{ color: isLightMode ? black : white }} />
                    </Tooltip>
                  ) : (
                    <EditIcon style={{ color: isLightMode ? black : white }} />
                  )}
                </>
              ) : (
                <>
                  {showTooltip ? (
                    <Tooltip title="Discard edit">
                      <AutoFixHighIcon style={{ color: isLightMode ? black : white }} />
                    </Tooltip>
                  ) : (
                    <AutoFixHighIcon style={{ color: isLightMode ? black : white }} />
                  )}
                </>
              )}
            </IconButton>
          </InputAdornment>
        }
        className={isLightMode ? "light-field" : "dark-field"}
      />
    </FormControl>
  );
};
