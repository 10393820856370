import React from "react";
import { RHFMultiCheckbox } from "src/components/hook-form/RHFCheckbox";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Typography, Button, OutlinedInput, FormControl, InputAdornment, InputLabel } from "@mui/material";
import RenderMarkdown from "./RenderMarkdown";
import QuestionResource from "./QuestionResource";
import { getActualAnswer } from "./utils";
import { AnswerFeedback } from "./components";
import useIsLightMode from "src/hooks/useIsLightMode";

import {
  QUIZ_IN_PROGRESS_ON_QUESTION_PAGE,
  QUIZ_FINISHED_FEEDBACK_PAGE,
  QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME,
  QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE,
  QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME,
  QUIZ_PREVIEW_MODE,
} from "../QuizStates";
import ReportQuestion from "./ReportQuestion";
import QBQuestionFlag from "./QBQuestionFlag";

export const paleOrange = "rgba(250, 84, 28, 0.2)";
export const paleGreen = "rgba(0, 157, 71, 0.2)";
export const palePurple = "rgba(117, 79, 254, 0.2)";

/*
  This question can be shown at 5 different stages.
  1. a question in a quiz (with back and next buttons)
  2. a question on the review page (with edit button to open in review mode)
  3. a question in review mode (with cancel and save buttons)
  4. a question on the feedback page (with no buttons and with feedback)
  5. and in preview mode (no buttons, no feedback)
*/
const QBQuestion = ({
  obj,
  quizState,
  nextQuestion,
  backQuestion,
  setAnswerId,
  toggleFlag,
  enterReviewMode,
  returnToFeedback,
}) => {
  const isLightMode = useIsLightMode();

  const { isCalculation, currentQuestionNumber, totalNumberOfQuestions, question, answerId, isFlagged } = obj;

  const {
    questionId,
    questionTitle,
    questionText,
    possibleAnswers,
    actualAnswerId,
    actualAnswerReason,
    answerUnit,
    resourceImageUrl,
    visible,
    questionSet,
  } = question;

  const FormSchema = Yup.object().shape({
    multiCheckbox: Yup.array().min(1, "Choose at least one option"),
  });

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: { multiCheckbox: [] },
  });

  const isFirstQuestion = currentQuestionNumber === 1;
  const isLastQuestion = currentQuestionNumber === totalNumberOfQuestions;

  const actualAnswer = getActualAnswer(isCalculation, answerUnit, actualAnswerId, possibleAnswers);

  const showQuestionFeedback =
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME;

  const quizEnded =
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE ||
    quizState === QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME;

  const quizInProgress = quizState === QUIZ_IN_PROGRESS_ON_QUESTION_PAGE;

  const questionPreviewMode = quizState === QUIZ_PREVIEW_MODE;

  return (
    <div>
      {showQuestionFeedback && (
        <Button size="small" variant="contained" color="secondary" onClick={returnToFeedback} sx={{ mb: "30px" }}>
          Return to Feedback Summary
        </Button>
      )}
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        <strong>
          Question {currentQuestionNumber} of {totalNumberOfQuestions}{" "}
          {questionPreviewMode && (
            <div style={{ display: "inline-block" }}>
              <em>{visible === false && " (hidden) "}</em>
            </div>
          )}
          {questionPreviewMode && (
            <div style={{ display: "inline-block", marginLeft: "6px" }}>
              <em>{questionSet ? " (belongs to Question Set)" : " (belongs to Question Bank)"}</em>
            </div>
          )}
          {quizInProgress && <QBQuestionFlag isFlagged={isFlagged} toggleFlag={toggleFlag} />}
        </strong>
      </Typography>

      <RenderMarkdown text={questionText} />

      {questionTitle && (
        <>
          <RenderMarkdown text={questionTitle} />
          <div style={{ marginBottom: "1rem" }}></div>
        </>
      )}

      <QuestionResource resourceImageUrl={resourceImageUrl} />

      <div
        style={{
          backgroundColor: paleOrange,
          padding: isCalculation ? "1rem 1rem 1.5rem 1rem" : "1rem",
        }}
      >
        <FormProvider control={methods.control} methods={methods} formState={methods.formState}>
          {isCalculation ? (
            <>
              <FormControl sx={{ width: "min(15rem,85vw)", mt: 1, mb: 1 }}>
                <InputLabel size="small" htmlFor="answer-amount" sx={{ color: isLightMode ? "black" : "white" }}>
                  Answer
                </InputLabel>
                <OutlinedInput
                  id="answer-amount"
                  size="small"
                  startAdornment={
                    answerUnit === "£" ? (
                      <InputAdornment position="start" sx={{ color: isLightMode ? "black" : "white" }}>
                        {answerUnit}
                      </InputAdornment>
                    ) : undefined
                  }
                  endAdornment={
                    answerUnit !== "£" ? (
                      <InputAdornment position="start" sx={{ color: isLightMode ? "black" : "white" }}>
                        {answerUnit}
                      </InputAdornment>
                    ) : undefined
                  }
                  label="Answer"
                  value={answerId ?? ""}
                  onChange={(e) => {
                    if (quizEnded) return;
                    setAnswerId && setAnswerId(e.target.value);
                  }}
                  disabled={quizEnded}
                  className={isLightMode ? "light-field" : "dark-field"}
                />
              </FormControl>
            </>
          ) : (
            <RHFMultiCheckbox
              row={false}
              name={`question${questionId}`}
              label="Select one of the following:"
              spacing={0}
              onChange={(newAnswerId) => {
                if (quizEnded) return;
                setAnswerId && setAnswerId(newAnswerId);
              }}
              disabled={quizEnded}
              value={answerId ? [answerId] : []}
              options={possibleAnswers.map((answer) => {
                return {
                  value: answer.answerId,
                  label: <>{answer.answerText}</>,
                };
              })}
            />
          )}
        </FormProvider>

        {quizInProgress && (
          <>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
              }}
            >
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => backQuestion(answerId)}
                disabled={isFirstQuestion}
              >
                Previous Question
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => nextQuestion(answerId)}
                disabled={isLastQuestion}
              >
                Next Question
              </Button>
              <Button size="small" variant="contained" color="secondary" onClick={enterReviewMode}>
                Review Answers {isLastQuestion && "and Submit"}
              </Button>
            </div>
          </>
        )}
      </div>

      {(quizInProgress || showQuestionFeedback || questionPreviewMode) && (
        <>
          <ReportQuestion questionId={questionId} />
          <Typography sx={{ mt: 2 }}>
            If you cannot answer a question, continue to the next question, and if there's time remaining at the end,
            you'll have the chance to revisit it before submitting your answers.
          </Typography>
        </>
      )}

      {showQuestionFeedback && (
        <>
          <AnswerFeedback
            isCorrect={actualAnswerId === answerId}
            formattedActualAnswer={actualAnswer}
            actualAnswerReason={actualAnswerReason}
          />
        </>
      )}

      {questionPreviewMode && (
        <>
          <AnswerFeedback
            isCorrect={true}
            formattedActualAnswer={actualAnswer}
            actualAnswerReason={actualAnswerReason}
          />
          <AnswerFeedback
            isCorrect={false}
            formattedActualAnswer={actualAnswer}
            actualAnswerReason={actualAnswerReason}
          />
        </>
      )}
    </div>
  );
};

export default QBQuestion;
