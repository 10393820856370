import { Divider, Stack, Card, Typography, Box, Avatar } from "@mui/material";

import TextMaxLine from "src/components/text-max-line";
import Image from "src/components/image/Image";
import ResourceCost from "src/newComponents/ResourceCost";

interface AddOnCourseProps {
  title: string;
  description: string;
  image: string;
  cost: number;
  originalCost: number;
  provider: {
    name: string;
    image: string;
  };
}

export default function AddOnCourse({ title, description, image, cost, originalCost, provider }: AddOnCourseProps) {
  return (
    <Card
      sx={{
        display: { sm: "flex" },
        "&:hover": {
          boxShadow: (theme: any) => theme.customShadows.z24,
        },
      }}
    >
      <Box sx={{ flexShrink: { sm: 0 } }}>
        <Image
          alt={title}
          src={image}
          sx={{
            height: 1,
            objectFit: "cover",
            width: { sm: 240 },
          }}
          loading="eager"
        />
      </Box>

      <Stack spacing={3} sx={{ p: 3 }}>
        <Stack
          spacing={{
            xs: 3,
            sm: 1,
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <TextMaxLine variant="h6" line={1}>
              {title}
            </TextMaxLine>
          </Stack>

          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </Stack>

        <Divider sx={{ borderStyle: "dashed", display: { sm: "none" } }} />

        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{
            color: "text.disabled",
            "& > *:not(:last-child)": { mr: 2.5 },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar src={provider.image} />
            <Typography variant="body2">{provider.name}</Typography>
          </Stack>

          <ResourceCost cost={cost} originalCost={originalCost} paddingTop={false} />
        </Stack>
      </Stack>
    </Card>
  );
}
