import generateLinkToEditQuestion from "./generateLinkToEditQuestion";

const getHiddenQuestions = (questionsMetaData) => {
  return questionsMetaData
    .filter((questionMetaData) => questionMetaData.visible === false)
    .map((questionMetaData) => {
      const isSetQuestion = questionMetaData.question_set !== null && questionMetaData.question_set !== undefined;
      return {
        id: questionMetaData.id,
        link: generateLinkToEditQuestion(questionMetaData.id, isSetQuestion),
      };
    });
};

export default getHiddenQuestions;
