import React from "react";

interface AnswerProps {
  lines: (string | React.ReactElement)[];
}

const Answer = ({ lines }: AnswerProps) => {
  return (
    <>
      {lines.map((line, index) => {
        const isFirstLine = index === 0;
        const isLastLine = index === lines.length - 1;
        const style = {
          marginTop: isFirstLine ? 0 : undefined,
          marginBottom: isLastLine ? 0 : undefined,
        };

        return (
          <p key={index} style={style}>
            {line}
          </p>
        );
      })}
    </>
  );
};

export default Answer;
