export const NAV = {
  W_DRAWER: 280,
  H_ITEM: 48,
};

export const ICON = {
  NAV_ITEM: 24,
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 94,
};
