import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";

import { BasketContext } from "src/context/BasketContext";
import useResponsive from "src/hooks/useResponsive";

import CartSummary from "./components/CartSummary";
import CartItemsDesktop from "./components/CartItemsDesktop";
import ContinueShoppingButton from "./components/ContinueShoppingButton";
import CartItemsMobile from "./components/CartItemsMobile";
import PageTemplate from "../PageTemplate";
import { getConflictingItems } from "./utils/getConflictingItems";
import { confirmToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";

const CheckoutPage = () => {
  const { items, forceRemoveItemsFromBasket, removeItemFromBasket } = useContext(BasketContext);

  const isMobile = useResponsive("down", "md");
  const isLightMode = useIsLightMode();

  const basketHasItems = items.length > 0;

  useEffect(() => {
    const conflictingItems = getConflictingItems(items);

    if (conflictingItems.length > 0) {
      const message = (
        <>
          <p style={{ marginTop: 0 }}>
            The following items are already included in the Foundation Training Programme. Would you like to remove them
            from your basket?
          </p>
          <ul>
            {conflictingItems.map((item) => (
              <li key={item.stripePriceId}>{item.name}</li>
            ))}
          </ul>
        </>
      );

      confirmToast({
        message: message,
        isLightMode: isLightMode,
        onConfirm: () => {
          forceRemoveItemsFromBasket(conflictingItems);
        },
        confirmText: "Remove",
      });
    }
  }, []);

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/checkout/" }]}>
        <title>Checkout | Pre-Reg Shortcuts</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageTemplate title="Checkout">
        {!basketHasItems && (
          <div style={{ margin: "2rem 0" }}>
            It looks like your basket is empty. Check out{" "}
            <a href="/products/" className="faqPageLink">
              our products
            </a>
            .
          </div>
        )}

        {basketHasItems && (
          <>
            <Grid container spacing={{ xs: 5, md: 8 }}>
              <Grid item xs={12} md={8}>
                {isMobile ? (
                  <CartItemsMobile items={items} removeItemFromBasket={removeItemFromBasket} />
                ) : (
                  <CartItemsDesktop items={items} removeItemFromBasket={removeItemFromBasket} />
                )}
                {!isMobile && <ContinueShoppingButton />}
              </Grid>
              <Grid item xs={12} md={4}>
                <CartSummary items={items} isMobile={isMobile} />
              </Grid>
            </Grid>
            {isMobile && <ContinueShoppingButton />}
          </>
        )}
      </PageTemplate>
    </>
  );
};

export default CheckoutPage;
