import React, { useState } from "react";
import { Button } from "@mui/material";

import MoreInformationModal from "../MoreInformationModal";

interface LearnMoreButtonWithModalProps {
  title: string;
  content: React.ReactNode;
  rightMargin?: boolean;
  buttonText?: string;
  contained?: boolean;
  size?: "small" | "medium";
}

const LearnMoreButtonWithModal = ({
  title,
  content,
  rightMargin,
  buttonText = "Learn More",
  contained = false,
  size = "small",
}: LearnMoreButtonWithModalProps) => {
  const [open, setOpen] = useState(false);

  const style = rightMargin ? { marginRight: "1rem" } : {};

  return (
    <>
      <Button
        variant={contained ? "contained" : "outlined"}
        color="secondary"
        style={style}
        onClick={() => setOpen(true)}
        size={size}
      >
        {buttonText}
      </Button>
      {open && <MoreInformationModal title={title} content={content} handleClose={() => setOpen(false)} />}
    </>
  );
};

export default LearnMoreButtonWithModal;
