import { Dialog, Box, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { m, AnimatePresence } from "framer-motion";
import { varFade } from "./variants";

export default function DialogAnimate({ title, open, onClose, showCloseIcon, variants, children, sx, ...other }) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          PaperComponent={(props) => (
            <Box
              component={m.div}
              {...(variants ||
                varFade({
                  distance: 120,
                  durationIn: 0.32,
                  durationOut: 0.24,
                  easeIn: "easeInOut",
                }).inUp)}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box onClick={onClose} sx={{ width: "100%", height: "100%", position: "fixed" }} />
              <Paper sx={sx} {...props}>
                {props.children}
              </Paper>
            </Box>
          )}
          {...other}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} style={{ paddingRight: "2.75rem", paddingBottom: "0px" }}>
            <span>{title}</span>
            {showCloseIcon && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent
            style={{
              paddingBottom: 0,
              marginBottom: "1rem",
              marginTop: "1rem",
              paddingLeft: "1rem",
            }}
          >
            {children}
          </DialogContent>
        </Dialog>
      )}
    </AnimatePresence>
  );
}
