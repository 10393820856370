import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "./AppContext";
import CourseRepository from "src/repository/courseRepository";
import CourseModel from "src/models/CourseModel";

export const CoursesContext = React.createContext(
  {} as {
    courses: CourseModel[];
    setCourses: (courses: CourseModel[]) => void;
    isLoadingCourses: boolean;
  }
);

export const CoursesContextProvider = (props: React.PropsWithChildren<Record<string, never>>) => {
  const { isAuthenticated } = useContext(AppContext);

  const [courses, setCourses] = useState<CourseModel[]>([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(isAuthenticated);

  const courseRepository = new CourseRepository();

  useEffect(() => {
    courseRepository
      .getCourses()
      .then((courses) => setCourses(courses))
      .finally(() => setIsLoadingCourses(false));
  }, [isAuthenticated]);

  return (
    <CoursesContext.Provider value={{ courses: courses, setCourses, isLoadingCourses }}>
      {props.children}
    </CoursesContext.Provider>
  );
};
