import { Alert, Typography } from "@mui/material";

import ConfigurationModel from "src/models/ConfigurationModel";
import RegisterForSessions from "./RegisterForSessions";
import Iconify from "src/components/icon/Iconify";

interface DSPLiveSessionsTopSectionProps {
  userHasAccess: boolean;
  configurations: ConfigurationModel[];
}

const DSPLiveSessionsTopSection = ({ userHasAccess, configurations }: DSPLiveSessionsTopSectionProps) => {
  return (
    <>
      <Typography sx={{ margin: "1rem 0" }}>
        Our live drop-in sessions will cover key points from the Designated Supervisor Programme and are a great way to
        connect with other supervisors throughout the foundation training year.
      </Typography>

      <Typography sx={{ margin: "1rem 0" }}>
        To register for the live drop-in sessions, select 'Zoom Register for Live Sessions' above and join us live on
        the day!
      </Typography>
      <Typography sx={{ margin: "1rem 0" }}>
        If you're unable to attend live, we upload the recorded videos so you can catch up any time.
      </Typography>

      {userHasAccess && <RegisterForSessions configurations={configurations} />}
      {!userHasAccess && (
        <Alert icon={<Iconify width={24} icon={"carbon:locked"} />} severity="warning">
          The Designated Supervisor Programme requires access. <br />
          For more details, contact us at{" "}
          <a className="faqPageLink" target="_blank" href="mailto:info@preregshortcuts.com" rel="noreferrer">
            info@preregshortcuts.com
          </a>
          .
        </Alert>
      )}
    </>
  );
};

export default DSPLiveSessionsTopSection;
