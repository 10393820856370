import { useFormContext, Controller } from "react-hook-form";

import { Radio, FormLabel, FormGroup, FormControl, FormHelperText, FormControlLabel } from "@mui/material";
import useIsLightMode from "src/hooks/useIsLightMode";

export function RHFCheckbox({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel control={<Radio {...field} checked={field.value} />} {...other} />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}

export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, onChange, ...other }) {
  const isLightMode = useIsLightMode();
  const { control } = useFormContext();

  const getSelected = (selectedItems, item) => {
    if (selectedItems === undefined) {
      // initially nothing was selected, select it
      onChange && onChange(item);
      return [item];
    } else if (selectedItems.includes(item)) {
      // item was already selected, so keep it selected
      onChange && onChange(item);
      return [];
    } else {
      // item was not selected, so select it
      onChange && onChange(item);
      return [item];
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          {label && (
            <FormLabel
              sx={{
                typography: "body1",
                fontWeight: "bold",
                color: isLightMode ? "black" : "white",
              }}
            >
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              ...(row && {
                flexDirection: "row",
              }),
              "& .MuiFormControlLabel-root": {
                "&:not(:last-of-type)": {
                  mb: spacing || 0,
                },
                ...(row && {
                  mr: 0,
                  "&:not(:last-of-type)": {
                    mr: spacing || 2,
                  },
                }),
              },
            }}
          >
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Radio
                      checked={other.value ? other.value.includes(option.value) : false}
                      onChange={() => field.onChange(getSelected(field.value, option.value))}
                    />
                  }
                  label={option.label}
                  {...other}
                />
              );
            })}
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
