import React from "react";
import { Helmet } from "react-helmet-async";

import PageTemplate from "src/pages/PageTemplate";

import WhyUsContent from "./components/WhyUsContent";

const WhyUsPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/why-us/" }]}>
        <title>Why Us | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Discover why we are the leading online pharmacy foundation year provider with an outstanding pass rate in the GPhC registration assessment."
        />
        <meta
          name="keywords"
          content="Why Us, Choose Pre-Reg Shortcuts, Rated Excellent on Trustpilot, Transforming Pharmacists, Online courses, Leading Pharmacy Education, Professional Pharmacists, Career Success"
        />
      </Helmet>
      <PageTemplate title="Why Choose Pre-Reg Shortcuts?">
        <WhyUsContent />
      </PageTemplate>
    </>
  );
};

export default WhyUsPage;
