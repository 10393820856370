import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Stack, IconButton, InputAdornment } from "@mui/material";

import Iconify from "src/components/icon/Iconify";
import FormProvider, { RHFTextField } from "src/components/hook-form";

import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";

export default function AuthSetNewPasswordForm({ confirmPasswordReset }) {
  const isLightMode = useIsLightMode();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const LoginSchema = Yup.object().shape({
    password1: Yup.string().required("Password is required.").min(8, "Your password must be at least 8 characters."),
    password2: Yup.string()
      .required("Confirm password is required.")
      .min(8, "Your password must be at least 8 characters.")
      .oneOf([Yup.ref("password1"), null], "Your passwords do not match."),
  });

  const defaultValues = {
    password1: "",
    password2: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const onSubmit = async ({ password1, password2 }) => {
    if (password1 === password2) {
      confirmPasswordReset(password1);
    } else {
      errorToast("Passwords don't match!", isLightMode);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5} alignItems="flex-end">
        <RHFTextField
          name="password1"
          label="Password"
          hasFocus={true}
          type={showPassword1 ? "text" : "password"}
          tabIndex={1}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword1} edge="end" inputProps={{ tabIndex: 4 }}>
                  <Iconify icon={showPassword1 ? "carbon:view" : "carbon:view-off"} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="password2"
          label="Confirm Password"
          type={showPassword2 ? "text" : "password"}
          tabIndex={2}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword2} edge="end" inputProps={{ tabIndex: 5 }}>
                  <Iconify icon={showPassword2 ? "carbon:view" : "carbon:view-off"} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          tabIndex={3}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
