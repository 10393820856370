import { Typography, Stack, Button, Box, Grid } from "@mui/material";
import TeamMember from "../assets/meetTheTeam/TeamMember";

import umarImage from "src/assets/meetTheTeam/umar.png";
import marvinImage from "src/assets/meetTheTeam/marvin.png";
import georginaImage from "src/assets/meetTheTeam/georgina.png";
import shirinImage from "src/assets/meetTheTeam/shirin.jpg";

import teamPictureOne from "src/assets/meetTheTeam/team_picture.png";
import teamPictureTwo from "src/assets/meetTheTeam/team_picture_2.png";

const MeetTheTeamSPC = ({ isHomePage }) => {
  const members = [
    {
      id: 1,
      name: "Marvin",
      role: "Co-founder and Clinical lead",
      photo: marvinImage,
      linkedIn: "https://www.linkedin.com/in/marvin-munzu-a8794b96/",
    },
    {
      id: 2,
      name: "Umar",
      role: "Co-founder and Calculations lead",
      photo: umarImage,
      linkedIn: "https://www.linkedin.com/in/umar-majid-73a56419a/",
    },
    {
      id: 3,
      name: "Georgina",
      role: "MEP & OTC lead",
      photo: georginaImage,
      linkedIn: "https://www.linkedin.com/in/georgina-gillard-27a99b1b9/",
    },
    {
      id: 4,
      name: "Shirin Alwash-Nuaimi",
      role: "Designated Supervisor Programme Lead",
      photo: shirinImage,
      linkedIn: "https://www.linkedin.com/in/shirinalwash/",
    },
  ];
  return (
    <>
      <Stack spacing={3} sx={{ maxWidth: "90vw", mx: "auto", textAlign: "center" }}>
        <Typography variant="h2">Meet Our Tutors</Typography>

        <Typography sx={{ color: "text.secondary" }}>
          Learn from industry leading professionals dedicated to guiding you through your foundation year journey.
        </Typography>
      </Stack>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(4, 1fr)",
          },
        }}
      >
        {members.map((member) => (
          <TeamMember key={member.id} member={member} />
        ))}
      </Box>

      {isHomePage && (
        <Stack alignItems="center" sx={{ mt: 1.5 }}>
          <Button variant="outlined" size="large" color="inherit" href="/about">
            Learn More
          </Button>
        </Stack>
      )}

      {!isHomePage && (
        <Stack spacing={3} sx={{ textAlign: "center", mt: 6 }}>
          <Typography variant="h2">Meet The Rest Of Our Team</Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Head of Admin: Vince Turner
            <br />
            <br />
            Ambassadors: Aimee Davidson, Payal Radia, Rakchhya Kc, Tanya Patel, Zain Ahmed
          </Typography>

          <Grid
            container
            justifyContent={{ xs: "flex-start", md: "space-evenly" }}
            sx={{ gap: "1rem", justifyContent: "center" }}
          >
            <img
              src={teamPictureOne}
              alt="Team"
              title="Team"
              width={350}
              height={304}
              loading="lazy"
              style={{ borderRadius: "1rem" }}
            />
            <img
              src={teamPictureTwo}
              alt="Team"
              title="Team"
              width={350}
              height={304}
              loading="lazy"
              style={{ borderRadius: "1rem" }}
            />
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default MeetTheTeamSPC;
