import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useTimer } from "react-timer-hook";
import { DARK_MODE_TOAST_STYLE, LIGHT_MODE_TOAST_STYLE } from "src/App";
import { SIX_SECONDS } from "src/utils/duration";
import { palePurple } from "./qb_question/QBQuestion";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SHOW_ALERT_WHEN_REMAINING_PERCENTAGE = 0.2;

const QBTimer = ({ expiryTimestamp, totalDurationInMinutes, onExpire, isLightMode, timerHidden, toggleHideTimer }) => {
  const [alertSent, setAlertSent] = useState(false);

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire,
  });

  const almostOutOfTime = areWeAlmostOutOfTime(totalDurationInMinutes, expiryTimestamp);

  useEffect(() => {
    if (alertSent === false && almostOutOfTime === true) {
      setAlertSent(true);
      toast(
        <div data-cy="TimeRemainingToast">
          <TimeRemainingToast hours={hours} minutes={minutes} seconds={seconds} almostOutOfTime={almostOutOfTime} />
        </div>,
        {
          icon: "⏳",
          duration: SIX_SECONDS,
          style: isLightMode ? LIGHT_MODE_TOAST_STYLE : DARK_MODE_TOAST_STYLE,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [almostOutOfTime]);

  return (
    <div
      style={{
        backgroundColor: palePurple,
        padding: "0.25rem 1rem",
        borderRadius: "0.5rem",
      }}
    >
      {!timerHidden && (
        <TimeRemaining hours={hours} minutes={minutes} seconds={seconds} almostOutOfTime={almostOutOfTime} />
      )}

      <Tooltip title={timerHidden ? "Show Timer" : "Hide Timer"}>
        <IconButton
          aria-label={timerHidden ? "Show Timer" : "Hide Timer"}
          onClick={toggleHideTimer}
          style={timerHidden ? undefined : { marginLeft: "0.5rem" }}
        >
          {timerHidden ? <VisibilityIcon style={{ width: "24px" }} /> : <VisibilityOffIcon style={{ width: "22px" }} />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default QBTimer;

const TimeRemainingToast = ({ hours, minutes, seconds, almostOutOfTime }) => {
  return (
    <>
      <span style={{ marginRight: "0.2rem" }}>Time Remaining:</span>
      <TimeRemaining hours={hours} minutes={minutes} seconds={seconds} almostOutOfTime={almostOutOfTime} />
    </>
  );
};

const TimeRemaining = ({ hours, minutes, seconds, almostOutOfTime }) => {
  const hoursFormatted = formatTime(hours);
  const minutesFormatted = formatTime(minutes);
  const secondsFormatted = formatTime(seconds);
  return (
    <span style={almostOutOfTime ? almostOutOfTimeStyle : { fontSize: "18px" }}>
      <span>{hoursFormatted}</span>:<span>{minutesFormatted}</span>:<span>{secondsFormatted}</span>
    </span>
  );
};

const formatTime = (time) => {
  return time.toString().length === 1 ? "0" + time : time;
};

const areWeAlmostOutOfTime = (totalDurationInMinutes, expiryTimestamp) => {
  const totalDurationInSeconds = totalDurationInMinutes * 60; // expected quiz duration in seconds
  const secondsUntilExpiry = (expiryTimestamp - Date.now()) / 1000; // seconds left until expiry
  const percentageTimeRemaining = secondsUntilExpiry / totalDurationInSeconds;
  return percentageTimeRemaining <= SHOW_ALERT_WHEN_REMAINING_PERCENTAGE;
};

const almostOutOfTimeStyle = {
  fontWeight: "bold",
  fontSize: "18px",
};
