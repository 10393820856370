import React from "react";
import { Helmet } from "react-helmet-async";

import ResetPasswordView from "./components/ResetPasswordView";

const ForgotPasswordPage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/forgot-password/" }]}>
        <title>Forgot Password | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Reset your Pre-Reg Shortcuts password securely. Regain access to your online pharmacy training."
        />
        <meta
          name="keywords"
          content="Reset Password, Recover Account, Access Revision plan, Enter email address, Receive link to reset password"
        />
      </Helmet>
      <ResetPasswordView />
    </>
  );
};

export default ForgotPasswordPage;
