import React from "react";
import { Helmet } from "react-helmet-async";
import { Divider, Container, Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import MeetTheTeamSPC from "src/newComponents/MeetTheTeamSPC";
import CompaniesSPC from "src/newComponents/CompaniesSPC";

import overlay from "src/assets/landing_page_overlay.jpeg";
import { bgGradient } from "../../utils/cssStyles";
import AweberModal from "src/custom_components/AweberModal";

import LandingPageTopSPC from "./components/LandingPageTopSPC";
import LandingPageWhyUsSPC from "./components/LandingPageWhyUsSPC";
import useStripeCallback from "./components/useStripeCallback";
import LandingPageFeaturedCourses from "./components/LandingPageFeaturedCourses";
import ReviewsSPC from "./components/ReviewsSPC";

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: overlay,
  }),
  overflow: "hidden",
}));

const LandingPage = () => {
  useStripeCallback();

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "" }]}>
        <title>Home | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Enrol on our pharmacy foundation year program and discover why Pre-Reg Shortcuts is rated excellent by Trustpilot and highly recommended by most trainee pharmacists."
        />
        <meta
          name="keywords"
          content="Pre-Reg Shortcuts, Pharmacy Foundation Year Programme, Enrol here, Preview Online Courses, Our Collaborations, Featured Courses, Trainee Pharmacists, Excellent Rating on Trustpilot"
        />
      </Helmet>
      <StyledRoot>
        <Container
          sx={{
            py: 12,
            display: { md: "flex" },
            alignItems: { md: "center" },
            height: { md: `100vh` },
          }}
        >
          <LandingPageTopSPC />
        </Container>
      </StyledRoot>

      <Container
        sx={{
          minHeight: 1,
          pt: { xs: 6, md: 6 },
        }}
      >
        <LandingPageWhyUsSPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />

        <CompaniesSPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <Stack spacing={3} sx={{ alignItems: "center", pb: { xs: 8 }, mt: 1, mb: 2 }}>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Sign Up for Pre-Reg Shortcuts Updates
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary", textAlign: "center" }}>
            Enter your email below to access free GPhC style questions, resources, updates and more.
          </Typography>

          <AweberModal />
        </Stack>
        <LandingPageFeaturedCourses />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MeetTheTeamSPC isHomePage={true} />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <ReviewsSPC />
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
      </Container>
    </>
  );
};
export default LandingPage;
