import React from "react";
import { Stack, Button } from "@mui/material";

import useResponsive from "src/hooks/useResponsive";
import onlineCommunityIcon from "src/assets/whyUs/1.svg";
import interactiveLearningIcon from "src/assets/whyUs/2.svg";
import awardNominationsIcon from "src/assets/whyUs/3.svg";

const LandingPageWhyUsSPC = () => {
  const isMdUp = useResponsive("up", "md");
  const imageWidth = isMdUp ? "40%" : "35%";
  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={{ xs: 2, sm: 4 }}
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ textAlign: "center" }}
      >
        <Section
          title="Online Community"
          content="1 in 4 Trainee Pharmacists in the UK enrol on our platform!"
          icon={
            <img
              src={onlineCommunityIcon}
              alt={"Online Community"}
              style={{ margin: "auto", width: imageWidth }}
              title={"Online Community"}
            />
          }
        />
        <Section
          title="Interactive Learning"
          content="Up to 40 live online study sessions for motivation, updates and practice!"
          icon={
            <img
              src={interactiveLearningIcon}
              alt={"Interactive Learning"}
              style={{ margin: "auto", width: imageWidth }}
              title="Interactive Learning"
            />
          }
        />
        <Section
          title="Awards Nominations"
          content="Chemist & Druggist nominations for our excellence in Pharmacy Education!"
          icon={
            <img
              src={awardNominationsIcon}
              alt={"Awards Nominations"}
              style={{
                margin: "auto",
                width: imageWidth,
              }}
              title="Awards Nominations"
            />
          }
        />
      </Stack>
      <Stack alignItems="center" sx={{ mt: 1.5 }}>
        <Button variant="outlined" size="large" color="inherit" href="/why-us/">
          Why Choose Us?
        </Button>
      </Stack>
    </>
  );
};

export default LandingPageWhyUsSPC;

const Section = ({ title, content, icon }) => {
  return (
    <div
      style={{
        width: "95%",
        display: "inline-block",
      }}
    >
      <div style={{ width: "93%", marginLeft: "3%" }}>
        {icon}
        <h2>{title}</h2>
        <p style={{ verticalAlign: "middle" }}>{content}</p>
      </div>
    </div>
  );
};
