import { Typography } from "@mui/material";

interface PageTitleProps {
  title: string;
  withBottomMargin?: boolean;
}

const PageTitle = ({ title, withBottomMargin }: PageTitleProps) => {
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: { xs: "2rem", sm: "2.2rem", md: "2.4rem" },
        mb: withBottomMargin ? 2 : 0,
      }}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
