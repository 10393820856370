const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;

export const TEN_MINUTES = 10 * ONE_MINUTE;

export const TWENTY_SECONDS = 20 * ONE_SECOND;
export const TEN_SECONDS = 10 * ONE_SECOND;
export const EIGHT_SECONDS = 8 * ONE_SECOND;
export const SIX_SECONDS = 6 * ONE_SECOND;
export const FIVE_SECONDS = 5 * ONE_SECOND;
export const FOUR_SECONDS = 4 * ONE_SECOND;
export const THREE_SECONDS = 3 * ONE_SECOND;
