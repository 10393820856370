export const QUIZ_NOT_STARTED = 0; // quiz has not started yet
export const QUIZ_IN_PROGRESS_ON_QUESTION_PAGE = 1; // quiz has started, user is looking at a question
export const QUIZ_IN_PROGRESS_ON_REVIEW_PAGE = 2; // quiz has started, user on review page
export const QUIZ_FINISHED_FEEDBACK_PAGE = 3; // quiz has finished, user on feedback page
export const QUIZ_FINISHED_FEEDBACK_PAGE_OUT_OF_TIME = 4; // quiz ran out of time, user on feedback page

export const QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE = 5; // quiz has finished, user viewing quiz feedback on a question
export const QUIZ_FINISHED_QUESTION_FEEDBACK_PAGE_OUT_OF_TIME = 6; // quiz ran out of time, user viewing quiz feedback on a question

export const QUIZ_PREVIEW_MODE = 7; // quiz is in preview mode
