import React, { Suspense } from "react";
import { getBankName, getWeightingName } from "src/utils/format_bank";
import { getAttemptTime } from "./utils/formatAttemptTime";
import { paleGreen } from "./qb_question/QBQuestion";
import DateService from "src/utils/dateService";

const QuizAttemptsDataGrid = React.lazy(() => import("./QuizAttemptsDataGrid"));

const QuizAttempts = ({ attempts }) => {
  if (!attempts) return <></>;
  if (attempts.length === 0) return <></>;

  const formattedAttempts = attempts.map((attempt) => {
    const when = DateService.parseServerDate(attempt.started_quiz);

    return {
      id: attempt.id,
      when: when,
      setup: {
        numQuestions: attempt.num_questions,
        weighting: attempt.weighting,
        duration: attempt.duration_in_minutes,
        bank: attempt.bank,
      },
      result: {
        numQuestions: attempt.num_questions,
        numQuestionsCorrect: attempt.num_questions_correct,
        timeTaken: attempt.time_taken_in_seconds,
        score: attempt.percentage,
      },
    };
  });

  const columns = [
    {
      field: "when",
      headerName: "Date",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 75,
      flex: 1,
      renderCell: ({ value }) => (
        <div>
          <span>{DateService.formatFour(value)}</span>
          <br />
          <span>{DateService.formatThree(value)}</span>
        </div>
      ),
    },
    {
      field: "setup",
      headerName: "Setup",
      minWidth: 150,
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ value }) => {
        const { numQuestions, weighting, duration, bank } = value;

        return (
          <div>
            <span>{getBankName(bank)}</span>
            {weighting && (
              <>
                <br />
                <span>{getWeightingName(weighting)} weighted</span>
              </>
            )}
            <br />
            <span>{numQuestions} questions</span>
            <br />
            <span>{duration} minutes</span>
          </div>
        );
      },
    },
    {
      field: "result",
      headerName: "Result",
      minWidth: 110,
      flex: 1.2,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ value }) => {
        const { numQuestions, numQuestionsCorrect, timeTaken, score } = value;

        const { hours, minutes, seconds } = getAttemptTime(timeTaken);
        const times = [hours, minutes, seconds];

        const scoreAsInt = parseInt(score);
        const scoreColor = scoreAsInt >= 70 ? paleGreen : "transparent";

        return (
          <div>
            <span style={{ backgroundColor: scoreColor }}>Scored {score}%</span>
            <br />
            <span>
              {numQuestionsCorrect}/{numQuestions} correct
            </span>
            <br />
            {times.map((time, idx) => {
              if (time?.length === 0) return null;
              return (
                <span key={idx}>
                  {time}
                  {idx !== times.length - 1 && <br />}
                </span>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Suspense fallback={<div>Loading quiz attempts...</div>}>
        <QuizAttemptsDataGrid rows={formattedAttempts} columns={columns} />
      </Suspense>
    </div>
  );
};

export default QuizAttempts;
