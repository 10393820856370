import { useState } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";

import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";

import GiveAccess from "../manage_access_helpers/GiveAccess";
import AccessDateSelector from "../manage_access_helpers/AccessDateSelector";
import DateService from "src/utils/dateService";

interface ManageWorkshopAccessProps {
  selectedAction: "give" | "edit" | "remove";
  allResources: ResourceModel[];
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
}

const ManageWorkshopAccess = ({
  selectedAction,
  allResources,
  userId,
  userName,
  onSuccessCallbackFn,
}: ManageWorkshopAccessProps) => {
  const [selectedWorkshopId, setSelectedWorkshopId] = useState<number | null>(null);
  const workshopResources = allResources.filter((resource) => resource.type === ResourceModelType.workshop);

  const selectedWorkshop = workshopResources.find((resource) => resource.id === selectedWorkshopId);

  return (
    <>
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <InputLabel id="select-workshop-label">Select Workshop</InputLabel>
        <Select
          labelId="select-workshop-label"
          id="select-workshop"
          value={selectedWorkshopId}
          label="Workshop"
          onChange={(e) => setSelectedWorkshopId(e.target.value as number)}
          size="small"
          fullWidth
        >
          {workshopResources.map((resource) => (
            <MenuItem key={resource.id} value={resource.id}>
              {resource.title}
            </MenuItem>
          ))}
        </Select>
      </div>

      {selectedWorkshop && (
        <>
          {selectedAction === "give" && (
            <AddAccess
              userId={userId}
              userName={userName}
              onSuccessCallbackFn={onSuccessCallbackFn}
              workshop={selectedWorkshop}
            />
          )}
          {selectedAction === "edit" && (
            <EditAccess
              userId={userId}
              userName={userName}
              onSuccessCallbackFn={onSuccessCallbackFn}
              workshop={selectedWorkshop}
            />
          )}
          {selectedAction === "remove" && (
            <RemoveAccess
              userId={userId}
              userName={userName}
              onSuccessCallbackFn={onSuccessCallbackFn}
              workshop={selectedWorkshop}
            />
          )}
        </>
      )}
    </>
  );
};

export default ManageWorkshopAccess;

interface AddAccessProps {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
  workshop: ResourceModel;
}

const AddAccess = ({ userId, userName, onSuccessCallbackFn, workshop }: AddAccessProps) => {
  const [startDate, setStartDate] = useState(DateService.newDate());
  const [endDate, setEndDate] = useState(DateService.newDate());

  if (workshop.access !== null) {
    return <p>User has access or their access has expired, select "Edit Access" or "Remove Access" instead.</p>;
  }

  return (
    <div>
      <p>By proceeding you will give user access to this workshop.</p>

      <AccessDateSelector label="Access Start" date={startDate} onChange={setStartDate} />
      <br />
      <AccessDateSelector label="Access End" date={endDate} onChange={setEndDate} />
      <br />

      <GiveAccess
        text={`Give access to workshop '${workshop.title}'`}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        accessInstructions={[
          {
            resource: workshop,
            crudAction: "create",
            accessStart: startDate,
            accessEnd: endDate,
          },
        ]}
      />
    </div>
  );
};

interface EditAccessProps {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
  workshop: ResourceModel;
}

const EditAccess = ({ userId, userName, onSuccessCallbackFn, workshop }: EditAccessProps) => {
  const [startDate, setStartDate] = useState(DateService.parseServerDate(workshop.access?.accessBegins?.toISOString()));
  const [endDate, setEndDate] = useState(DateService.parseServerDate(workshop.access?.accessEnds?.toISOString()));

  if (workshop.access === null) {
    return <p>User has no access to this workshop, select "Give Access" instead.</p>;
  }

  return (
    <div>
      <p>By proceeding you will edit this user's access to this workshop.</p>

      <AccessDateSelector label="Access Start" date={startDate} onChange={setStartDate} />
      <br />
      <AccessDateSelector label="Access End" date={endDate} onChange={setEndDate} />
      <br />

      <GiveAccess
        text={`Update access to workshop '${workshop.title}'`}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        accessInstructions={[
          {
            resource: workshop,
            crudAction: "update",
            accessStart: startDate,
            accessEnd: endDate,
          },
        ]}
      />
    </div>
  );
};

interface RemoveAccessProps {
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
  workshop: ResourceModel;
}

const RemoveAccess = ({ userId, userName, onSuccessCallbackFn, workshop }: RemoveAccessProps) => {
  if (workshop.access === null) {
    return <p>User has no access to this workshop, select "Give Access" instead.</p>;
  }

  return (
    <div>
      <p>By proceeding you will permanently remove user's access to this workshop.</p>

      <GiveAccess
        text={`Permanently remove access to workshop '${workshop.title}'`}
        userId={userId}
        userName={userName}
        onSuccessCallbackFn={onSuccessCallbackFn}
        accessInstructions={[
          {
            resource: workshop,
            crudAction: "delete",
            accessStart: null,
            accessEnd: null,
          },
        ]}
      />
    </div>
  );
};
