import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { AppContext } from "src/context/AppContext";

import QBQuestion from "../components_with_stories/qb_question/QBQuestion";
import { mapQuestionDtoToQuestionProps } from "../components_with_stories/QBQuiz";
import { adminGetQuestionEndpoint } from "../urls";
import { QUIZ_PREVIEW_MODE } from "src/components_with_stories/QuizStates";
import { errorToast } from "src/components_with_stories/toast";
import { TEN_MINUTES } from "src/utils/duration";
import useIsLightMode from "src/hooks/useIsLightMode";

const PreviewQuestionPage = () => {
  const { questionId } = useParams();
  const { isAuthenticated, isSuperuser } = useContext(AppContext);
  const navigate = useNavigate();
  const [question, setQuestion] = useState();
  const isLightMode = useIsLightMode();

  useEffect(() => {
    axios
      .get(adminGetQuestionEndpoint, {
        params: { question_id: parseInt(questionId) },
      })
      .then((response) => {
        const questionDto = response.data;
        const question = mapQuestionDtoToQuestionProps(questionDto, 1, 10);
        setQuestion(question);
      })
      .catch((error) => {
        const response = error.response;
        if (response && response?.status === 400) {
          errorToast("Question does not exist. ", isLightMode, TEN_MINUTES);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthenticated) {
    const sessionCookie = Cookies.get("csrftoken");
    const sessionCookieExists = sessionCookie ? sessionCookie.length > 0 : false;
    if (sessionCookieExists) {
      console.log("Preview page - session cookie exists, waiting for global auth...");
      return <>Authenticating...</>;
    }
    navigate(`/login/?next=preview-question/${questionId}/`, { replace: true });
    return <></>;
  }
  if (!isSuperuser) navigate("/");

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/preview-question/" }]}>
        <title>Preview Question | Pre-Reg Shortcuts</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div style={{ padding: "2rem" }}>{question && <QBQuestion obj={question} quizState={QUIZ_PREVIEW_MODE} />}</div>
    </>
  );
};

export default PreviewQuestionPage;
