import { Helmet } from "react-helmet-async";

import PageTemplate from "src/pages/PageTemplate";

const StatusDisclosurePage = () => {
  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/status-disclosure/" }]}>
        <title>Status Disclosure | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Pre Reg Shortcuts offers Klarna's interest-free finance options. View our status disclosure here."
        />
        <meta
          name="keywords"
          content="FCA regulated, Klarna finance, interest-free credit, Pre Reg Shortcuts, finance options, Klarna pay in three, Klarna pay in 30 days"
        />
      </Helmet>
      <PageTemplate title="Status Disclosure">
        <p>
          Pre Reg Shortcuts Ltd is authorised and regulated by the Financial Conduct Authority (FRN: 1010725). We act as
          a credit broker not a lender. We will only introduce you to Klarna Financial Services UK Ltd (FRN: 1010725).
          Klarna may offer you their unregulated 'pay in three instalments' or 'pay in 30 days' finance products (both
          are interest-free, 0% APR). They may also offer you their FCA regulated extended term finance plan (up to 12
          months), this option will also be interest-free (0% APR). The finance options available will be clearly
          displayed at checkout under 'Financing'. If you would like to know how we handle complaints, please ask for a
          copy of our complaints handling process. You can also find information about referring a complaint to the
          Financial Ombudsman Service (FOS) at financial-ombudsman.org.uk. This service is not available for the
          unregulated 'pay in three instalments' or 'pay in 30 days' Klarna finance products.
        </p>
      </PageTemplate>
    </>
  );
};

export default StatusDisclosurePage;
