import { Grid } from "@mui/material";
import React from "react";
import StepLabel from "src/pages/workshops/components/StepLabel";

export default function MockPaperHowItWorks() {
  return (
    <Grid item xs={12} md={4}>
      <h2
        style={{
          marginBottom: "1.5rem",
        }}
      >
        How It Works
      </h2>
      <StepLabel step="1" title="Step 1" body="Log in your account and view mock paper instructions." />
      <StepLabel step="2" title="Step 2" body="Select exam section to attempt." />
      <StepLabel
        step="3"
        title="Step 3"
        body="Review and mark your mock paper with full rationale for all questions."
      />
    </Grid>
  );
}
