import { useContext } from "react";
import { Stack } from "@mui/material";

import RoutedButton from "src/newComponents/buttons/RoutedButton";
import img from "src/assets/foundation_training_programme.png";
import useResponsive from "src/hooks/useResponsive";
import useIsLightMode from "src/hooks/useIsLightMode";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";

import { AccessContext } from "src/context/AccessContext";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

import MyCard from "../MyCard";
import KlarnaPayment from "./KlarnaPayment";
import MoneyBackGuaranteeButton from "./MoneyBackGuaranteeButton";

export default function ProductsPageTopSection() {
  const { getResourceModelByResourceType } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceType("foundationTrainingProgramme");

  const isSmDown = useResponsive("down", "sm");
  const isLightMode = useIsLightMode();
  return (
    <MyCard
      customStyle={{
        backgroundColor: isLightMode ? "#efe3c8" : "#cba249",
        marginTop: "0",
      }}
    >
      <Stack
        direction={{
          xs: "column",
          md: "row",
        }}
        spacing={4}
      >
        <img
          src={img}
          alt="Foundation Training Programme"
          loading="eager"
          title="Foundation Training Programme"
          style={{
            width: isSmDown ? "100%" : "370px",
            height: isSmDown ? "auto" : "265px",
          }}
        />
        <Stack direction="column">
          <h2
            style={{
              marginTop: isSmDown ? 0 : "1rem",
              marginBottom: "8px",
            }}
          >
            FOUNDATION PHARMACIST TRAINING PROGRAMME
          </h2>
          <h3
            style={{
              marginTop: 0,
              marginBottom: "8px",
            }}
          >
            ALL IN ONE PROGRAMME
          </h3>
          <p style={{ marginTop: 0, marginBottom: "6px" }}>
            Includes all the popular resources you need to pass the GPhC exam, all in the one platform!
          </p>
          <p style={{ marginTop: 0, marginBottom: "6px" }}>
            With our GPhC approved training plan, all exam revision courses, 50 live study sessions, question bank, mock
            paper, telegram group, check in calls, select learn more to see what's included.
          </p>
          <p style={{ marginTop: 0, marginBottom: "6px" }}>
            Enjoy 365 days access to everything you need to succeed in your GPhC registration assessment and beyond!
          </p>
          <p style={{ marginTop: 0, marginBottom: "6px" }}>£200 discount automatically applied at the checkout</p>

          {resourceCanBePurchased(resourceModel) && <KlarnaPayment cost={resourceModel.cost} />}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: isSmDown ? "1rem" : "-0.5rem",
              gap: "1rem",
              flexDirection: isSmDown ? "column" : "row",
            }}
          >
            {resourceModel && (
              <Stack direction="row" alignItems="center" spacing={1.5}>
                {resourceCanBePurchased(resourceModel) && (
                  <ResourceCost
                    cost={resourceModel.cost}
                    originalCost={resourceModel.originalCost}
                    paddingTop={false}
                  />
                )}
                <AddResourceToBasket resourceModel={resourceModel} />
              </Stack>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
              gap: "1rem",
              flexDirection: isSmDown ? "column" : "row",
            }}
          >
            <RoutedButton path="/foundation-training-programme/" variant="contained" buttonColor="primary">
              Learn More
            </RoutedButton>
            <div>
              <MoneyBackGuaranteeButton />
            </div>
          </div>
        </Stack>
      </Stack>
    </MyCard>
  );
}
