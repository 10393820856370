import React, { useState } from "react";

import { Button, Divider } from "@mui/material";
import { confirmToast, dismissAllToasts } from "src/components_with_stories/toast";

import "./QBQuizMenu.css";
import useResponsive from "src/hooks/useResponsive";
import SelectDuration from "./mock_paper/SelectDuration";
import { QBQuizInstructions } from "./QBQuizInstructions";
import { calculationsBank, clinicalMixedBank } from "src/utils/format_bank";
import MobileTooltip from "src/newComponents/MobileTooltip";

const MPQuizMenu = ({ onStart, loadingQuestions, isLightMode, numQuestions, initialDuration, mockPaperOrder }) => {
  const isMobile = useResponsive("down", "sm");

  const initialHours = Math.floor(initialDuration / 60);
  const initialMinutes = initialDuration % 60;

  const [selectedHours, setSelectedHours] = useState(initialHours);
  const [selectedMinutes, setSelectedMinutes] = useState(initialMinutes);

  const isLoadingQuestions = loadingQuestions === true;
  const selectedBank = mockPaperOrder === 1 ? calculationsBank : clinicalMixedBank;

  return (
    <div data-cy="QBQuiz_QBQuizMenu">
      <QBQuizInstructions selectedBank={selectedBank} isMockPaper={true} />

      <Divider sx={{ mt: { xs: 3, md: 2.5 } }} style={{ borderColor: "transparent" }} />

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <SelectDuration
          initialHours={initialHours}
          initialMinutes={initialMinutes}
          onChange={(hours, minutes) => {
            setSelectedHours(hours);
            setSelectedMinutes(minutes);
          }}
        />
        <MobileTooltip
          title="Duration"
          content={
            <>
              <span style={{ display: "block", margin: "8px 0" }}>
                If you require more time, click "Need more time" to add up to 50% additional time.
              </span>
            </>
          }
        />
      </div>

      <Button
        size="small"
        variant="contained"
        color="primary"
        data-cy="QBQuiz_StartButton"
        disabled={isLoadingQuestions}
        sx={{ mt: 1 }}
        onClick={() => {
          const mobileMessage =
            "We've detected you are on a mobile. For the best experience, we highly recommend you switch to a tablet or desktop.";
          const confirmMessage = `You have ${selectedHours} hours and ${selectedMinutes} minutes to complete ${numQuestions} questions.`;
          const ourRecommendationMessage =
            "recommend that you are in a quiet, distraction-free space as you cannot pause, save, or resume the quiz at a later time.";

          const message = (
            <>
              {isMobile && (
                <>
                  <strong>{mobileMessage}</strong>
                  <br />
                  <br />
                </>
              )}
              {isMobile ? "In addition, we " : "We "}
              {ourRecommendationMessage}
              <br />
              <br />
              {confirmMessage}
            </>
          );

          confirmToast({
            message: message,
            isLightMode: isLightMode,
            onConfirm: () => {
              dismissAllToasts();
              const duration = selectedHours * 60 + selectedMinutes;
              onStart(duration);
            },
            confirmText: "Start Mock Paper",
          });
        }}
      >
        {isLoadingQuestions ? "Starting Mock Paper..." : "Start Mock Paper"}
      </Button>
    </div>
  );
};

export default MPQuizMenu;
