import ResourceModelType from "src/models/ResourceModelType";
import ResourceAccessDto from "../dtos/ResourceAccessDto";
import ResourceAccessModel from "src/models/ResourceAccessModel";
import ResourceDto from "../dtos/ResourceDto";
import ResourceModel from "src/models/ResourceModel";
import imagePlaceholder from "src/assets/placeholder.jpeg";
import DateService from "src/utils/dateService";

export default function mapResourceDtoToModel(resourceDto: ResourceDto): ResourceModel {
  const type = mapResourceDtoTypeToResourceModelType(resourceDto);
  const access = resourceDto.resource_access
    ? mapResourceAccessDtoToResourceAccessModel(resourceDto.resource_access)
    : null;
  const isIncludedInPremium = getIsIncludedInPremium(type);
  return {
    id: parseInt(resourceDto.id),
    title: resourceDto.title,
    checkoutDescription: resourceDto.checkout_description,
    stripePriceId: resourceDto.stripe_price_id,
    cost: parseFloat(resourceDto.cost),
    originalCost: parseFloat(resourceDto.original_cost),
    type: type,
    access: access,
    isIncludedInPremium: isIncludedInPremium,
    availableOnProductsPage: resourceDto.available_on_products_page,
    image: resourceDto.image ?? imagePlaceholder,
    featuredProductOrder: resourceDto.featured_product_order,
  };
}

const mapResourceDtoTypeToResourceModelType = (resourceDto: ResourceDto) => {
  switch (resourceDto.type) {
    case "mockPaper":
      return ResourceModelType.mockPaper;
    case "questionSet":
      return ResourceModelType.questionSet;
    case "workshop":
      return ResourceModelType.workshop;
    case "course":
      return ResourceModelType.course;
    case "questionBank":
      return ResourceModelType.questionBank;
    case "foundationTrainingProgramme":
      return ResourceModelType.foundationTrainingProgramme;
    case "designatedSupervisorProgramme":
      return ResourceModelType.designatedSupervisorProgramme;
    default:
      throw new Error("Unknown resource type.");
  }
};

const mapResourceAccessDtoToResourceAccessModel = (resourceAccessDto: ResourceAccessDto): ResourceAccessModel => {
  // both accesses are null, we assume it's a mock paper resource.

  if (resourceAccessDto.access_begins === null || resourceAccessDto.access_ends === null) {
    return {
      id: parseInt(resourceAccessDto.id),
      accessBegins: null,
      accessEnds: null,
      userHasValidAccess: true,
      accessEnded: false,
    };
  }

  const accessBegins = DateService.parseServerDate(resourceAccessDto.access_begins);
  const accessEnds = DateService.parseServerDate(resourceAccessDto.access_ends);

  const userHasValidAccess = DateService.newDate().isBetween(accessBegins, accessEnds);
  const accessEnded = DateService.newDate().isAfter(accessEnds);

  return {
    id: parseInt(resourceAccessDto.id),
    accessBegins: accessBegins,
    accessEnds: accessEnds,
    userHasValidAccess: userHasValidAccess,
    accessEnded: accessEnded,
  };
};

const getIsIncludedInPremium = (type: ResourceModelType) => {
  switch (type) {
    case ResourceModelType.foundationTrainingProgramme:
    case ResourceModelType.mockPaper:
    case ResourceModelType.course:
    case ResourceModelType.questionBank:
      return true;
    case ResourceModelType.workshop:
    case ResourceModelType.designatedSupervisorProgramme:
    case ResourceModelType.questionSet:
      return false;
    default:
      throw new Error("Unknown resource type.");
  }
};
