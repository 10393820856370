import ResourceModelType from "src/models/ResourceModelType";

export const getConflictingItems = (
  items: {
    type: ResourceModelType;
    includedInPremium: boolean;
  }[]
) => {
  const containsFoundationTrainingProgramme = items.some(
    (item) => item.type === ResourceModelType.foundationTrainingProgramme
  );
  if (!containsFoundationTrainingProgramme) {
    return [];
  }

  const itemsWithoutProgramme = items.filter((item) => item.type !== ResourceModelType.foundationTrainingProgramme);

  const conflictingItems = itemsWithoutProgramme.filter((item) => item.includedInPremium);
  return conflictingItems;
};
