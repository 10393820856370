import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import PageTemplate from "src/pages/PageTemplate";
import LoadingScreen from "src/components/loading-screen";
import LiveStudySessionRepository from "src/repository/liveStudySessionRepository";
import LiveStudySessionModel from "src/models/LiveStudySessionModel";
import { errorToast } from "src/components_with_stories/toast";
import { AppContext } from "src/context/AppContext";
import { AccessContext } from "src/context/AccessContext";
import ResourceModelType from "src/models/ResourceModelType";
import useIsLightMode from "src/hooks/useIsLightMode";

import LiveSessionsSchedule from "./components/LiveSessionsSchedule";
import LinkToCourses from "./components/LinkToCourses";
import LiveOnlineStudySessionsTopSection from "./components/LiveOnlineStudySessionsTopSection";
import MiniFAQsSPC from "src/newComponents/MiniFAQsSPC";
import { Divider } from "@mui/material";
import { liveStudySessionsTopics } from "src/config/faq/liveStudySessionsTopics";

const LiveOnlineStudySessionsPage = () => {
  const { isAuthenticated, configurations } = useContext(AppContext);
  const { getResourceModelByResourceType } = useContext(AccessContext);
  const [liveStudySessions, setLiveStudySessions] = useState<LiveStudySessionModel[]>();
  const [isLoading, setIsLoading] = useState(true);
  const isLightMode = useIsLightMode();

  const resourceModel = getResourceModelByResourceType(ResourceModelType.foundationTrainingProgramme);
  const userHasAccess = resourceModel?.access?.userHasValidAccess ?? false;

  useEffect(() => {
    const repository = new LiveStudySessionRepository();
    repository
      .getLiveStudySessions()
      .then((sessions: LiveStudySessionModel[]) => setLiveStudySessions(sessions))
      .catch(() => errorToast("Failed to fetch live study sessions.", isLightMode))
      .finally(() => setIsLoading(false));
  }, [isAuthenticated]);

  // resourcemodel for foundation training program is required to show the live study sessions
  // configurations are required to show the live study sessions
  const showLoadingSpinner = isLoading || !resourceModel || !configurations;

  return (
    <>
      {showLoadingSpinner && <LoadingScreen />}
      <Helmet link={[{ rel: "canonical", href: "/live-online-study-sessions/" }]}>
        <title>Live Online Study Sessions | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Over 50 opportunities to connect with our tutors and guest speakers throughout your foundation pharmacist training year designed to compliment your GPhC exam revision courses. Cover key topics such as exam revision, case studies, motivation and more."
        />
        <meta
          name="keywords"
          content="SEO Keywords, trainee pharmacist, foundation pharmacist training year, GPhC exam, live online study sessions, exam Q&A sessions, GPhC exam structure, pharmacy exam support, BNF course, pharmacy calculations course, MEP course, OTC course"
        />
      </Helmet>
      <PageTemplate title="Live Online Study Sessions">
        {configurations && (
          <LiveOnlineStudySessionsTopSection userHasAccess={userHasAccess} configurations={configurations} />
        )}

        <LinkToCourses />

        {liveStudySessions && <LiveSessionsSchedule liveStudySessions={liveStudySessions} />}
        <Divider sx={{ mt: { xs: 9, md: 8 } }} style={{ borderColor: "transparent" }} />
        <MiniFAQsSPC topics={liveStudySessionsTopics} />
      </PageTemplate>
    </>
  );
};

export default LiveOnlineStudySessionsPage;
