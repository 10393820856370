import { Box, Stack, Breadcrumbs } from "@mui/material";

import LinkItem from "./LinkItem";

export default function CustomBreadcrumbs({ links, sx }) {
  const lastLink = links[links.length - 1].name;

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {!!links.length && (
            <Breadcrumbs separator={<Separator />}>
              {links.map((link) => (
                <LinkItem key={link.name || ""} link={link} disabled={link.name === lastLink} />
              ))}
            </Breadcrumbs>
          )}
        </Box>
      </Stack>
    </Box>
  );
}

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: "50%",
        bgcolor: "text.disabled",
      }}
    />
  );
}
