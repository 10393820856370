import ResourceModel from "src/models/ResourceModel";
import ResourceModelType from "src/models/ResourceModelType";

import GiveAccess from "../manage_access_helpers/GiveAccess";

interface ManageMockPaperAccessProps {
  selectedAction: "give" | "edit" | "remove";
  allResources: ResourceModel[];
  userId: number;
  userName: string;
  onSuccessCallbackFn: (newUserNotes: string) => void;
}

const ManageMockPaperAccess = ({
  selectedAction,
  allResources,
  userId,
  userName,
  onSuccessCallbackFn,
}: ManageMockPaperAccessProps) => {
  const mockPaperResources = allResources.filter((resource) => resource.type === ResourceModelType.mockPaper);
  const questionSetResources = allResources.filter((resource) => resource.type === ResourceModelType.questionSet);

  if (mockPaperResources.length !== 1) {
    throw new Error("Invalid number of mock paper resources found");
  }
  if (questionSetResources.length !== 2) {
    throw new Error("Invalid number of question set resources found");
  }

  const mockPaper = mockPaperResources[0];
  const questionSet1 = questionSetResources[0];
  const questionSet2 = questionSetResources[1];

  if (selectedAction === "give") {
    if (mockPaper.access !== null) {
      return <p>User has access or their access has expired, select "Edit Access" or "Remove Access" instead.</p>;
    }
    return (
      <div>
        <p>
          By proceeding you will give user access to mock paper, question set 1 and question set 2. The user can then
          attempt this mock paper at any time.
        </p>

        <GiveAccess
          text="Give full mock paper access to user"
          userId={userId}
          userName={userName}
          onSuccessCallbackFn={onSuccessCallbackFn}
          accessInstructions={[
            {
              resource: mockPaper,
              crudAction: "create",
              accessStart: null,
              accessEnd: null,
            },
            {
              resource: questionSet1,
              crudAction: "create",
              accessStart: null,
              accessEnd: null,
            },
            {
              resource: questionSet2,
              crudAction: "create",
              accessStart: null,
              accessEnd: null,
            },
          ]}
        />
      </div>
    );
  }

  if (selectedAction === "edit") {
    if (mockPaper.access === null) {
      return <p>User has no access to mock paper, select "Give Access" instead.</p>;
    }

    if (questionSet1.access === null || questionSet2.access === null) {
      throw new Error("User has access to mock paper but not to both question sets");
    }

    const hasAttemptedBoth = mockPaper.access.accessEnds !== null;
    const hasStartedQuestionSet1 = questionSet1.access.accessEnds !== null;
    const hasStartedQuestionSet2 = questionSet2.access.accessEnds !== null;
    const hasNotAttemptedAny = !hasAttemptedBoth && !hasStartedQuestionSet1 && !hasStartedQuestionSet2;

    return (
      <div>
        {hasNotAttemptedAny && (
          <>
            <p>User has not attempted any question sets, nothing to edit. Select "Delete Access" instead.</p>
          </>
        )}
        {hasAttemptedBoth && (
          <div>
            <p>User has attempted both parts of the mock paper. </p>
            <GiveAccess
              text="Reset entire mock paper attempt so that user can attempt it again"
              userId={userId}
              userName={userName}
              onSuccessCallbackFn={onSuccessCallbackFn}
              accessInstructions={[
                {
                  resource: mockPaper,
                  crudAction: "update",
                  accessStart: null,
                  accessEnd: null,
                },
                {
                  resource: questionSet1,
                  crudAction: "update",
                  accessStart: null,
                  accessEnd: null,
                },
                {
                  resource: questionSet2,
                  crudAction: "update",
                  accessStart: null,
                  accessEnd: null,
                },
              ]}
            />
          </div>
        )}
        <div>
          {hasStartedQuestionSet1 && (
            <>
              <p>User has attempted part 1 of the mock paper. </p>
              <GiveAccess
                text="Reset question set 1 so that user can attempt it again"
                userId={userId}
                userName={userName}
                onSuccessCallbackFn={onSuccessCallbackFn}
                accessInstructions={[
                  {
                    resource: mockPaper,
                    crudAction: "update",
                    accessStart: null,
                    accessEnd: null,
                  },
                  {
                    resource: questionSet1,
                    crudAction: "update",
                    accessStart: null,
                    accessEnd: null,
                  },
                ]}
              />
            </>
          )}

          {hasStartedQuestionSet2 && (
            <>
              <p>User has attempted part 2 of the mock paper. </p>
              <GiveAccess
                text="Reset question set 2 so that user can attempt it again"
                userId={userId}
                userName={userName}
                onSuccessCallbackFn={onSuccessCallbackFn}
                accessInstructions={[
                  {
                    resource: mockPaper,
                    crudAction: "update",
                    accessStart: null,
                    accessEnd: null,
                  },
                  {
                    resource: questionSet2,
                    crudAction: "update",
                    accessStart: null,
                    accessEnd: null,
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  if (selectedAction === "remove") {
    if (mockPaper.access === null) {
      return <p>User has no access to mock paper, select "Give Access" instead.</p>;
    }

    if (questionSet1.access === null || questionSet2.access === null) {
      throw new Error("User has access to mock paper but not to both question sets");
    }
    return (
      <div>
        <p>
          By proceeding you will remove user's access to mock paper, question set 1 and question set 2. The user will no
          longer be able to attempt this mock paper or view any feedback they have received. Their attempt data will
          also be deleted.
        </p>

        <GiveAccess
          text="Permanently remove access to mock paper"
          userId={userId}
          userName={userName}
          onSuccessCallbackFn={onSuccessCallbackFn}
          accessInstructions={[
            {
              resource: mockPaper,
              crudAction: "delete",
              accessStart: null,
              accessEnd: null,
            },
            {
              resource: questionSet1,
              crudAction: "delete",
              accessStart: null,
              accessEnd: null,
            },
            {
              resource: questionSet2,
              crudAction: "delete",
              accessStart: null,
              accessEnd: null,
            },
          ]}
        />
      </div>
    );
  }

  throw new Error("Invalid action");
};

export default ManageMockPaperAccess;
