import { Divider, Stack, Typography } from "@mui/material";

import LearnMoreButtonWithModal from "src/newComponents/buttons/LearnMoreButtonWithModal";

import ProductItem from "./ProductItem";
import PreviewLinkButton from "src/newComponents/buttons/PreviewLinkButton";
import ResourceModel from "src/models/ResourceModel";

interface ProductItemsProps {
  title: string;
  link: string;
  content: React.ReactNode;
  resourceModels: ResourceModel[] | null;
}

export default function ProductItems({ title, link, content, resourceModels }: ProductItemsProps) {
  if (!resourceModels) return <></>;
  if (resourceModels.length === 0) return <></>;

  return (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={{
          xs: 1,
          sm: 2,
        }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        sx={{
          mt: {
            xs: 3,
            sm: 2,
          },
          mb: {
            xs: 2,
            sm: 2,
          },
        }}
      >
        <Typography variant="h4">{title}</Typography>

        <Stack direction="row" spacing={1} sx={{ mb: { xs: 1 } }}>
          <LearnMoreButtonWithModal title={title} content={content} />
          <PreviewLinkButton link={link} />
        </Stack>
      </Stack>

      {resourceModels.map((resourceModel) => {
        if (!resourceModel) return null;
        return <ProductItem key={resourceModel.id} resourceModel={resourceModel} />;
      })}
      <Divider
        sx={{
          mt: {
            xs: 3,
            sm: 2,
          },
        }}
      />
    </>
  );
}
